import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderStatus({ orderStatus = '' }) {
    const { t } = useTranslation();

    function determineOrderStatus(status) {
        switch (status) {
            case 'pending':
            case 'فى الانتظار':
                return 'pending';
            case 'processing':
            case 'معالجة':
                return 'complete';
            case 'complete':
            case 'مكتمل':
                return 'complete';
            case 'cancelled':
            case 'ملغى':
                return 'cancelled';
            case 'failed':
                return 'failed';
            default:
                return status;
        }
    }
    return (
        <span className={`order_status ${determineOrderStatus(orderStatus?.toLocaleLowerCase())}`}>
            {orderStatus}
        </span>
    );
}
