import i18n from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { baseInstance } from './api/baseInstance';
import { useInitApiKey } from './hooks/useInitApiKey';
import { updateCartIcon } from './redux/addToCart';
import { saveDataFromLocalStorage } from './redux/authSlice';
import Layout from './layouts/Layout';
import PuffLoader from 'react-spinners/PuffLoader';
import NonAuthLayout from './layouts/NonAuthLayout';
import 'react-toastify/dist/ReactToastify.css';

const Start = () => {
    const { pathname } = useLocation();
    const { isLogged } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    let userData = localStorage?.getItem('userData');
    let lang = i18n?.language;
    let caches = window?.caches;
    document.documentElement.lang = lang;

    useEffect(() => {
        if (caches)
            caches?.keys().then((names) => {
                names.forEach((name) => {
                    caches?.delete(name);
                });
            });
        if (userData) {
            userData = JSON.parse(userData);
            dispatch(saveDataFromLocalStorage(userData));
        }
    }, []);

    useEffect(() => {
        if (userData) {
            baseInstance
                .post('/ShoppingCart/MyCart', {
                    pageNumber: 0,
                    pageSize: 20,
                })
                .then((res) => {
                    dispatch(
                        updateCartIcon({
                            totalCount: res?.data?.data?.priceDetails?.totalQuantity || 0,
                            totalCartPrice: res?.data?.data?.priceDetails?.total || 0,
                        }),
                    );
                });
        } else {
            dispatch(
                updateCartIcon({
                    totalCount: 0,
                    totalCartPrice: 0,
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (pathname == '/login' || pathname == '/register') {
            setTimeout(() => {
                document?.querySelectorAll('input')?.forEach((input) => {
                    input.value = '';
                });
            }, 1500);
        }
    }, [pathname]);

    if (!isLogged) return <NonAuthLayout />;
    else return <Layout />;
};

const App = () => {
    const { isLoadingKey } = useInitApiKey();

    return isLoadingKey ? (
        <div className="loader-spinner-bx">
            <PuffLoader
                color="#DB5F19"
                size={70}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    ) : (
        <Start />
    );
};

export default App;
