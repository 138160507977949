import { baseInstance } from './baseInstance';

export const getPaymentMethods = () => {
    return baseInstance.get('/LookUp/GetPaymentMethods');
};

export const getCardsAPI = (data) => {
    return baseInstance.post('/Card/GetCustomerCardInfo', data);
};

export const getCardAPI = (id) => {
    return baseInstance.post(`/Card/CardDetails?${id}`);
};

export const updateCardAPI = (data) => {
    return baseInstance.post('/Card/UpdateCardInfo', data);
};

export const addCardAPI = (data) => {
    return baseInstance.post('/Card/AddCardInfo', data);
};

export const deleteCardAPI = (id) => {
    return baseInstance.delete(`/Card/DeleteCard?Id=${id}`);
};

export const checkoutPay = (data) => {
    return baseInstance.post('/Checkout/CustomConfirmOrder', data);
};

export const getPaymentStatus = (data) => {
    return baseInstance.post('/Checkout/PaymentStatus', data);
};

export const confirmOrderPayment = (checkOutId = '') => {
    return baseInstance.get(`/Checkout/ConfirmOrderPayment?checkOutId=${checkOutId}`);
};
