import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { SendSmsOtp } from '../../api/CustomerAPIs';
import { otpAPI } from '../../api/authAPIs';
import { ReactComponent as Back } from '../../assets/images/back.svg';
import sweetAlert from '../../helpers/sweetAlert';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { authTypes } from '../../utils/constants';
import CountDownOTP from '../Cart/CountDownOTP';

export default function VerifyPhoneModal({
    toggleOtpModal,
    setToggleOtpModal = () => {},
    otpInfo,
    setOtpInfo = () => {},
    control,
    reset,
    resetField,
    isDirty,
    isValid,
    forceRerender = () => {},
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [countdown, setCountdown] = useState(30); // Initial countdown value

    function handleReset() {
        setToggleOtpModal(false);
        setOtpInfo({ otp: '', key: '', countryId: '', addressId: '' });
        reset({
            otp: '',
        });
    }

    const startCountdown = () => {
        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount === 1) {
                    clearInterval(interval);
                    setIsResendDisabled(false); // Enable the button
                }
                return prevCount - 1;
            });
        }, 1000);
    };

    async function handelResendOtp() {
        const formData = { addressId: otpInfo?.addressId, isResend: true };

        try {
            const { data } = await SendSmsOtp(formData);
            sweetAlert.success(t('phone_number_otp_send'));
            setToggleOtpModal(true);
            setOtpInfo({ ...otpInfo, otp: '', key: data?.data?.key });
            resetField('otp');
            setIsResendDisabled(true); // Disable the button
            setCountdown(30); // Reset countdown
            startCountdown(); // Start countdown again
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    async function handelOTPSubmit() {
        const formData = {
            key: otpInfo?.key,
            otp: parseInt(otpInfo?.otp),
            authType: authTypes?.phone,
        };

        try {
            await otpAPI(formData);
            sweetAlert.success(t('phone_number_verified'));
            handleReset();
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            setOtpInfo({ ...otpInfo, otp: '' });
            resetField('otp');
        }
    }

    return (
        <>
            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={toggleOtpModal}
                onHide={handleReset}
                style={{ width: '65vw', maxWidth: '750px' }}
                draggable={false}
                resizable={false}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-between mb-3">
                        <h3>{t('verify_phone_number')}</h3>
                    </div>

                    <div className="form-bx">
                        <div className="flex flex-column">
                            <div className="otp-num">
                                <div className="field">
                                    <span className="p-float-label">
                                        <Controller
                                            name="otp"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                minLength: { value: 4 },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <OTPInput
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setOtpInfo({ ...otpInfo, otp: e });
                                                    }}
                                                    numInputs={4}
                                                    minLength={0}
                                                    renderSeparator={<span>-</span>}
                                                    renderInput={(props) => (
                                                        <InputText keyfilter="int" {...props} />
                                                    )}
                                                />
                                            )}
                                        />
                                    </span>
                                </div>
                            </div>

                            <Button
                                className="btn"
                                label={t('submit')}
                                disabled={!isDirty || !isValid}
                                onClick={handelOTPSubmit}
                            />
                        </div>

                        <div className="my-3 text-center">
                            <CountDownOTP countdown={countdown} startCountdown={startCountdown} />
                            <button
                                onClick={handelResendOtp}
                                className="text-btn mt-3"
                                disabled={isResendDisabled}>
                                {t('resend')}
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
