import React from 'react';
import { ReactComponent as Arrow } from '../../assets/images/btn-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './BackButton.css';

export default function BackButton({ btnClick = null, btnText }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <button
            className="back-button__component"
            onClick={(e) => {
                e.preventDefault();
                if (btnClick) {
                    btnClick(e);
                } else {
                    navigate(-1);
                }
            }}>
            <span className="back_icon">
                <Arrow />
            </span>
            {btnText && typeof btnText === 'string' ? btnText : t(`back`)}
        </button>
    );
}
