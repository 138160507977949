import React, { useState, useEffect, useMemo } from 'react';
import { combinationAttributeAPI } from '../../api/productAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { setShowMiniCartModal } from '../../redux/addToCart';
import { getAddToWishList, getRemoveFromWishList } from '../../api/wishListAPI';
import { getAddToCart } from '../../api/shopingCartAPIs';
// import { ReactComponent as World } from '../../assets/images/World.svg';
import { ReactComponent as Box } from '../../assets/images/Box.svg';
import { ReactComponent as Visa } from '../../assets/images/Visa.svg';
import { ReactComponent as Truck } from '../../assets/images/Truck.svg';
import { ReactComponent as LowStock } from '../../assets/images/low-stock.svg';
import { ReactComponent as OutStock } from '../../assets/images/out-stock.svg';
import { ReactComponent as Saving } from '../../assets/images/saving.svg';
import { ReactComponent as Shipping } from '../../assets/images/shippingIcon.svg';
import { ReactComponent as Stock } from '../../assets/images/stock.svg';
import Counter from './Counter';
import MiniCartModal from '../../components/Modal/MiniCartModal';
import sweetAlert from '../../helpers/sweetAlert';
import favOff from '../../assets/images/favOff.svg';
import favOn from '../../assets/images/favOn.svg';
import Rate from '../../assets/images/rate.svg';
import loginImg from '../../assets/images/sign_in.svg';

const DetailsBx = ({ productDetails = {} }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { assoicateId, id } = useParams();
    const { isLogged } = useSelector((state) => state.authReducer);
    const showMiniCartModal =
        useSelector((state) => state.addToCartReducer.showMiniCartModal) || false;
    const responseData = useSelector((state) => state.addToCartReducer.responseData);
    const [price, setPrice] = useState(productDetails?.price?.price || 0);
    const [productAttributes, setProductAttributes] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [productDetailsData, setProductDetailsData] = useState(productDetails);
    const [quantity, setQuantity] = useState(1);
    const [customQuantities, setCustomQuantities] = useState([]);
    const [visible, setVisible] = useState(false);
    const [storedString, setStoredString] = useState(localStorage.getItem('wishlist'));
    const setShowCartModal = (val) => dispatch(setShowMiniCartModal(val));

    // get related values for each attribute groupId
    async function getRelatedValues(groupId) {
        try {
            const { data } = await dispatch(combinationAttributeAPI({ id, groupId })).unwrap();

            if (data) {
                setProductAttributes((prev) =>
                    prev.map((attribute) => {
                        if (attribute?.groupId == groupId) {
                            const updatedValues = data.values?.map((val) => {
                                const isRelated = attribute?.values?.some(
                                    (relatedVal) =>
                                        (relatedVal?.id == val?.id &&
                                            relatedVal?.inStock &&
                                            relatedVal?.hasRelatedValues) ||
                                        false,
                                );
                                return { ...val, disabled: !isRelated };
                            });
                            return { ...attribute, values: updatedValues };
                        }
                        return attribute;
                    }),
                );
            } else {
                setProductAttributes((prev) =>
                    prev.map((attribute) => {
                        if (attribute?.groupId == groupId) {
                            const updatedValues = attribute?.values?.map((val) => {
                                return { ...val, disabled: !val?.inStock };
                            });
                            return { ...attribute, values: updatedValues };
                        }
                        return attribute;
                    }),
                );
            }

            const groupIds = productDetails?.attributes?.map((attr) => attr?.groupId) || [];
            setSelectedAttributes(
                groupIds?.reduce((acc, curr) => {
                    let currGroupIdValues = productDetails?.attributes?.find(
                        (attr) => attr?.groupId == curr,
                    )?.values;
                    let isSelectedId = currGroupIdValues?.find((val) => val?.isSelected)?.id;
                    if (isSelectedId) {
                        checkRelatedValues(
                            curr,
                            currGroupIdValues?.find((val) => val?.id == isSelectedId),
                            false,
                        );
                    }
                    return { ...acc, [curr]: isSelectedId || null };
                }, {}),
            );
        } catch (error) {
            console.log(error);
        }
    }

    // calculate price based on selected attributes with price adjustments and handle percentage or fixed price adjustments
    const calculatePrice = () => {
        let total = productDetails?.price?.price || 0;
        for (const key in selectedAttributes) {
            const attribute = productAttributes?.find((attr) => attr.groupId == key);
            const value = attribute?.values?.find((val) => val.id == selectedAttributes?.[key]);
            if (value?.hasAdjustment) {
                if (value?.isPercentage) {
                    total += (total * value?.priceAdjustment) / 100 || 0;
                } else {
                    total += value?.priceAdjustment || 0;
                }
            }
        }
        setPrice(total);
    };

    // check related values for each attribute and disable if not related
    function checkRelatedValues(groupId, value, isChecked) {
        if (value && value?.hasRelatedValues && value?.relatedValues?.length > 0) {
            setProductAttributes((prev) =>
                prev.map((attr) => {
                    if (attr.groupId == value?.relatedValues?.[0]?.groupId) {
                        const updatedValues = attr.values?.map((val) => {
                            const isRelated = value?.relatedValues?.[0]?.values?.some(
                                (relatedVal) => relatedVal?.id == val?.id,
                            );
                            return { ...val, disabled: !isChecked && !isRelated };
                        });
                        return { ...attr, values: updatedValues };
                    }
                    return attr;
                }),
            );
        }
    }

    const HandleAddToCart = () => {
        if (isLogged) {
            // check if there is any selected attribute
            if (Object.values(selectedAttributes).some((val) => val == null)) {
                alert('Please select all attributes');
                return;
            }

            let productAttributes = [];
            for (const key in selectedAttributes) {
                productAttributes?.push({
                    attributeId: key,
                    valueId: selectedAttributes[key],
                });
            }

            try {
                dispatch(
                    getAddToCart({
                        productId: assoicateId ? assoicateId : id,
                        quantity: typeof quantity === 'object' ? quantity?.name : quantity,
                        productAttributes: productAttributes,
                    }),
                );
                sweetAlert.success(t('added_to_cart'));
            } catch (error) {
                console.log(error);
            }
        } else {
            setVisible(true);
        }
    };

    const updateLocalStorage = (action, id) => {
        const idString = id.toString();
        let idArray = storedString ? storedString.split(',').map((item) => item?.trim()) : [];

        if (action === 'add') {
            if (!idArray.includes(idString)) {
                idArray.push(idString);
            }
        } else if (action === 'remove') {
            const indexToRemove = idArray.indexOf(idString);
            if (indexToRemove !== -1) {
                idArray.splice(indexToRemove, 1);
            }
        }

        const updatedString = idArray.join(', ');
        localStorage.setItem('wishlist', updatedString);
        setStoredString(updatedString);
    };

    const AddToMyWishListData = async () => {
        if (isLogged) {
            await dispatch(getAddToWishList({ productId: id }));
            setProductDetailsData({ ...productDetailsData, isWishlist: true });
        } else {
            updateLocalStorage('add', id);
        }
    };

    const RemoveFromMyWishListData = async () => {
        if (isLogged) {
            await dispatch(getRemoveFromWishList({ productId: id }));
            setProductDetailsData({ ...productDetailsData, isWishlist: false });
        } else {
            updateLocalStorage('remove', id);
        }
    };

    const discountPercentage = (oldPrice, newPrice) => {
        return Math.floor(((oldPrice - newPrice) / oldPrice) * 100) || 0;
    };

    const getValuesByType = (attribute) => {
        switch (attribute?.typeName) {
            case 'TextSquare':
                return (
                    <div>
                        <div className="title">
                            <div>{attribute?.groupName}</div>
                        </div>
                        <div className="sizes">
                            {attribute?.values?.map((value) => {
                                return (
                                    <span
                                        key={value?.id}
                                        className={
                                            // !value?.hasRelatedValues ||
                                            !value?.inStock || value?.disabled
                                                ? 'size-label-disable'
                                                : `toggle ${
                                                      selectedAttributes?.[attribute?.groupId] ==
                                                      value?.id
                                                          ? 'size-label active'
                                                          : 'size-label'
                                                  }`
                                        }
                                        onClick={() => {
                                            if (value?.disabled) {
                                                return;
                                            }
                                            setSelectedAttributes((prev) => {
                                                if (prev?.[attribute?.groupId] == value?.id) {
                                                    return { ...prev, [attribute?.groupId]: null };
                                                }
                                                return { ...prev, [attribute?.groupId]: value?.id };
                                            });
                                            // select and check related values for each attribute and disable if not related, also send if it's checked or unchecked
                                            checkRelatedValues(
                                                attribute?.groupId,
                                                value,
                                                selectedAttributes?.[attribute?.groupId] ==
                                                    value?.id,
                                            );
                                        }}>
                                        {value?.name}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                );
                break;
            case 'DropdownList':
                return (
                    <div>
                        <div className="title">{attribute?.groupName}</div>
                        <div className="style-sizes">
                            <Dropdown
                                value={attribute?.values?.find(
                                    (val) => val?.id == selectedAttributes?.[attribute?.groupId],
                                )}
                                options={[
                                    { label: t('select'), value: null, disabled: false },
                                    ...attribute?.values?.map((val) => ({
                                        label: val?.name,
                                        value: val,
                                        disabled:
                                            // !val?.hasRelatedValues ||
                                            !val?.inStock || val?.disabled,
                                    })),
                                ]}
                                onChange={(e) => {
                                    if (e.value?.disabled) {
                                        return;
                                    }
                                    setSelectedAttributes((prev) => {
                                        if (
                                            e.value == null ||
                                            prev?.[attribute?.groupId] == e.value.id
                                        ) {
                                            return { ...prev, [attribute?.groupId]: null };
                                        }
                                        return { ...prev, [attribute?.groupId]: e.value.id };
                                    });
                                    checkRelatedValues(
                                        attribute?.groupId,
                                        e.value
                                            ? e.value
                                            : attribute?.values?.find(
                                                  (val) =>
                                                      val?.id ==
                                                      selectedAttributes?.[attribute?.groupId],
                                              ),
                                        e.value == null
                                            ? true
                                            : selectedAttributes?.[attribute?.groupId] ==
                                                  e.value.id,
                                    );
                                }}
                                placeholder={t('select')}
                                optionDisabled={(option) => option?.disabled}
                                optionLabel={(option) => option?.label}
                                className="w-full"
                            />
                        </div>
                    </div>
                );
                break;
            case 'ColorCircle':
                return (
                    <div className="colors-bx">
                        <div className="title">{attribute?.groupName}</div>
                        <div className="colors">
                            <ul>
                                {attribute?.values?.map((item) => (
                                    <li
                                        key={item?.name}
                                        onClick={() => {
                                            if (
                                                // !item?.hasRelatedValues ||
                                                !item?.inStock ||
                                                item?.disabled
                                            ) {
                                                return;
                                            }
                                            setSelectedAttributes((prev) => {
                                                if (prev?.[attribute?.groupId] == item?.id) {
                                                    return { ...prev, [attribute?.groupId]: null };
                                                }
                                                return { ...prev, [attribute?.groupId]: item?.id };
                                            });
                                            checkRelatedValues(
                                                attribute?.groupId,
                                                item,
                                                selectedAttributes?.[attribute?.groupId] ==
                                                    item?.id,
                                            );
                                        }}>
                                        <span
                                            className={`${
                                                // !item?.hasRelatedValues ||
                                                !item?.inStock || item?.disabled
                                                    ? 'color-circle-disable'
                                                    : selectedAttributes?.[attribute?.groupId] ==
                                                        item?.id
                                                      ? 'color-circle active'
                                                      : 'color-circle'
                                            }`}
                                            style={{ backgroundColor: item?.name }}></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                );
                break;
            default:
                return (
                    <div>
                        <div className="title">{attribute?.groupName}</div>
                        <div className="">
                            <div className="flex flex-column gap-2">
                                {attribute?.values?.map((value) => {
                                    return (
                                        <div key={value?.id}>
                                            <RadioButton
                                                key={value?.id}
                                                id={value?.id}
                                                value={value?.id}
                                                inputId={'item' + value?.id}
                                                name={attribute?.groupId}
                                                disabled={
                                                    // !value?.hasRelatedValues ||
                                                    !value?.inStock || value?.disabled
                                                }
                                                checked={
                                                    selectedAttributes?.[attribute?.groupId] ==
                                                    value?.id
                                                }
                                                className={`custom-radio-button ${
                                                    // !value?.hasRelatedValues ||
                                                    !value?.inStock || value?.disabled
                                                        ? 'disabled-radio'
                                                        : ''
                                                }`}
                                                onChange={(e) => {
                                                    if (
                                                        // !value?.hasRelatedValues ||
                                                        !value?.inStock ||
                                                        value?.disabled
                                                    ) {
                                                        return;
                                                    }
                                                    setSelectedAttributes((prev) => {
                                                        if (
                                                            prev?.[attribute?.groupId] == value?.id
                                                        ) {
                                                            return {
                                                                ...prev,
                                                                [attribute?.groupId]: null,
                                                            };
                                                        }
                                                        return {
                                                            ...prev,
                                                            [attribute?.groupId]: value?.id,
                                                        };
                                                    });
                                                    // select and check related values for each attribute and disable if not related, also send if it's checked or unchecked
                                                    checkRelatedValues(
                                                        attribute?.groupId,
                                                        value,
                                                        selectedAttributes?.[attribute?.groupId] ==
                                                            value?.id,
                                                    );
                                                }}
                                            />
                                            <label
                                                className={`mx-2 ${
                                                    // !value?.hasRelatedValues ||
                                                    !value?.inStock || value?.disabled
                                                        ? 'disabled'
                                                        : ''
                                                }`}>
                                                {value?.name}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
                break;
        }
    };

    const DomAttributes = (
        <div className="size-bx">
            {productAttributes && productAttributes?.length > 0
                ? productAttributes?.map((attribute) => {
                      return attribute?.values?.length > 0 ? getValuesByType(attribute) : '';
                  })
                : null}
        </div>
    );

    useEffect(() => {
        if (
            productDetailsData?.allowedQuantities &&
            productDetailsData?.allowedQuantities?.length > 0
        ) {
            const uniqueQuantities = productDetailsData?.allowedQuantities.filter(
                (value, index, self) => self.indexOf(value) === index,
            );
            const customQuantities = uniqueQuantities.map((value) => {
                return { name: value, code: value };
            });
            setCustomQuantities(customQuantities);
            setQuantity(customQuantities?.[0]);
        }
    }, [productDetailsData]);

    // calculate price based on selected attributes with price adjustments and handle percentage or fixed price adjustments
    useEffect(() => {
        calculatePrice();

        // if there is selected value and has been disabled, remove it from selected attributes and recheck related values again
        for (const key in selectedAttributes) {
            const attribute = productAttributes?.find((attr) => attr.groupId == key);
            const value = attribute?.values?.find((val) => val.id == selectedAttributes?.[key]);
            if (value?.disabled) {
                setSelectedAttributes((prev) => ({ ...prev, [key]: null }));
                checkRelatedValues(attribute?.groupId, value, false);
            }
        }
    }, [selectedAttributes]);

    // fill attributes and values with it's related values on page load
    useEffect(() => {
        setProductAttributes(productDetails?.attributes || []);

        const groupIds = productDetails?.attributes?.map((attr) => attr?.groupId) || [];
        // setSelectedAttributes(groupIds?.reduce((acc, curr) => ({ ...acc, [curr]: null }), {}));

        if (groupIds?.length > 0) {
            groupIds?.forEach(async (groupId) => {
                await getRelatedValues(groupId);
            });
        }
    }, [productDetails]);

    return (
        <div className="product-details-bx">
            <div className="colored-title-brand">
                <span className="title">{t('brand')}: </span>
                {productDetailsData?.manufacturer?.map((brand) => {
                    return (
                        <Link to={`/brand/${brand?.name}/${brand?.id}`} key={brand?.id}>
                            {productDetailsData?.manufacturer?.indexOf(brand) > 0 ? '|' : null}
                            <span className="name"> {brand?.name} </span>
                        </Link>
                    );
                })}
            </div>

            <strong className="my-2 block">
                {t('sku')} : {productDetailsData?.sku}
            </strong>

            <div className="product-name">
                <h2>{productDetailsData?.name}</h2>
                <div className="rate-label">
                    <img src={Rate} alt="pic" />{' '}
                    {Number(productDetailsData?.rating?.avgRate || 0)?.toFixed(1)}
                </div>
                <div className="colored-title">
                    <span className="title">{t('sold_by')}:</span>
                    <Link to={`/vendor/vendor-products/${productDetailsData?.vendor?.id}`}>
                        <span className="name"> {productDetailsData?.vendor?.name}</span>
                    </Link>
                </div>
            </div>

            <div className="actions-icons">
                <div
                    className="item"
                    onClick={
                        productDetailsData?.isWishlist ||
                        storedString
                            ?.split(',')
                            ?.map((item) => item?.trim())
                            .includes(id?.toString())
                            ? RemoveFromMyWishListData
                            : AddToMyWishListData
                    }>
                    {productDetailsData?.isWishlist ||
                    storedString
                        ?.split(',')
                        ?.map((item) => item?.trim())
                        .includes(id?.toString()) ? (
                        <img src={favOn} alt="pic" />
                    ) : (
                        <img src={favOff} alt="pic" />
                    )}
                    <span>{t('add_to_wishlist')}</span>
                </div>
            </div>

            <div className="prices-labels">
                <div className="new">
                    {price} <span>{t('sar')}</span>
                </div>
                {productDetailsData?.price?.oldPrice ? (
                    <div className="price-old">
                        {productDetailsData?.price?.oldPrice} <span>{t('sar')}</span>
                    </div>
                ) : null}
            </div>

            {productDetailsData?.price?.oldPrice ? (
                <div className="product-labels">
                    <>
                        <Saving /> {t('save')}{' '}
                        {discountPercentage(productDetailsData?.price?.oldPrice, price)}%{' '}
                        {t('right-now')}
                    </>
                </div>
            ) : null}

            {productDetailsData?.shipping?.shippingStatusId == 1 ? (
                <div className="product-labels shipping-label">
                    <>
                        <Shipping /> {productDetailsData?.shipping?.shippingStatus}
                    </>
                </div>
            ) : null}

            {productDetailsData?.stockStatus?.stockStatusId != null ? (
                <div
                    className={`product-labels stock-label stock-${productDetailsData?.stockStatus?.stockStatusId}`}>
                    {productDetailsData?.stockStatus?.stockStatusId == 0 ? (
                        <>
                            {productDetailsData?.stockStatus?.stockStatus && (
                                <>
                                    <OutStock /> {productDetailsData?.stockStatus?.stockStatus}
                                </>
                            )}
                        </>
                    ) : productDetailsData?.stockStatus?.stockStatusId == 1 ? (
                        <>
                            <Stock /> {productDetailsData?.stockStatus?.stockStatus}
                        </>
                    ) : (
                        <>
                            <LowStock /> {productDetailsData?.stockStatus?.stockStatus}
                        </>
                    )}{' '}
                    {!productDetailsData?.stockAvailability ||
                    !productDetailsData?.stockStatus?.stockQuantity
                        ? null
                        : `(${productDetailsData?.stockStatus?.stockQuantity || 0})`}
                </div>
            ) : null}

            <div className="items-bx">
                {productDetailsData?.attributes?.length > 0 ? (
                    <div className="title">
                        <h4>{t('attributes')}</h4>
                    </div>
                ) : null}
                {DomAttributes}
            </div>

            <div className="items-bx">
                <div className="title">
                    <h4>{t('quantity')}</h4>
                </div>

                <div className="add-bx">
                    {productDetailsData?.allowedQuantities &&
                    productDetailsData?.allowedQuantities?.length > 0 ? (
                        <Dropdown
                            value={quantity}
                            onChange={(e) => setQuantity(e.value)}
                            options={customQuantities}
                            optionLabel="name"
                            placeholder={t('select_quantity')}
                            className="w-full"
                            disabled={
                                productDetailsData?.stockStatus?.stockStatusId == 0 ? true : false
                            }
                        />
                    ) : (
                        <Counter
                            num={quantity}
                            setNum={setQuantity}
                            disabled={
                                productDetailsData?.stockStatus?.stockStatusId == 0 ? true : false
                            }
                        />
                    )}
                    <button
                        className="add-to-cart-btn"
                        onClick={HandleAddToCart}
                        disabled={
                            productDetailsData?.stockStatus?.stockStatusId == 0 ||
                            Object.values(selectedAttributes)?.some((val) => val == null)
                                ? true
                                : false
                        }>
                        {t('add_to_cart')}
                    </button>
                </div>
            </div>

            <div className="shipping-card">
                <ul>
                    <li>
                        <Box />
                        {productDetailsData?.shipping?.deliveryDate
                            ? productDetailsData?.shipping?.deliveryDate
                            : t('fast-delivery')}
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <Visa />
                        {t('easy-returns')}
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <Truck />
                        {productDetailsData?.shipping?.shippingStatusId == 1
                            ? t('free-shipping')
                            : `${t('shipping-fee')} ${productDetailsData?.deliveryfees} ${t(
                                  'sar',
                              )}`}
                        {/* <World />
                        {t('safe-pay')} */}
                    </li>
                </ul>
            </div>

            <div className="shipping-card shipping-info">
                <h4>{t('shipping_head')}</h4>
                <h6>
                    {t('shipping_body1')} <span>{t('return_policy')}</span> {t('shipping_body2')}
                </h6>
            </div>

            <Dialog visible={visible} style={{ width: '30vw' }} onHide={() => setVisible(false)}>
                <div className="popup-content">
                    <img src={loginImg} alt="pic" />
                    <p className="m-0">{t(`you_should_login_to_add_to_cart`)}</p>
                </div>

                <div className="popup-action-btn-bx">
                    <Button
                        label={t(`no_thanks`)}
                        onClick={() => setVisible(false)}
                        className="none-popup-btn"
                    />

                    <Link
                        to="/login"
                        onClick={() => {
                            window.scroll({ top: 0, behavior: 'smooth' });
                        }}>
                        <Button label={`${t(`sign_in`)}`} className="login-popup-btn" />
                    </Link>
                </div>
            </Dialog>

            {/* Cart Modal */}
            <MiniCartModal
                visible={showMiniCartModal}
                setVisible={setShowCartModal}
                productData={productDetails}
                responseData={responseData}
            />
        </div>
    );
};

export default DetailsBx;
