import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { getBrandsAPI } from '../../api/brandsAPIs';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Carousel from 'react-multi-carousel';
import NoData from '../../components/NoData/NoData';
import 'react-multi-carousel/lib/styles.css';

const BrandSection = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [brands, setBrands] = useState([]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            partialVisibilityGutter: 40,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            partialVisibilityGutter: 40,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    async function getBrands() {
        dispatch(showLoader());

        const formData = {
            paging: { PageNumber: 0, PageSize: 20 },
            data: { isHome: true },
        };

        try {
            const { data } = await getBrandsAPI(formData);
            setBrands(data?.data?.list);
        } catch (err) {
            console.log(err);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getBrands();
    }, []);

    return (
        <section className="brand-section container_bx">
            <h3 className="center-title">{t('brands')}</h3>
            {brands?.length > 0 ? (
                <div className="brand-slider">
                    <Carousel
                        responsive={responsive}
                        draggable={false}
                        infinite={false}
                        swipeable={true}
                        rtl={i18n.language === 'ar' ? true : false}
                        arrows={true}>
                        {brands?.map((brand) => (
                            <Link
                                className="item"
                                to={`/brand/${brand?.name}/${brand?.id}`}
                                key={brand?.id}
                                onClick={() => window?.scrollTo(0, 0)}>
                                <img src={brand?.image} alt={brand?.name} />
                            </Link>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <NoData />
            )}
        </section>
    );
};

export default BrandSection;
