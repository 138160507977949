import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { useTranslation } from 'react-i18next';
import { resetPasswordAPI } from '../../api/authAPIs';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { authTypes } from '../../utils/constants';
import { loginAction } from '../../redux/authSlice';
import PasswordChecklist from 'react-password-checklist';
import Swal from 'sweetalert2';

const ResetPassword = () => {
    const { t } = useTranslation();
    const toast = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');

    function handleSetPassword(event) {
        setPassword(event.target.value);
    }

    function handleSetMatchPassword(event) {
        setMatchPassword(event.target.value);
    }

    const {
        control,
        watch,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm({ mode: 'onChange' });

    const watchedPassword = watch('newPassword');

    const onSubmit = (data) => {
        dispatch(showLoader());
        resetPasswordAPI({
            newPassword: data.newPassword,
            confirmPassword: data.confirmPassword,
            otp: searchParams.get('otp'),
            authType: authTypes.phone,
        })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    text: t('password_reset_successfully'),
                    confirmButtonText: t('close'),
                });
                if (res?.data?.data) {
                    dispatch(loginAction(res?.data?.data));
                    navigate(`/`);
                    return;
                }
                setTimeout(() => {
                    navigate(`/login`);
                }, 1000);
            })
            .catch((error) => {
                const errorList = error.response?.data?.statusMessage || ['server error'];
                Swal.fire({
                    icon: 'error',
                    title: errorList,
                    confirmButtonText: t('close'),
                });
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    return (
        <div className="auth-bx">
            <div className="title">
                <h3>{t('re_Enter_new_password')}</h3>
                <div className="text reset-text">{t('re_Enter_new_password_pref')}</div>
            </div>

            <div className="form-bx">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column">
                    <Toast ref={toast} />

                    <div className="field">
                        <label
                            htmlFor="newPassword"
                            className={classNames({ 'p-error': errors.newPassword })}>
                            <span className="red-asterisk">*</span> {t('password')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{
                                    required: 'Password is required.',
                                    minLength: {
                                        value: 8,
                                    },
                                    maxLength: {
                                        value: 16,
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Password
                                            id={field.name}
                                            {...field}
                                            inputRef={field.ref}
                                            className={classNames({
                                                'p-invalid': fieldState.error,
                                            })}
                                            toggleMask
                                            feedback={false}
                                            value={password}
                                            onChange={(e) => {
                                                handleSetPassword(e);
                                                field.onChange(e.target.value);
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </span>
                        <PasswordChecklist
                            rules={['minLength', 'capital', 'specialChar', 'number']}
                            minLength={8}
                            maxLength={16}
                            value={password}
                            valueAgain={matchPassword}
                            className="check-list-items"
                            messages={{
                                minLength: t('password_has_at_least_characters'),
                                specialChar: t('password_has_special_characters'),
                                number: t('password_has_a_number'),
                                capital: t('password_has_a_capital_letter'),
                            }}
                        />
                    </div>

                    <div className="field">
                        <label
                            htmlFor="confirmPassword"
                            className={classNames({ 'p-error': errors.confirmPassword })}>
                            <span className="red-asterisk">*</span> {t('confirm_password')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{
                                    required: 'Password is required.',
                                    validate: (value) =>
                                        value === watchedPassword || 'The passwords do not match',
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Password
                                            id={field.name}
                                            {...field}
                                            inputRef={field.ref}
                                            className={classNames({
                                                'p-invalid': fieldState.error,
                                            })}
                                            toggleMask
                                            feedback={false}
                                            value={matchPassword}
                                            onChange={(e) => {
                                                handleSetMatchPassword(e);
                                                field.onChange(e.target.value);
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </span>
                    </div>

                    <Button
                        className="btn"
                        label={t('submit')}
                        type="submit"
                        disabled={!isDirty || !isValid}
                    />
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
