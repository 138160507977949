import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import deliveryImage from '../../assets/images/delivery.svg';
import moneyImage from '../../assets/images/money.svg';
import returnImg from '../../assets/images/return.svg';
import '../../assets/css/shared/service-section.css';

const Services = () => {
    const { t } = useTranslation();

    return (
        <div className="container_bx">
            <div className="services-bx">
                <Link to="">
                    <span className="ser-img">
                        <img src={deliveryImage} alt="pic" />
                    </span>
                    <span className="text">{t('free_delivery')}</span>
                </Link>
                <Link to="">
                    <span className="ser-img">
                        <img src={moneyImage} alt="pic" />
                    </span>{' '}
                    <span className="text">{t('cash_on_delivery')}</span>
                </Link>
                <Link to="">
                    <span className="ser-img">
                        <img src={moneyImage} alt="pic" />
                    </span>
                    <span className="text">{t('security_payment')}</span>
                </Link>
                <Link to="">
                    <span className="ser-img">
                        <img src={returnImg} alt="pic" />
                    </span>
                    <span className="text">{t('easy_return')}</span>
                </Link>
            </div>
        </div>
    );
};

export default Services;
