import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    background: '#ffffff',
    color: '#212121',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

const sweetAlert = {
    success: (text) => {
        Toast.fire({
            icon: 'success',
            title: text,
        });
    },
    error: (text) => {
        Toast.fire({
            icon: 'error',
            title: text,
        });
    },
};

export default sweetAlert;
