import React from 'react';
import { useTranslation } from 'react-i18next';
import sweetAlert from '../../helpers/sweetAlert';
import '../../assets/css/shared/counter.css';

const Counter = ({ disabled = false, num = 1, setNum = () => {} }) => {
    const { t } = useTranslation();
    const incNum = () => {
        setNum(Number(num) + 1);
    };
    const decNum = () => {
        if (num > 1) {
            setNum(Number(num) - 1);
        }
    };
    const handleChange = (e) => {
        if (e.target.value < 1) {
            e.target.value = 1;
            sweetAlert.error(t('minQuantity'));
        }

        if (typeof +e.target.value === 'number' && +e.target.value > 0 && +e.target.value !== NaN) {
            setNum(e.target.value);
        }
    };

    return (
        <div className="btn-group">
            <div className="">
                <button
                    className="minus"
                    type="button"
                    onClick={decNum}
                    disabled={disabled || num == 1}>
                    -
                </button>
            </div>
            <input
                type="text"
                value={num}
                onChange={handleChange}
                disabled={disabled}
                min="1"
                minLength={1}
            />
            <div className="">
                <button className="plus" type="button" onClick={incNum} disabled={disabled}>
                    +
                </button>
            </div>
        </div>
    );
};

export default Counter;
