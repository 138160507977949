import React, { useEffect, useState } from 'react';
import { getOrderDetails } from '../../../api/ordersAPIs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/loaderSlice';
import { useNavigate, useParams } from 'react-router-dom';
import NoData from '../../../components/NoData/NoData';
import BackButton from '../../../components/BackButton/BackButton';
import OrderSummary from '../../../components/OrderDetails/OrderSummary';
import OrderProducts from '../../../components/OrderDetails/OrderProducts';
import OrderShippingDetails from '../../../components/OrderDetails/OrderShippingDetails';
import OrderPriceDetails from '../../../components/OrderDetails/OrderPriceDetails';
import './OrderDetails.css';
import useForceRerender from '../../../hooks/useForceRerender';

export default function OrderDetails() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { orderId } = useParams();
    const dispatch = useDispatch();

    const [rerender, forceRerender] = useForceRerender();

    const [orderDetails, setOrderDetails] = useState([]);

    async function handleOrderDetails(id) {
        dispatch(showLoader());
        try {
            const { data } = await getOrderDetails(id);

            setOrderDetails(data?.data);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    useEffect(() => {
        handleOrderDetails(orderId);
    }, [orderId, rerender]);

    if (!orderDetails) return <NoData productShape={true} text={t('no_items')} />;

    return (
        <section className="order_details__section">
            <div className="basic-info-bx">
                <div className="back__wrapper mb-6">
                    <BackButton
                        btnText={t(`back-to-all-orders`)}
                        btnClick={(e) => {
                            e.preventDefault();
                            navigate(`/profile/orders`);
                        }}
                    />
                </div>
                <OrderSummary orderDetails={orderDetails} />
                <OrderProducts orderDetails={orderDetails} forceRerender={forceRerender} />
            </div>
            <div className="shipping_details my-4">
                <h2>{t(`shipping_details`)}</h2>
                <div className="basic-info-bx my-2">
                    <OrderShippingDetails shippingDetails={orderDetails?.billingAddress} />
                </div>
            </div>
            <div className="payment_method">
                <h2>{t('paymentMethod')}</h2>
                <div className="basic-info-bx my-2 flex align-items-center justify-content-start gap-2">
                    <span className="payment_radio">
                        <span></span>
                    </span>
                    <p className="flex-1">{orderDetails?.paymentMethod?.title}</p>
                    <div className="payment_icon">
                        <img
                            src={orderDetails?.paymentMethod?.icon}
                            width={'100%'}
                            height={'100%'}
                            alt={orderDetails?.paymentMethod?.title}
                        />
                    </div>
                </div>
            </div>
            <div className="price_details">
                <OrderPriceDetails
                    priceDetails={orderDetails?.priceDetails}
                    orderDetails={orderDetails}
                />
            </div>
        </section>
    );
}
