import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Prev } from '../../assets/images/prev.svg';
import { ReactComponent as Next } from '../../assets/images/next.svg';
import { ReactComponent as First } from '../../assets/images/first.svg';
import { ReactComponent as Last } from '../../assets/images/last.svg';
import { useLocation } from 'react-router-dom';
import './Pagination.css';

export default function Pagination({
    totalItems = 1,
    itemsPerPage = 10,
    currentPage = 0,
    onPageChange = () => {},
    handelScroll = () => {},
}) {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const totalPages = Math.ceil(totalItems / itemsPerPage) || 1;
    const isLastPage = currentPage === totalPages - 1;
    const hasNextPage =
        currentPage < totalPages - 1 && totalItems > (currentPage + 1) * itemsPerPage;

    useEffect(() => {
        if (!pathname.includes('assoicate') && !pathname.includes('product-details'))
            window.scroll({ top: 0, behavior: 'smooth' });
        if (handelScroll && currentPage > 0) handelScroll();
        else window.scroll({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    // note pages send to api starts with 0, but we show it to user starting from 1

    return (
        <div className="pagination">
            <button
                onClick={() => {
                    handelScroll();
                    onPageChange(0);
                }}
                className="pagination-arrows"
                disabled={currentPage === 0}>
                {/* {t('first')} */}
                <First />
            </button>

            <span className="paginationSpan">
                {/* show only 5 pages and the current page in the middle */}
                <button
                    className="pagination-buttons"
                    onClick={() => {
                        handelScroll();
                        onPageChange(currentPage - 1);
                    }}
                    disabled={currentPage === 0}>
                    <Prev />
                    {/* {t('previous')} */}
                </button>

                {/* {totalPages > 5 && currentPage > 2 && (<span className="pagination-dots">...</span>)} */}

                {Array.from({ length: totalPages > 5 ? 5 : totalPages }).map((_, index) => {
                    const pageNumber = currentPage + index;
                    return pageNumber >= 0 && pageNumber < totalPages ? (
                        <button
                            key={pageNumber}
                            onClick={() => {
                                handelScroll();
                                onPageChange(pageNumber);
                            }}
                            className={
                                pageNumber === currentPage
                                    ? 'pagination-buttons active'
                                    : 'pagination-buttons'
                            }>
                            {pageNumber + 1}
                        </button>
                    ) : null;
                })}

                {/* {totalPages > 5 && totalPages - currentPage > 3 && (<span className="pagination-dots">...</span>)} */}
                <button
                    className="pagination-buttons"
                    onClick={() => {
                        handelScroll();
                        onPageChange(currentPage + 1);
                    }}
                    disabled={!hasNextPage || isLastPage}>
                    {/* {t('next')} */}
                    <Next />
                </button>
            </span>
            <button
                onClick={() => {
                    handelScroll();
                    onPageChange(totalPages - 1);
                }}
                className="pagination-arrows"
                disabled={isLastPage}>
                {/* {t('last')} */}
                <Last />
            </button>
        </div>
    );
}
