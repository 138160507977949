import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CheckoutCreditCard({ card = {} }) {
   const { t } = useTranslation();
   return (
      <div className="flex align-items-center" style={{ paddingInlineStart: '15px' }}>
         <div className="">
            <p>
               <span>{t('cardNumber')} ****</span>
               <span>{card?.cardNumber?.slice(-4)}</span>
               {card?.default ? (
                  <span className="mx-2 default-card">{`${t('default')}`}</span>
               ) : null}
            </p>
            <p>
               {t('EXP')} {card?.expiryDate}
            </p>
            {card?.expired ? <p className="text-red-500">{t('expired')}</p> : null}
         </div>
      </div>
   );
}
