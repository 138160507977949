import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { getBanners } from '../../api/commonAPIs';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/shared/slider-carousel.css';
import NoData from '../../components/NoData/NoData';

const GallerySliderOne = () => {
    const dispatch = useDispatch();
    const [banners, setBanners] = useState([]);

    async function getBannersHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getBanners();
            setBanners(data?.data?.top?.filter((banner) => banner?.enabled));
        } catch (error) {
            console.log('error', error);
        }
        dispatch(hideLoader());
    }

    function handleLink(banner) {
        if (banner?.brandName) {
            return `/brand/${banner?.brandName}/${banner?.brandId}?${
                banner?.tagId ? `tagId=${banner?.tagId}` : ''
            }${banner?.categoryId ? `&categoryId=${banner?.categoryId}` : ''}${
                banner?.subCategoryId ? `&subCategoryId=${banner?.subCategoryId}` : ''
            }`;
        } else {
            return `/`;
        }
    }

    useEffect(() => {
        getBannersHandler();
    }, []);

    return (
        <section className="slider-section container_bx">
            {banners?.length > 0 ? (
                <div className="gray-slider">
                    <Carousel
                        containerClass="container"
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        centerMode={false}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        slidesToSlide={1}
                        focusOnSelect={false}
                        minimumTouchDrag={80}
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        draggable={false}
                        responsive={{
                            desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
                            mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
                            tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
                        }}
                        arrows
                        infinite
                        showDots
                        swipeable
                        pauseOnHover
                        keyBoardControl
                        shouldResetAutoplay>
                        {banners?.map((banner) => (
                            <Link
                                className="carousel-item"
                                key={banner?.id}
                                to={handleLink(banner)}
                                onClick={() => {
                                    window.scroll({ top: 0, behavior: 'smooth' });
                                }}>
                                <img
                                    className="slider-product-img"
                                    src={banner?.image}
                                    alt={banner?.alt}
                                />
                            </Link>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <NoData />
            )}
        </section>
    );
};

export default GallerySliderOne;
