import React from 'react';
import { ReactComponent as NoOrderSvg } from '../../../assets/images/No_Order.svg';
import { ReactComponent as Arrow } from '../../../assets/images/btn-arrow.svg';
import { useTranslation } from 'react-i18next';
import './NoOrder.css';

export default function NoOrder() {
    const { t } = useTranslation();
    return (
        <div className="no-data mt-4 no-order">
            <span className="inline-block">
                <NoOrderSvg />
            </span>
            <p className="no-order__text">{t(`no_order_description`)}</p>
            <div>
                <button className="browse-btn">
                    {t(`browse_products`)}
                    <span className="inline-block mx-2">
                        <Arrow />
                    </span>
                </button>
            </div>
        </div>
    );
}
