import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteCustomerAddress, updateCustomerAddress } from '../../api/CustomerAPIs';
import { ReactComponent as Back } from '../../assets/images/back.svg';
import sweetAlert from '../../helpers/sweetAlert';
import { hideLoader, showLoader } from '../../redux/loaderSlice';

export default function ChangeAddressModal({
    addressList = [],
    showChangeModal = false,
    setShowChangeModal = () => {},
    forceRerender = () => {},
    selectedAddressID = '',
    setSelectedAddressID = () => {},
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedAddress, setSelectedAddress] = useState();

    function handleReset() {
        setShowChangeModal(false);
    }

    async function deleteHandler(address) {
        try {
            await deleteCustomerAddress(address?.id);
            sweetAlert.success(t('address_deleted_successfully'));
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    async function changeDefaultAddress(address) {
        const formData = {
            id: address?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            countryId: address?.country?.id,
            addressTypeId: address?.addressType?.id,
            phoneNumber: address?.phone,
            additionalNotes: address?.additionalNotes,
            addressLineAr: address?.addressLine,
            addressLineEn: address?.addressLine,
            latitude: address?.latitude,
            longitude: address?.longitude,
            isDefault: true,
        };

        try {
            await updateCustomerAddress(formData);
            sweetAlert.success(t('default_address_updated_successfully'));
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    return (
        <>
            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={showChangeModal}
                onHide={handleReset}
                style={{ width: '65vw', maxWidth: '750px' }}
                draggable={false}
                resizable={false}
                className="add-address__modal">
                <div className="change-address-modal">
                    <div className="mb-3">
                        <h3 className="mb-1">{t('change_address')}</h3>
                        <p>{t(`change_address_info`)}</p>
                    </div>
                    <div className="addresses__wrapper">
                        {addressList?.length > 0 &&
                            addressList?.map((address, index) => (
                                <div
                                    className={`address-item gap-1 flex-wrap ${
                                        selectedAddress === address?.id ? 'selected' : ''
                                    }`}
                                    key={index}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedAddress(address?.id);
                                    }}>
                                    <div className="address-item-body">
                                        <h3>
                                            {address?.firstName} {address?.lastName}
                                        </h3>
                                        <p>{address?.addressLine}</p>
                                        <p>
                                            {address?.phone}{' '}
                                            <span>
                                                {address?.phoneVerified && (
                                                    <span className="verified">
                                                        {t('verified')}
                                                    </span>
                                                )}
                                            </span>
                                            {/* : <span className='main-color' onClick={() => verifyPhoneHandler(address)}>{t("not_verified")}</span>}</span> */}
                                        </p>
                                    </div>
                                    <div className="address-item-actions">
                                        <div className="address-item-header-btns flex align-items-center justify-content-end flex-wrap">
                                            <button
                                                className="text-btn min-w-0 text-color"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    deleteHandler(address);
                                                }}>
                                                {t('delete')}
                                            </button>
                                            {/* <button
                                                className="text-btn min-w-0 main-color"
                                                onClick={() => {
                                                    // editHandler(address);
                                                }}>
                                                {t('edit')}
                                            </button> */}
                                            <div className="inline-flex align-items-center gap-1">
                                                <span className="text-color">
                                                    {t('default_address')}
                                                </span>
                                                <InputSwitch
                                                    checked={address?.isDefault}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        changeDefaultAddress(address);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="map-info flex align-items-center justify-content-end">
                        <button
                            className="main-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedAddressID(selectedAddress);
                                handleReset();
                                setTimeout(() => {
                                    forceRerender();
                                }, 200);
                            }}>
                            {t('save')}
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
