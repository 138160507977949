import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { otpAPI } from '../../api/authAPIs';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { authTypes } from '../../utils/constants';
import { resendOtpAPI } from '../../api/authAPIs';
import OtpInput from 'react-otp-input';
import Swal from 'sweetalert2';
import Countdown from 'react-countdown';

const ForgetPasswordOtp = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [disableResendButton, setDisableResendButton] = useState(false);
    const [countdown, setCountdown] = useState(30); // Initial countdown value

    const {
        handleSubmit,
        formState: { errors, isDirty, isValid },
        control,
    } = useForm();

    const onSubmit = (data) => {
        dispatch(showLoader());
        otpAPI({
            Key: searchParams.get('Key'),
            otp: data.otp,
            authType: authTypes.phone,
        })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    text: t('otp_verified_successfully'),
                    confirmButtonText: t('close'),
                });
                setTimeout(() => {
                    navigate(`/reset-password?otp=${data.otp}`);
                }, 1000);
            })
            .catch((error) => {
                const errorList = error.response?.data?.statusMessage || ['server error'];
                Swal.fire({
                    icon: 'error',
                    title: errorList,
                    confirmButtonText: t('close'),
                });
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };

    const Completionist = () => <p className="resend-title">{t('enable_resend')}</p>;
    const renderer = ({ seconds, completed }) => {
        if (completed) return <Completionist />;
        else
            return (
                <p className="resend-title">
                    {t('resend_in')} <span>00:{seconds}</span>
                </p>
            );
    };

    const handelResendOtp = () => {
        const data = { key: searchParams.get('Key'), authType: authTypes.phone };
        setDisableResendButton(true);
        resendOtpAPI(data)
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    text: t('otp_sent'),
                    confirmButtonText: t('close'),
                });
                navigate(`/forget-password-otp?Key=${res?.data?.data?.key}`);
                startCountdown();
            })
            .catch((error) => {
                const errorList = error?.response?.data?.statusMessage || ['server error'];
                Swal.fire({
                    icon: 'error',
                    title: errorList,
                    confirmButtonText: t('close'),
                });
            });
    };

    const startCountdown = () => {
        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount === 1) {
                    clearInterval(interval);
                    setDisableResendButton(false); // Enable the button
                }
                return prevCount - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        if (disableResendButton) {
            startCountdown();
        }
    }, [disableResendButton]);

    return (
        <div className="auth-bx">
            <div className="title reset-title">
                <h3>{t('otp_code')}</h3>
            </div>

            <div className="form-bx">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column">
                    <Toast ref={toast} />

                    <div className="otp-num">
                        <div className="field">
                            <span className="p-float-label">
                                <Controller
                                    name="otp"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                        minLength: {
                                            value: 4,
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <OtpInput
                                                value={field.value}
                                                onChange={field.onChange}
                                                numInputs={4}
                                                minLength={0}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => (
                                                    <InputText keyfilter="int" {...props} />
                                                )}
                                            />
                                        </>
                                    )}
                                />
                            </span>
                        </div>
                    </div>

                    <Button
                        className="btn"
                        label={t('submit')}
                        type="submit"
                        disabled={!isDirty || !isValid}
                        onClick={() => {
                            window.scroll({ top: 0, behavior: 'smooth' });
                        }}
                    />
                </form>

                <div className="my-3 text-center">
                    <Countdown date={Date.now() + countdown * 1000} renderer={renderer} />
                    <button
                        disabled={disableResendButton}
                        onClick={handelResendOtp}
                        className="text-btn mt-3">
                        {t('resend')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ForgetPasswordOtp;
