import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import defaultImg from '../../assets/images/default-img.svg';

export default function MiniCartModal({
    visible,
    setVisible,
    productData = {},
    responseData = {},
}) {
    const { t } = useTranslation();

    return (
        <>
            <Dialog
                contentClassName="cart-popup-bx"
                showHeader={false}
                visible={visible}
                style={{
                    width: '50vw',
                    height: 'auto',
                    maxHeight: '70vh',
                    borderRadius: '30px',
                    overflow: 'hidden',
                }}
                contentStyle={{ padding: '60px 50px', borderRadius: '30px' }}
                onHide={() => setVisible(false)}
                draggable={false}
                resizable={false}
                closable={true}
                closeOnEscape>
                <div className="product-main-info">
                    <div className="product-image__wrapper">
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            src={productData.media[0].url ?? defaultImg}
                            alt="pic"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = defaultImg;
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}>
                        <h1 className="m-0">
                            {typeof productData.name === 'string' && productData.name}
                        </h1>
                        <p style={{ color: 'var(--orange-color)' }}>{t(`added_to_cart`)}</p>
                    </div>
                </div>

                <div
                    className="cart-total__wrapper"
                    style={{
                        margin: '28px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <div>
                        <h3>{t(`cart_total`)}</h3>
                    </div>
                    <div>
                        <h3>
                            {responseData?.data?.totalCartPrice &&
                                `${t('sar')} ${parseFloat(
                                    responseData.data.totalCartPrice,
                                )?.toFixed(2)}`}
                        </h3>
                    </div>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                    <Link
                        to="/cart/couponId="
                        style={{ flex: '1' }}
                        onClick={() => {
                            setVisible(false);
                            window.scroll({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }}>
                        <Button
                            label={t(`view_cart`)}
                            className="login-popup-btn"
                            style={{
                                backgroundColor: 'var(--orange-color)',
                                border: '1px solid var(--orange-color)',
                                borderRadius: '10px',
                                width: '100%',
                            }}
                        />
                    </Link>

                    <Button
                        label={t(`continue_shopping`)}
                        onClick={() => setVisible(false)}
                        className="none-popup-btn"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#312B29',
                            padding: '10px 20px',
                            border: '1px solid #312B29',
                            borderRadius: '10px',
                            flex: '1',
                        }}
                    />
                </div>
            </Dialog>
        </>
    );
}
