import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import NoData from '../../components/NoData/NoData';
import { useDispatch } from 'react-redux';
import { getAppSettings } from '../../api/commonAPIs';
import { hideLoader, showLoader } from '../../redux/loaderSlice';

export default function PrivacyPolicy() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [privacyPolicy, setPrivacyPolicy] = useState('');

    async function getPrivacyPolicyDataHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getAppSettings();
            // setTermsAndConditions(response.data.data);
            const privacyWeb = data?.data?.find((item) => item?.key === 'WebPrivacyPolicy');
            console.log(privacyWeb, 'data');
            setPrivacyPolicy(privacyWeb?.value);
            console.log(data);
        } catch (error) {
            console.error('Error fetching terms and conditions', error);
        } finally {
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        getPrivacyPolicyDataHandler();
    }, []);

    return (
        <section className="privacyPolicy basic-info-bx mb_20">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="basic-info-bx-inner">
                            <h2 className="main-heading">{t('privacyPolicy')}</h2>
                            <div className="order-list">
                                {privacyPolicy ? <>{parse(`${privacyPolicy}`)}</> : <NoData />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
