import { createSlice } from '@reduxjs/toolkit';
import { combinationAttributeAPI } from '../api/productAPIs';

export const combinationAttributeSlice = createSlice({
    name: 'combinationAttributes',
    initialState: {
        combinationAttribute: [],
        loading: false,
    },
    extraReducers: {
        [combinationAttributeAPI.pending]: (state) => {
            state.loading = true;
        },
        [combinationAttributeAPI.fulfilled]: (state, action) => {
            state.combinationAttribute = action.payload;
            state.loading = false;
        },
        [combinationAttributeAPI.rejected]: (state) => {
            state.loading = false;
        },
        
    },
});

export default combinationAttributeSlice.reducer;
