import axios from 'axios';
import Cookies from 'js-cookie';
import { clearMiniCartModal } from '../redux/addToCart';
import { store } from '../redux/store';
import { logoutAction } from './../redux/authSlice';

export const baseInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

baseInstance.interceptors.request.use(async (req) => {
    let lang = Cookies.get('i18next') || 'en';
    let langId = 1;
    if (lang === 'ar') langId = 2;
    else langId = 1;
    let token = store?.getState()?.authReducer?.token || Cookies.get('token') || '';
    let apiKey = store?.getState()?.authReducer?.apiKey || Cookies.get('apiKey') || '';

    req.headers.platform = '4';
    if (langId) req.headers.language = langId;
    if (token) req.headers.Authorization = `Bearer ${token}`;
    if (apiKey) req.headers.apiKey = apiKey;
    req.headers['Content-Type'] = 'application/json';
    if (req.url === '/Vendor/ApplyAsVendor') req.headers['Content-Type'] = 'multipart/form-data';
    req.headers.Accept = 'application/json';
    return req;
});

baseInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if (err?.response?.status === 401) {
            store.dispatch(logoutAction());
            store.dispatch(clearMiniCartModal());
        }
        return Promise.reject(err);
    },
);
