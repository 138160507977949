import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReviewProductModal from '../Modal/ReviewProductModal';
import OrderStatus from './OrderStatus';
import placeholderImage from '../../assets/images/placeholderImage.png';

export default function OrderProduct({
    product = {},
    orderDetails = {},
    showRateBtn = true,
    forceRerender = () => {},
}) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showReviewModal, setShowReviewModal] = useState(false);

    return (
        <>
            <div className="product-card__wrapper">
                <div
                    className="order-product grid flex-w h-full cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(
                            product?.type == 10
                                ? `/product-details/${product?.id}/assoicate/`
                                : product?.groupId
                                  ? `/product-details/${product?.groupId}/assoicate/${product?.id}`
                                  : `/product-details/${product?.id}`,
                        );
                    }}>
                    <div className="product_image col-6 lg:col-4">
                        <div className="image__wrapper w-full h-full">
                            <img
                                src={product?.imageURL}
                                alt={product?.name}
                                height={'100%'}
                                width={'100%'}
                                style={{ objectFit: 'cover' }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = placeholderImage;
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className={`product_details col-6 ${
                            showRateBtn ? 'lg:col-5' : 'lg:col-8'
                        }`}>
                        <div className="product_details__ID">
                            <p className="order_id">
                                <span>{t(`orderOn`)}</span>
                                <span className="mx-1">#{orderDetails?.id}</span>
                            </p>
                        </div>
                        <div className="product_details__date">
                            <p className="order_date">
                                <span>{t(`orderDate`)}</span>
                                <span className="mx-1">
                                    {orderDetails?.createdOn
                                        ?.split('T')?.[0]
                                        ?.split('-')
                                        .reverse()
                                        .join('/')}
                                </span>
                            </p>
                        </div>
                        <div className="order_quantity">
                            <Tooltip
                                target={`.product_name__title-${product.id}`}
                                position="top"
                                content={product.name}
                            />
                            <span
                                className={`one-line-text font-bold product_name__title-${product.id}`}>
                                {(product?.cart?.quantity > 1 && (
                                    <span
                                        style={{
                                            color: 'var(--orange-color)',
                                            fontWeight: '500',
                                            marginInlineEnd: '4px',
                                        }}>
                                        {`${product?.cart?.quantity} X`}
                                    </span>
                                )) ||
                                    ''}
                                {product?.name}
                            </span>
                            {product?.selectedAttributes?.length > 0 && (
                                <div className="flex gap-2 my-1 align-items-center">
                                    {product?.selectedAttributes?.map((attr, index) => {
                                        if (attr?.type === 3) {
                                            return (
                                                <>
                                                    <span
                                                        className="inline-block"
                                                        style={{
                                                            backgroundColor: attr?.name,
                                                            width: '20px',
                                                            height: '20px',
                                                            borderRadius: '50%',
                                                        }}></span>
                                                    {index !==
                                                    product?.selectedAttributes?.length - 1
                                                        ? ', '
                                                        : ''}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <span key={index} className="one-line-text">
                                                    {attr?.name}
                                                    {index !==
                                                    product?.selectedAttributes?.length - 1
                                                        ? ', '
                                                        : ''}
                                                </span>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                        <div
                            className="order_price font-bold"
                            style={{
                                direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                            }}>
                            <span className="mx-1">{product?.prices?.price ?? 0}</span>
                            <span className="">
                                {orderDetails?.currency?.code
                                    ? t(`${orderDetails.currency.code}`)
                                    : t('SAR')}
                            </span>
                        </div>
                        <div className="order_status">
                            <OrderStatus orderStatus={orderDetails?.orderStatus} />
                        </div>
                    </div>
                    {(orderDetails?.orderStatusId == 30 && !product?.isRated && showRateBtn && (
                        <div className={`col-12 lg:col-3`}>
                            <button
                                className="review_product"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowReviewModal(true);
                                }}>
                                {t(`rate`)}
                            </button>
                        </div>
                    )) ||
                        ''}
                </div>
            </div>
            <ReviewProductModal
                toggleReviewModal={showReviewModal}
                setToggleReviewModal={setShowReviewModal}
                product={product}
                forceRerender={forceRerender}
            />
        </>
    );
}
