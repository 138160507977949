import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import loaderSlice from './loaderSlice';
import wishListReducer from './wishListSlice'
import addToCartReducer from './addToCart'
import compareListReducer from './compareListSlice'
import combinationAttributeReducer from './combinationAttributeSlice'

export const store = configureStore({
    reducer: {
        authReducer: authReducer,
        mainLoader: loaderSlice,
        wishListReducer: wishListReducer,
        addToCartReducer: addToCartReducer,
        compareListReducer: compareListReducer,
        combinationAttributeReducer: combinationAttributeReducer
    },
});
