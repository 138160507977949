import React from 'react';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';

export default function LoadingButton({
    btnClick = () => {},
    btnText = '',
    btnDisabled = false,
    btnClassName = '',
}) {
    const isMiniLoader = useSelector((state) => state.mainLoader.isMiniLoader);

    return (
        <button
            className={`main-btn my-3 py-4 ${btnClassName}`}
            disabled={isMiniLoader || btnDisabled}
            onClick={btnClick}>
            {isMiniLoader ? (
                <div className="loader">
                    <BeatLoader
                        color={'#fff'}
                        loading={isMiniLoader || btnDisabled}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (
                btnText
            )}
        </button>
    );
}
