import React, { useEffect, useState, useMemo, useRef } from "react";
import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { ReactComponent as PinIcon } from "../../assets/images/pin.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import Loader from "../Loader/Loader";
import "./generalMap.css";

function generalMap({ editable = false, pin = { lat: 0, lng: 0 }, setPin = () => { } }) {
  const { t } = useTranslation();
  const list = useMemo(() => ["places"], []);
  const [centerMap, setCenterMap] = useState(pin);
  const [searchBox, setSearchBox] = useState("");
  const data = useJsApiLoader({ id: "google-map-script", googleMapsApiKey: "AIzaSyBmoKsmgZhRx50_hXvAg3buhHHxy03_Sv0" || "", libraries: list });
  const mapRef = useRef();

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places?.length === 0) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    places?.forEach((place) => {
      if (place.geometry && place.geometry.viewport) {
        bounds?.union(place.geometry.viewport);
      } else {
        bounds?.extend(place.geometry.location);
      }
    });
    const nextMarkers = places?.map((place) => ({
      position: place.geometry.location,
    }));
    const nextCenter = nextMarkers?.[0]?.position;
    setCenterMap({ lat: nextCenter?.lat() || "", lng: nextCenter?.lng() || "" });
  }

  const onSBLoad = ref => { setSearchBox(ref); };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              setSearchBox(results[0].formatted_address);
            }
          }
        });
        setCenterMap({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }

  useEffect(() => { setCenterMap(pin); }, [pin]);
  useEffect(() => { if (editable) setPin(centerMap); }, [centerMap]);

  return (
    <div className={`google-map ${editable ? "editable" : "not-editable"}`}>
      {data?.isLoaded ? (
        <GoogleMap
          mapContainerClassName="map-container"
          center={centerMap}
          zoom={18}
          options={{
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            disableDefaultUI: true,
            draggable: true,
            scrollwheel: true,
            scaleControl: true,
            disableDoubleClickZoom: true,
          }}
          onLoad={(map) => { mapRef.current = map; }}
          onClick={(e) => { if (editable) setCenterMap({ lat: e?.latLng?.lat(), lng: e?.latLng?.lng() }); }}
          onDragEnd={() => { if (editable) setPin({ lat: mapRef?.current?.getCenter()?.lat(), lng: mapRef?.current?.getCenter()?.lng() }); }}
        >
          <Marker
            position={centerMap}
            draggable={editable ? true : false}
            onDragEnd={(e) => { setCenterMap({ lat: e.latLng.lat(), lng: e.latLng.lng() }); }}
            animation={window.google.maps.Animation.DROP}
          />
          {editable && (
            <>
              <StandaloneSearchBox
                onPlacesChanged={onPlacesChanged}
                onLoad={onSBLoad}
                options={{ types: ['(cities)'] }}
                onUnmount={() => { setSearchBox(""); }}
              >
                <div className="map-search-box">
                  <input
                    type="text"
                    placeholder={t("map_placeholder")}
                    className="map-search-input"
                  />
                  <SearchIcon className="map-search-btn" />
                </div>
              </StandaloneSearchBox>
              <button className="current-location-btn" onClick={handleCurrentLocation} style={{ display: "flex !important" }}><PinIcon /> {t("locate_me")}</button>
            </>
          )}
        </GoogleMap>
      ) : <Loader />
      }
    </ div >
  );
}

export default React.memo(generalMap); 