import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCompareList, getRemoveFromCompareList } from '../../../api/compareListAPIs';
import { hideLoader, showLoader } from '../../../redux/loaderSlice';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import NoData from '../../../components/NoData/NoData';
import trashIcon from '../../../assets/images/trash_icon.svg';
import '../../../assets/css/pages/compare.css';

const Compare = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isLogged } = useSelector((state) => state?.authReducer);
    const compareListData = useSelector((state) => state?.compareListReducer?.compareList) || [];
    const local = localStorage.getItem('language');

    useEffect(() => {
        getCompareListData();
    }, [local, isLogged]);

    useEffect(() => {
        dispatch(showLoader());
        setTimeout(() => { dispatch(hideLoader()); }, 1000);
        // remove any duplicate product id from compare list
        let compareListArr = localStorage.getItem('compareList');
        if (compareListArr) {
            compareListArr = compareListArr?.split(',');
            let uniqueArr = [...new Set(JSON.parse(JSON.stringify(compareListArr)))];
            localStorage.setItem('compareList', uniqueArr);
        }
    }, []);

    const getCompareListData = () => {
        dispatch(showLoader());

        let compareListObj = {};

        if (isLogged) {
            compareListObj = { ids: [], isGuest: false };
        } else {
            const compareListArr = localStorage.getItem('compareList')?.split(',') || [];
            compareListObj = { ids: compareListArr?.slice(0, 3), isGuest: true };
        }

        dispatch(getCompareList(compareListObj));
        dispatch(hideLoader());
    };

    const handleRemoveCompare = async (id) => {
        dispatch(showLoader());

        if (isLogged) {
            await dispatch(getRemoveFromCompareList({ productId: id }));
            await dispatch(getCompareList({ ids: [], isGuest: false }));
        } else {
            let compareListArr = localStorage.getItem('compareList')?.split(',') || [];
            compareListArr = compareListArr.filter((item) => item != id);

            localStorage.setItem('compareList', compareListArr);
            dispatch(getCompareList({ ids: compareListArr.slice(0, 3), isGuest: true }));
        }

        dispatch(hideLoader());
    };


    return (
        <section className="compare-section container_bx">
            <Breadcrumb itemName={t('compare')} />
            <div className="grid">
                <div className="col-12 md:col-12 lg:col-12">
                    <div className="compare-table">
                        <div className="table_responsive">
                            {compareListData?.data?.length > 0 ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{t('Item/Product')}</td>
                                            {compareListData != null &&
                                                compareListData?.data != null &&
                                                compareListData?.data.length > 0
                                                ? compareListData?.data?.slice(0, 3).map((item) => {
                                                    return (
                                                        <td>
                                                            <div className="item-header">
                                                                <span className="trash" key={item.id} onClick={() => { handleRemoveCompare(item.id); }}>
                                                                    <img src={trashIcon} alt="pic" />
                                                                </span>
                                                                <Link to={`/product-details/${item.id}`}>
                                                                    <img className="product-img" src={item.media[0].url} alt="pic" />
                                                                    <h3>{item.name}</h3>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    );
                                                })
                                                : null
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('brand')}</td>
                                            {compareListData != null &&
                                                compareListData?.data != null &&
                                                compareListData?.data.length > 0
                                                ? compareListData?.data?.slice(0, 3).map((item) => {
                                                    return (<td>{item.manufacturer.map((brand) => brand.name).join(' | ')}</td>);
                                                }) : null
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('specifications')}</td>
                                            {compareListData != null &&
                                                compareListData?.data != null &&
                                                compareListData?.data.length > 0
                                                ? compareListData?.data?.slice(0, 3).map((item) => {
                                                    return (
                                                        <td>
                                                            <div className="description-list">
                                                                {item.specifications.map(
                                                                    (specification) => {
                                                                        return (
                                                                            <>
                                                                                <div style={{ fontWeight: 'bold', }}>{specification.key}</div>
                                                                                <p>{specification.value}</p>
                                                                            </>
                                                                        );
                                                                    },
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                }) : null
                                            }
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="t-u-case">{t("SKU")}</span>
                                            </td>
                                            {compareListData != null &&
                                                compareListData?.data != null &&
                                                compareListData?.data.length > 0
                                                ? compareListData?.data?.slice(0, 3).map((item) => {
                                                    return <td>{item.sku}</td>;
                                                }) : null
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('price')}</td>
                                            {compareListData != null &&
                                                compareListData?.data != null &&
                                                compareListData?.data.length > 0
                                                ? compareListData?.data?.slice(0, 3).map((item) => {
                                                    return (
                                                        <td>{item.price.price} {t("sar")}</td>
                                                    );
                                                }) : null
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <NoData productShape={true} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Compare;