import { validateApiKeyAPI, getApiKeyAPI } from '../api/apiKeyAPIs';

export const validateApiKey = (apiKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            let isValid;
            isValid = await validateApiKeyAPI(apiKey);

            // old apikey is working
            if (isValid.data) {
                resolve(apiKey);
                return;
            }

            // const {token , key} = await getApiKeyAPI();
            const keyData = await getApiKeyAPI();
            const decodedKey = atob(keyData.data.key);
            const decodedToken = atob(keyData.data.token);

            const finalKey = replaceTokenKey(decodedToken, decodedKey);

            isValid = await validateApiKeyAPI(finalKey);
            if (isValid.data) {
                resolve(finalKey);
                return;
            }
        } catch (e) {
            reject(e);
        }
    });
};

const replaceTokenKey = (token, key) => {
    const isLast = parseInt(token[token.length - 1], 10);
    let tempToken = isLast ? token.split('').reverse().join('') : token;
    const keyIndex = tempToken.indexOf(key);
    if (keyIndex === -1) {
        return null;
    }
    tempToken = isLast
        ? tempToken.replace(key, '').split('').reverse().join('')
        : tempToken.replace(key, '');
    return tempToken.substring(0, tempToken.length - 1);
};
