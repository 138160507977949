import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import NoData from '../../../components/NoData/NoData'

export default function Notification() {
    const { t } = useTranslation();
    const [notification, setNotification] = useState([])

    return (
        <div className="container_bx mt-5">
            <h1>{t('notifications')}</h1>

            {notification.length ? notification?.map((item, index) => (
                <div key={index}>
                    <h2>{item?.title}</h2>
                    <p>{item?.body}</p>
                </div>
            )) : <NoData />}
        </div>
    )
}
