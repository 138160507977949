import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderProduct from './OrderProduct';

export default function OrderProducts({ orderDetails = {}, forceRerender = () => {} }) {
    const { t } = useTranslation();

    return (
        <div className="order-products__wrapper">
            {orderDetails?.items?.map((item, index) => (
                <div key={index}>
                    <OrderProduct
                        product={item}
                        orderDetails={orderDetails}
                        forceRerender={forceRerender}
                    />
                </div>
            ))}
        </div>
    );
}
