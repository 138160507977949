/* eslint-disable no-unsafe-optional-chaining */
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import Pagination from '../../components/Pagination/Pagination';
import NoData from '../../components/NoData/NoData';
import Loader from '../../components/Loader/Loader';
import { ReactComponent as Flag } from '../../assets/images/flag.svg';
import FlagProductReview from '../../components/Modal/FlagProductReview';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const Reviews = ({
    loading = false,
    productReviews = {},
    page = 0,
    setPage = () => {},
    forceRerender = () => {},
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { userId, isLogged } = useSelector((state) => state.authReducer);

    const [flagReviewModal, setFlagReviewModal] = useState(false);
    const [flagReviewId, setFlagReviewId] = useState('');

    async function reportReviewHandler(productReview) {
        // Implement your logic here
        setFlagReviewId(productReview?.id);
        setFlagReviewModal(true);
    }
    return (
        <div className="card-bx reviews-card">
            <h3>{t('reviews')}</h3>

            <div className="reviews-bx">
                <div className="overall">
                    <h4>{t('overall')}</h4>

                    <div className="rate-num">
                        {productReviews?.groupRating?.avgRate?.toFixed(1) || 0}
                        <span>
                            ({productReviews?.groupRating?.totalCount || 0} {t('user')})
                        </span>
                    </div>

                    <div className="rate-stars mt-3 mb-2">
                        <Rating
                            initialValue={productReviews?.groupRating?.avgRate?.toFixed(1) || 0}
                            rtl={lang === 'ar' ? true : false}
                            allowHover={false}
                            iconsCount={5}
                            size={25}
                            allowFraction
                            fillColor="#f86b33"
                            emptyColor="#b4b4b4"
                            readonly
                        />
                    </div>

                    <div className="progress">
                        <div className="item lightGreen">
                            <span>5</span>{' '}
                            <ProgressBar
                                value={
                                    (productReviews?.groupRating?.countRate5?.toFixed(1) * 100) /
                                        productReviews?.groupRating?.totalCount?.toFixed(1) || 0
                                }></ProgressBar>
                        </div>
                        <div className="item green">
                            <span>4</span>{' '}
                            <ProgressBar
                                value={
                                    (productReviews?.groupRating?.countRate4?.toFixed(1) * 100) /
                                        productReviews?.groupRating?.totalCount?.toFixed(1) || 0
                                }></ProgressBar>
                        </div>
                        <div className="item lightOrange">
                            <span>3</span>{' '}
                            <ProgressBar
                                value={
                                    (productReviews?.groupRating?.countRate3?.toFixed(1) * 100) /
                                        productReviews?.groupRating?.totalCount?.toFixed(1) || 0
                                }></ProgressBar>
                        </div>
                        <div className="item orange">
                            <span>2</span>{' '}
                            <ProgressBar
                                value={
                                    (productReviews?.groupRating?.countRate2?.toFixed(1) * 100) /
                                        productReviews?.groupRating?.totalCount?.toFixed(1) || 0
                                }></ProgressBar>
                        </div>
                        <div className="item red">
                            <span>1</span>
                            <ProgressBar
                                value={
                                    (productReviews?.groupRating?.countRate1?.toFixed(1) * 100) /
                                        productReviews?.groupRating?.totalCount?.toFixed(1) || 0
                                }></ProgressBar>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="reviews">
                        <Loader />
                    </div>
                ) : (
                    <div className="reviews">
                        {productReviews?.reviews?.length ? (
                            <>
                                <div className="title">
                                    <div className="text">
                                        <span>{productReviews?.paging?.TotalItems || 0}</span>
                                        <h4>{t('customer-reviews')}</h4>
                                    </div>
                                </div>

                                <ul>
                                    {productReviews?.reviews?.map((item, index) => (
                                        <li key={item?.id}>
                                            <div>
                                                <Rating
                                                    initialValue={(item?.rating).toFixed(1) || 0}
                                                    rtl={lang === 'ar' ? true : false}
                                                    allowHover={false}
                                                    iconsCount={5}
                                                    size={20}
                                                    allowFraction
                                                    fillColor="#f86b33"
                                                    emptyColor="#b4b4b4"
                                                    readonly
                                                />
                                            </div>
                                            <p>{item?.reviewText || ''}</p>
                                            <div className="flex align-items-center justify-content-between gap-4">
                                                <div className="flex-1">
                                                    <h1 className="name">
                                                        {item?.customerName || ''}
                                                    </h1>
                                                    <p className="date pt-0 mb-0">
                                                        {new Date(
                                                            item?.createdOn,
                                                        ).toLocaleDateString(lang, {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                    </p>
                                                </div>
                                                {isLogged && userId !== item?.customerId ? (
                                                    <div
                                                        className="flex align-items-center justify-content-center gap-2 cursor-pointer"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            reportReviewHandler(item);
                                                        }}>
                                                        <span className="flex align-items-center justify-content-center">
                                                            <Flag />
                                                        </span>
                                                        <span
                                                            className="font-bold"
                                                            style={{
                                                                color: 'var(--orange-color)',
                                                            }}>
                                                            {t(`report`)}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                {(productReviews?.paging?.TotalItems > 5 && (
                                    <Pagination
                                        totalItems={productReviews?.paging?.TotalItems || 0}
                                        itemsPerPage={5}
                                        currentPage={page}
                                        onPageChange={(page) => {
                                            setPage(page);
                                        }}
                                        handelScroll={() => {
                                            const reviews = document.querySelector('.reviews-card');
                                            reviews.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    />
                                )) ||
                                    ''}
                            </>
                        ) : (
                            <NoData />
                        )}
                    </div>
                )}
            </div>
            <FlagProductReview
                toggleReviewModal={flagReviewModal}
                setToggleReviewModal={setFlagReviewModal}
                reviewId={flagReviewId}
                setReviewId={setFlagReviewId}
                forceRerender={forceRerender}
            />
        </div>
    );
};

export default Reviews;
