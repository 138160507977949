import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import './OrdersTable.css';
import { Link } from 'react-router-dom';
import OrderProduct from '../../../components/OrderDetails/OrderProduct';

import OrderStatus from '../../../components/OrderDetails/OrderStatus';
import { Carousel } from 'primereact/carousel';

const responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: '1199px',
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1,
    },
];

export default function OrdersTable({ orders = [] }) {
    const { t, i18n } = useTranslation();
    const [ordersData, setOrdersData] = useState(orders);
    const [expandedRows, setExpandedRows] = useState(null);

    const orderNoTemplate = (rowData) => {
        return <span className="text-bold order-list__number">#{rowData.id}</span>;
    };

    const dateTemplate = (rowData) => {
        return <span>{rowData?.createdOn?.split('T')?.[0]}</span>;
    };

    const statusTemplate = (rowData) => {
        return <OrderStatus orderStatus={rowData.orderStatus} />;
    };

    const totalTemplate = (rowData) => {
        return (
            <span>
                <span>
                    {parseFloat(rowData?.orderTotal)?.toFixed(1)} {rowData?.currency?.code}
                </span>
            </span>
        );
    };

    const actionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2">
                <Link to={`/profile/orders/${rowData?.id}`} className="main-btn">
                    {t('details')}
                </Link>
            </div>
        );
    };

    const rowExpansionTemplate = (data) => {
        const orderProduct = data?.product?.length > 0 ? data?.product?.slice(0, 4) : [];
        return (
            <div className="orders-items-list">
                <div className="">
                    {orderProduct?.length > 0 ? (
                        <>
                            <div className="card">
                                <Carousel
                                    value={
                                        i18n.language === 'ar'
                                            ? orderProduct.reverse()
                                            : orderProduct
                                    }
                                    numVisible={2}
                                    numScroll={1}
                                    // draggable={true}
                                    responsiveOptions={responsiveOptions}
                                    circular={false}
                                    itemTemplate={(item) => (
                                        <OrderProduct product={item} orderDetails={data} />
                                    )}
                                />
                            </div>
                            {data?.product?.length > 4 ? (
                                <Link
                                    to={`/profile/orders/${data?.id}`}
                                    className="main-btn"
                                    style={{ margin: 'auto' }}>
                                    {t('see_all')}
                                </Link>
                            ) : null}
                            {/* {data?.product?.map((item, index) => {
                                if (index < 4) {
                                    return (
                                        <div className="product" key={index}>
                                            <OrderProduct product={item} orderDetails={data} />
                                        </div>
                                    );
                                }
                            })}
                            {data?.product?.length > 4 && (
                                <Link
                                    to={`/profile/orders/${data?.id}`}
                                    className="main-btn"
                                    style={{ margin: 'auto' }}>
                                    {t('see_all')}
                                </Link>
                            )} */}
                        </>
                    ) : (
                        <p>{t('no_items')}</p>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="card orders__table">
            <DataTable
                dataKey="id"
                value={ordersData}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                    setExpandedRows(e.data);
                }}
                onRowClick={(e) => {
                    let expandedRowsObj = {};
                    orders.forEach((order) => {
                        if (order.id === e.data.id) {
                            expandedRowsObj[e.data.id] = true;
                        }
                    });
                    if (expandedRows?.[e.data.id] === true) {
                        delete expandedRowsObj[e.data.id];
                    }
                    setExpandedRows(expandedRowsObj);
                }}
                rowExpansionTemplate={rowExpansionTemplate}
                tableStyle={{ minWidth: '60rem' }}>
                {/* <Column expander={true} style={{ width: '5rem' }} /> */}
                <Column field="order" header={t(`orderNumber`)} body={orderNoTemplate} />
                <Column field="date" header={t(`date`)} body={dateTemplate} />
                <Column field="status" header={t(`status`)} body={statusTemplate} />
                <Column field="total" header={t(`total`)} body={totalTemplate} />
                <Column
                    field="actions"
                    header="actions"
                    body={actionsTemplate}
                    headerClassName="actions"
                />
            </DataTable>
        </div>
    );
}

{
    /* <ul className="order-list">
                                    <li>
                                        <p>{t('order')}</p>
                                        <p>{t('date')}</p>
                                        <p>{t('status')}</p>
                                        <p>{t('total')}</p>
                                        <p>&nbsp;</p>
                                    </li>
                                    {ordersData?.map((order, index) => (
                                        <li key={index} className="order-item">
                                            <p>#{order?.id}</p>
                                            <p>{order?.createdOn?.split('T')?.[0]}</p>
                                            <p>{order?.orderStatus}</p>
                                            <p>
                                                <span>
                                                    {order?.currency?.code}{' '}
                                                    {parseFloat(order?.orderTotal)?.toFixed(1)}
                                                </span>{' '}
                                                {t('for')} ({order?.product?.length || 1}{' '}
                                                {t('items')})
                                            </p>
                                            <p>
                                                <Link
                                                    to={`/profile/orders/${order?.id}`}
                                                    className="main-btn">
                                                    {t('review')}
                                                </Link>
                                            </p>
                                        </li>
                                    ))}
                                </ul> */
}
