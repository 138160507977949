import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseInstance } from './baseInstance';

export const getProductAPI = () =>
    baseInstance.post('/Product/GetProductTagHome', { isHome: true });

export const getRelatedProductByID = (obj) =>
    baseInstance.post('/Product/GetRelatedProductsByProductId', obj);

export const getAssociateProductsAPI = (obj) =>
    baseInstance.post(`/Product/GetAssociatedProductsByProductId`, obj);

export const getProductReviewsAPI = (productId, obj) =>
    baseInstance.post(`/Product/GetProductReviews?ProductId=${productId}`, obj?.paging);

export const getProductDetailsAPI = (productId) =>
    baseInstance.post(`/Product/GetProductDetails?ProductId=${productId}`);

export const combinationAttributeAPI = createAsyncThunk(
    '/Product/GetProductCombinations',
    async (data) => {
        try {
            const res = await baseInstance.get(
                `/Product/GetProductCombinations?ProductId=${data?.id}&MappingId=${data?.groupId}`,
            );
            return res?.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const addProductReviewAPI = (obj) => baseInstance.post('/Product/AddProductReview', obj);

export const flagProductReviewAPI = (obj) => baseInstance.post('/Product/FlagProductReview', obj);

export const getSortingList = () => baseInstance.get('/Product/GetSortingList');

export const getSearchSuggestionsByKeyword = (Keyword) =>
    baseInstance.get(`/Product/GetSearchSuggestionsByKeyword?Keyword=${Keyword}`);
