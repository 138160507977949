import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Fav } from '../../assets/images/favourite.svg';
import { useTranslation } from 'react-i18next';
import defaultImg from '../../assets/images/default-img.svg';
import './AssociateItem.css';

const AssociateItem = ({
    id = '',
    productId = '',
    name = '',
    img = '',
    price = '',
    priceOld = '',
    isWishlist = false,
}) => {
    const { t } = useTranslation();
    const { assoicateId } = useParams();

    return (
        <div className={`new-cart-item ${assoicateId == id ? 'active' : ''}`}>
            <div className="img-holder">
                <Link
                    to={`/product-details/${productId}/assoicate/${id}`}
                    className="product-img"
                    onClick={() => {
                        window.scroll({ top: 0, behavior: 'smooth' });
                    }}>
                    {img ? (
                        <img
                            className="product-img"
                            src={img}
                            alt="pic"
                            onError={(e) => {
                                e.target.src = defaultImg;
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <img
                            className="product-img"
                            src={defaultImg}
                            alt="pic"
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </Link>
            </div>
            <Link
                className="name text-center"
                to={`/product-details/${productId}/assoicate/${id}`}
                onClick={() => {
                    window.scroll({ top: 0, behavior: 'smooth' });
                }}>
                {(price && (
                    <p className="price text-center">
                        {price} {t('sar')}
                    </p>
                )) ||
                    ''}
                {(priceOld && (
                    <p className="price-old text-center">
                        {priceOld} {t('sar')}
                    </p>
                )) ||
                    ''}
            </Link>
        </div>
    );
};

export default AssociateItem;
