import StepOne from '../pages/VendorApply/StepOne';
import StepTwo from '../pages/VendorApply/StepTwo';
import StepThree from '../pages/VendorApply/StepThree';
import { STEPS } from './constants';

export const getCurrentForm = (step) => {
    switch (step) {
        case STEPS.BUSIENSS:
            return <StepOne />;
        case STEPS.DOCUMENT:
            return <StepTwo />;
        case STEPS.INFO:
            return <StepThree />;
        default:
            return <h1>invalid step</h1>;
    }
};
