import { InputSwitch } from 'primereact/inputswitch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CardIcon } from '../../../assets/images/Visa.svg';

export default function PaymentCard({
    card = {},
    deleteCard = () => {},
    handleDefaultCard = () => {},
    handleEditCard = () => {},
}) {
    const { t } = useTranslation();

    return (
        <div className="address-item flex-wrap payment-card-wrapper">
            <div className="card-item-body flex gap-2">
                <div className="card-icon-wrapper" style={{ width: '26px', height: '26px' }}>
                    <CardIcon className="card-icon w-full h-full" />
                </div>
                <div className="card-main-info">
                    <p className="holder-name">{card?.holderName}</p>
                    <p className="mb-1">
                        {t('cardNumber')}:{' '}
                        <span>{card?.cardNumber?.replace(/\d(?=\d{4})/g, '*')}</span>
                    </p>
                    <p className="m-1">
                        {t('EXP')}
                        <span className="mx-2">{card?.expiryDate}</span>
                    </p>
                    <p className="card-status mb-0">
                        {card?.expired ? <span className="expired">{t('expired')}</span> : ''}
                    </p>
                </div>
            </div>
            <div className="card-item-actions w-full lg:w-auto">
                <div className="card-item-header-btns flex align-items-center justify-content-end flex-wrap">
                    <button
                        disabled={card?.default}
                        className="text-btn min-w-0 text-color"
                        onClick={() => {
                            deleteCard(card?.id);
                        }}>
                        {t('delete')}
                    </button>
                    <button
                        className="text-btn min-w-0 main-color"
                        onClick={() => {
                            handleEditCard(card);
                        }}>
                        {t('edit')}
                    </button>
                    <div className="inline-flex align-items-center gap-3">
                        <span className="text-color">{t('default_card')}</span>
                        <InputSwitch
                            checked={card?.default}
                            onChange={() => {
                                handleDefaultCard(card);
                            }}
                            disabled={card?.default}
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
