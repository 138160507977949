import { createSlice } from '@reduxjs/toolkit';
import { getAddToWishList, getRemoveFromWishList, getWishList } from '../api/wishListAPI';

export const wishListSlice = createSlice({
    name: 'wishList',
    initialState: {
        myWishList: [],
        loading: false,
        paging: {},
    },
    reducers: {
        setPaging: (state, action) => {
            state.paging = action.payload;
        },
        clearMyWishList: (state) => {
            state.myWishList = [];
        },
    },
    extraReducers: {
        [getWishList.pending]: (state) => {
            state.loading = true;
        },
        [getWishList.fulfilled]: (state, action) => {
            state.myWishList = action.payload;
            state.loading = false;
        },
        [getWishList.rejected]: (state) => {
            state.loading = false;
        },
        [getAddToWishList.pending]: (state) => {
            state.loading = true;
        },
        [getAddToWishList.fulfilled]: (state, action) => {
            state.myWishList = action.payload;
            state.loading = false;
        },
        [getAddToWishList.rejected]: (state) => {
            state.loading = false;
        },
        [getRemoveFromWishList.pending]: (state) => {
            state.loading = true;
        },
        [getRemoveFromWishList.fulfilled]: (state, action) => {
            state.myWishList = action.payload;
            state.loading = false;
        },
        [getRemoveFromWishList.rejected]: (state) => {
            state.loading = false;
        },
    },
});
export const { setPaging, clearMyWishList } = wishListSlice.actions;
export default wishListSlice.reducer;
