export const STEPS = {
    BUSIENSS: 'BUSIENSS_STEP',
    DOCUMENT: 'DOCUMENT_STEP',
    INFO: 'INFO_STEP',
};

export const authTypes = {
    email: 0,
    apple: 1,
    google: 2,
    facebook: 3,
    twitter: 4,
    phone: 5
};


// export const FIELDS = {
//     BUSIENSS: 'buisness',
//     DOCUMENT: 'document',
//     INFO: 'info',
// }

// export const defaultFields = {
//     buisness: {
//         firstName: '',
//         lastName: '',
//     },
//     document: {},
//     info: {}
// }
