import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import placeholderImage from '../../assets/images/placeholderImage.png';

const ItemsList = ({ list = {} }) => {
    const { couponId } = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation();
    return (
        <>
            <div className="flex align-items-center gap-2">
                <h2>{t('orderDetails')}</h2> <Link to={`/cart/${couponId}`}>{t('edit')}</Link>
            </div>
            <div className="items-list card-border">
                {/* <p className='text-gray-500'>
                    {t('deliveryBetween')}
                    <span className='text-gray-700'> {list?.shipping?.deliveryDate || '2 - 3'}</span>{' '}
                    {t('workingDays')}
                </p> */}
                <ul className="grid">
                    {list?.map((item, index) => (
                        <li
                            key={index}
                            className="col-12 lg:col-6"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                navigate(
                                    item?.type == 10
                                        ? `/product-details/${item?.id}/assoicate/`
                                        : item?.type == 6
                                          ? `/product-details/${item?.groupId}/assoicate/${item?.id}`
                                          : `/product-details/${item?.id}`,
                                )
                            }>
                            <div className="flex align-items-center m-1 py-2 item-card">
                                <div className="item-image mx-2">
                                    <img
                                        src={item?.imageURL}
                                        alt={item?.name}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = placeholderImage;
                                        }}
                                    />
                                </div>

                                <span className="item-details">
                                    <span className="one-line-text">{item?.name}</span>
                                    <span className="text-gray-500">
                                        {t('quantity')}: {item?.cart?.quantity}
                                    </span>
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default ItemsList;
