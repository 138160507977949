import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCustomerAddress } from '../../api/CustomerAPIs';
import { getMyCart } from '../../api/shopingCartAPIs';
import { updateCartIcon } from '../../redux/addToCart';
import NoData from '../../components/NoData/NoData';
import Pagination from '../../components/Pagination/Pagination';
import useForceRerender from '../../hooks/useForceRerender';
import AddressList from './AddressList';
import ItemsList from './ItemsList';
import PriceCard from './PriceCard';
import './Cart.css';
import sweetAlert from '../../helpers/sweetAlert';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import LoadingButton from '../../components/LoadingButton/LoadingButton';

export default function Cart() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMiniLoader = useSelector((state) => state.mainLoader.isMiniLoader);
    const { isLogged } = useSelector((state) => state.authReducer);

    const [rerender, forceRerender] = useForceRerender();

    const [firstRender, setFirstRender] = useState(true);
    const [cartData, setCartData] = useState([]);
    const [cartPrice, setCartPrice] = useState({});
    const [address, setAddress] = useState([]);
    const [selectedAddressID, setSelectedAddressID] = useState('');
    const [selectedAddress, setSelectedAddress] = useState({});
    const [couponCode, setCouponCode] = useState('');
    const [cartPage, setCartPage] = useState(0);
    const [cartPageSize] = useState(20);

    function checkoutHandler() {
        if (selectedAddress?.id && selectedAddress?.phoneVerified) {
            navigate(
                `/checkout/address_id=${selectedAddress?.id || ''}/coupon_code=${couponCode || ''}`,
            );
        } else if (selectedAddress?.id && !selectedAddress?.phoneVerified) {
            sweetAlert.error(t(`please_verify_your_address`));
        } else {
            sweetAlert.error(t(`please_add_your_address`));
        }
    }

    async function getMyCartData() {
        if (!isLogged) return;

        if (firstRender) {
            dispatch(showLoader());
        }
        const formData = {
            PageNumber: cartPage,
            PageSize: cartPageSize,
        };
        try {
            const { data } = await getMyCart(formData);
            setCartData(data?.data);
            setCartPrice(data?.data?.priceDetails);
            setFirstRender(false);
            dispatch(
                updateCartIcon({
                    totalCount: data?.data?.priceDetails?.totalQuantity || 0,
                    totalCartPrice: data?.data?.priceDetails?.total || 0,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getMyAddressData() {
        if (!isLogged) return;
        if (firstRender) {
            dispatch(showLoader());
        }

        try {
            const { data } = await getCustomerAddress();
            setAddress(data?.data);
            setFirstRender(false);
            if (selectedAddressID) {
                setSelectedAddress(data?.data?.list?.find((item) => item.id === selectedAddressID));
            } else {
                setSelectedAddress(data?.data?.list?.find((item) => item.isDefault));
            }
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    useEffect(() => {
        getMyCartData();
        getMyAddressData();
    }, [rerender]);

    useEffect(() => {
        getMyCartData();
    }, [cartPage]);

    if (!cartData || !cartData?.products?.length)
        return <NoData productShape={true} text={t('no_items')} />;

    return (
        <>
            <section className="container_bx cart-section mt-5">
                <div className="grid">
                    <div className="col-12 lg:col-8">
                        <AddressList
                            list={address}
                            defaultAddress={selectedAddress}
                            setDefaultAddress={setSelectedAddress}
                            selectedAddressID={selectedAddressID}
                            setSelectedAddressID={setSelectedAddressID}
                            forceRerender={forceRerender}
                            rerender={rerender}
                        />
                        <ItemsList list={cartData?.products} forceRerender={forceRerender} />
                        {(cartData?.paging?.TotalItems > cartPageSize && (
                            <>
                                <Pagination
                                    totalItems={cartData?.paging?.TotalItems}
                                    itemsPerPage={cartPageSize}
                                    currentPage={cartPage}
                                    onPageChange={(page) => setCartPage(page)}
                                    handelScroll={() => {
                                        const items = document.querySelector('.cart-items-list');
                                        items.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                />
                            </>
                        )) ||
                            ''}
                    </div>

                    <div className="col-12 lg:col-4">
                        <div className="checkout-sidebar">
                            <PriceCard
                                prices={cartPrice}
                                setPrices={setCartPrice}
                                couponCode={couponCode}
                                setCouponCode={setCouponCode}
                                forceRerender={forceRerender}
                            />
                            <LoadingButton btnClick={checkoutHandler} btnText={t('checkout')} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
