import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    BiLogoFacebookSquare,
    BiLogoSnapchat,
    BiLogoYoutube,
    BiLogoInstagram,
} from 'react-icons/bi';
import logo from '../../assets/images/logo-EN.png';
import appStore from '../../assets/images/app-store.svg';
import playStore from '../../assets/images/play-store.svg';
import twitterX from '../../assets/images/Twitter_X_Icon.svg';
import sellLogo from '../../assets/images/sell-logo.svg';
import maestro from '../../assets/images/payment/maestro.svg';
import visa from '../../assets/images/payment/visa.svg';
import mastercard from '../../assets/images/payment/mastercard.svg';
import applePay from '../../assets/images/payment/apple-pay.svg';
import googlePay from '../../assets/images/payment/google-pay.svg';
import '../../assets/css/shared/footer.css';

const Footer = () => {
    const { t } = useTranslation();
    const { isLogged, userType } = useSelector((state) => state.authReducer);
    return (
        <>
            <div className="main-footer container_bx">
                <div className="footer-bx">
                    <div className="footer-item">
                        <div className="footer-logo">
                            <img src={logo} alt="pic" />
                        </div>
                        <div className="social-links">
                            <Link to="">
                                <BiLogoFacebookSquare />
                            </Link>
                            <Link to="">
                                <BiLogoSnapchat />
                            </Link>
                            <Link to="">
                                <BiLogoYoutube />
                            </Link>
                            <Link to="">
                                <img src={twitterX} alt="pic" />
                            </Link>
                            <Link to="">
                                <BiLogoInstagram />
                            </Link>
                        </div>
                        <div className="download-app">
                            <h3>{t(`downloadOutApp`)}</h3>
                            <Link to="">
                                <img src={appStore} alt="pic" />
                            </Link>
                            <Link to="">
                                <img src={playStore} alt="pic" />
                            </Link>
                        </div>
                    </div>

                    <div className="footer-item">
                        <h3>{t('menu')}</h3>
                        <ul>
                            <li>
                                <Link
                                    to="/"
                                    onClick={(e) => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t('home')}
                                </Link>
                            </li>
                            {isLogged ? (
                                <>
                                    <li>
                                        <Link
                                            to="/profile"
                                            onClick={(e) => {
                                                window.scroll({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                });
                                            }}>
                                            {t('my_account')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/profile/orders"
                                            onClick={(e) => {
                                                window.scroll({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                });
                                            }}>
                                            {t('orders')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/profile/addresses"
                                            onClick={(e) => {
                                                window.scroll({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                });
                                            }}>
                                            {t('addresses')}
                                        </Link>
                                    </li>
                                </>
                            ) : null}
                            <li>
                                <Link
                                    to="/cart/couponId="
                                    onClick={(e) => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t('cart')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/wishlist"
                                    onClick={(e) => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t('wishlist')}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-item">
                        <h3>{t(`usefulLinks`)}</h3>
                        <ul>
                            <li>
                                <Link to="/aboutus">{t(`aboutUs`)}</Link>{' '}
                            </li>
                            <li>
                                <Link
                                    to="/contact-us"
                                    onClick={(e) => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t(`contactUs`)}
                                </Link>{' '}
                            </li>
                            <li>
                                <Link
                                    to="/privacy-policy"
                                    onClick={(e) => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t(`privacyPolicy`)}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/terms-and-conditions"
                                    onClick={(e) => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t(`terms_conditions`)}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="news-footer">
                        {isLogged && userType !== 1 ? (
                            <div className="sell-items">
                                <img src={sellLogo} alt="pic" />
                                <Link
                                    to="/vendor-wizard"
                                    onClick={() => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}>
                                    {t('Sell_on_shaari')}
                                </Link>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </div>

            <div className="bottom-footer">
                <div className="Copyright">
                    {t('copy_right')} {new Date().getFullYear()} {t('all_rights_reserved')}
                </div>
                <div className="payment">
                    <div className="item">
                        <Link to="">
                            <img src={maestro} alt="pic" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="">
                            <img src={visa} alt="pic" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="">
                            <img src={mastercard} alt="pic" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="">
                            <img src={applePay} alt="pic" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="">
                            <img src={googlePay} alt="pic" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
