import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { classNames } from 'primereact/utils';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import { customerContactUs } from '../../api/CustomerAPIs';
import sweetAlert from '../../helpers/sweetAlert';

export default function ContactUsForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        control,
        formState: { errors, isDirty, isValid },
        watch,
        reset,
        resetField,
        handleSubmit,
    } = useForm({ mode: 'onChange' });

    const watchedEmail = watch('email');
    // const watchedPhone = watch('phoneNumber');
    // const watchedCountryCode = watch('countryCode');

    const onSubmit = (data) => {
        dispatch(showLoader());

        let loginData = {
            fullName: data?.fullName || '',
            email: data?.email || '',
            phone: data?.phoneNumber || '',
            message: data?.message || '',
        };

        try {
            customerContactUs(loginData)
                .then((response) => {
                    console.log(response);
                    if (response?.data?.statusMessage) {
                        sweetAlert.success(t(`contact_us_success`));
                        reset({
                            fullName: '',
                            email: '',
                            phoneNumber: '',
                            message: '',
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error?.response?.data?.statusMessage) {
                        sweetAlert.error(error?.response?.data?.statusMessage);
                    }
                });
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.statusMessage) {
                sweetAlert.error(error?.response?.data?.statusMessage);
            }
        }

        dispatch(hideLoader());
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="field">
                            <label
                                htmlFor="fullName"
                                className={classNames({ 'p-error': errors.fullName })}>
                                <span className="red-asterisk">*</span> {t('fullName')}
                            </label>
                            <span className="p-float-label">
                                <Controller
                                    name="fullName"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            className={`${classNames({
                                                'p-invalid': fieldState.error,
                                            })} w-full`}
                                            placeholder={t(`full-name_placeholder`)}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                            }}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage('fullName')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="field phone-num">
                            <label
                                htmlFor="phoneNumber"
                                className={classNames({ 'p-error': errors.phoneNumber })}>
                                <span className="red-asterisk">*</span> {t('phone_number')}
                            </label>
                            <div className="phone-fields">
                                <div className="field w-full">
                                    <span className="phone_num">
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                // minLength: {
                                                //     value: watchedCountryCode?.phoneLength,
                                                //     message: t('phone_number_min_length', {
                                                //         length: watchedCountryCode?.phoneLength || 0,
                                                //     }),
                                                // },
                                                // maxLength: {
                                                //     value: watchedCountryCode?.phoneLength,
                                                //     message: t('phone_number_max_length', {
                                                //         length: watchedCountryCode?.phoneLength || 15,
                                                //     }),
                                                // },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    id={field.name}
                                                    value={field.value || ''}
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                    className={`${classNames({
                                                        'p-invalid': fieldState.error,
                                                    })} w-full`}
                                                    placeholder={t('phone_number_placeholder')}
                                                    // maxLength={watchedCountryCode?.phoneLength}
                                                    keyfilter="pint"
                                                />
                                            )}
                                        />
                                    </span>
                                    {getFormErrorMessage('phoneNumber')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="field">
                            <label
                                htmlFor="email"
                                className={classNames({ 'p-error': errors.email })}>
                                <span className="red-asterisk">*</span> {t('email')}
                            </label>
                            <span className="p-float-label">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: t('invalid_email_address'),
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            className={`${classNames({
                                                'p-invalid': fieldState.error,
                                            })} w-full`}
                                            placeholder={t('email_placeholder')}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                            }}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="field">
                            <label
                                htmlFor="message"
                                className={classNames({ 'p-error': errors.message })}>
                                <span className="red-asterisk">*</span> {t('message')}
                            </label>
                            <span className="">
                                <Controller
                                    name="message"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                        pattern: {
                                            message: t('invalid_message'),
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputTextarea
                                            id={field.name}
                                            {...field}
                                            maxLength="100"
                                            rows="5"
                                            placeholder={t('message_placeholder')}
                                            className={`${classNames({
                                                'p-invalid': fieldState.error,
                                            })} w-full`}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage('message')}
                        </div>
                    </div>
                    <div className="col-12">
                        <LoadingButton
                            btnText={t('submit')}
                            btnDisabled={!isDirty || !isValid}
                            btnClassName="w-full"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}
