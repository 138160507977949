import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAddToCart } from '../../api/shopingCartAPIs';
import defaultImg from '../../assets/images/default-img.svg';
import { ReactComponent as Fav } from '../../assets/images/favourite.svg';
import rate from '../../assets/images/rate.svg';
import sliderCart from '../../assets/images/slider-cart.svg';
import { CiMenuKebab } from 'react-icons/ci';
import { setShowMiniCartModal } from '../../redux/addToCart';
import MiniCartModal from '../Modal/MiniCartModal';
import MiniLoginModal from '../Modal/MiniLoginModal';
import { getAddToWishList, getRemoveFromWishList } from '../../api/wishListAPI';

const CartItem = ({
    id = '',
    type = 0,
    img = '',
    title = '',
    rateText = '',
    desc = '',
    price = '',
    priceOld = '',
    isWishlist = false,
    manufacturer = [],
    cart = {},
    label = {},
    stockStatus = {},
    shipping = {},
    associatedId = null,
    productCard = {},
    forceRerender = () => {},
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLogged } = useSelector((state) => state.authReducer);
    const showMiniCartModal =
        useSelector((state) => state.addToCartReducer.showMiniCartModal) || false;
    const responseData = useSelector((state) => state.addToCartReducer.responseData);
    const setShowCartModal = (val) => dispatch(setShowMiniCartModal(val));

    const [visible, setVisible] = useState(false);
    const [isWishlistState, setIsWishlistState] = useState(isWishlist);

    const updateLocalStorage = (action, id) => {
        const idString = id.toString();
        const storedString = localStorage.getItem('wishlist');
        let idArray = storedString ? storedString.split(',').map((item) => item?.trim()) : [];

        if (action === 'add') {
            if (!idArray.includes(idString)) {
                idArray.push(idString);
            }
        } else if (action === 'remove') {
            const indexToRemove = idArray.indexOf(idString);
            if (indexToRemove !== -1) {
                idArray.splice(indexToRemove, 1);
            }
        }

        const updatedString = idArray.join(', ');
        localStorage.setItem('wishlist', updatedString);
    };

    const toggleWishlist = async (id) => {
        if (isLogged) {
            // Add logic here
            if (isWishlistState) {
                // Remove from wishlist
                dispatch(getRemoveFromWishList({ productId: id })).then((data) => {
                    if (
                        data?.payload?.statusCode === 200 ||
                        data?.payload?.statusCode === 204 ||
                        data?.payload === ''
                    ) {
                        setIsWishlistState(false);
                    }
                });
            } else {
                // Add to wishlist
                dispatch(getAddToWishList({ productId: id })).then((data) => {
                    if (data?.payload?.statusCode === 201) {
                        setIsWishlistState(true);
                    }
                });
            }
        } else {
            // setVisible(true);
            if (isWishlistState) {
                updateLocalStorage('remove', id);
                setIsWishlistState(false);
                forceRerender();
            } else {
                updateLocalStorage('add', id);
                setIsWishlistState(true);
                forceRerender();
            }
        }
    };

    const toggleCart = (id) => {
        if (isLogged) {
            const productAttributes = [];
            // const attr = [...attributesinDetails];
            // if (attr) {
            //     attr.forEach((attribute) => {
            //         // debugger;

            //         for (let val of attribute.values) {
            //             // Check some condition to determine which object to replace
            //             if (val.isSelected) {
            //                 // Replace the current object with the new one
            //                 // debugger;
            //                 productAttributes.push({
            //                     attributeId: attribute.id,
            //                     valueId: val.id,
            //                 });
            //             }
            //         }
            //     });
            // }
            dispatch(
                getAddToCart({
                    productId: id,
                    quantity: 1,
                    productAttributes: productAttributes,
                }),
            );
        } else {
            setVisible(true);
        }
    };

    return (
        <>
            <div>
                <div className="img-holder">
                    <div className="labels">
                        {label && (
                            <span style={{ backgroundColor: label?.color || '#000' }}>
                                {label?.name || ''}
                            </span>
                        )}
                        {(shipping?.shippingStatusId == 1 && (
                            <span className="free-shipping">{shipping?.shippingStatus}</span>
                        )) ||
                            ''}
                    </div>
                    <div className="actions">
                        <span
                            className={`fav ${
                                isWishlistState ||
                                localStorage
                                    .getItem('wishlist')
                                    ?.split(',')
                                    ?.map((item) => item?.trim())
                                    .includes(id?.toString())
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => {
                                toggleWishlist(id);
                            }}>
                            {isWishlistState ||
                            localStorage
                                .getItem('wishlist')
                                ?.split(',')
                                ?.map((item) => item?.trim())
                                .includes(id?.toString()) ? (
                                <Fav className="active-svg" />
                            ) : (
                                <Fav />
                            )}
                        </span>
                    </div>
                    {img ? (
                        <img
                            className="product-img"
                            src={img}
                            alt="pic"
                            onError={(e) => {
                                e.target.src = defaultImg;
                            }}
                            onClick={() =>
                                navigate(
                                    type == 10
                                        ? associatedId
                                            ? `/product-details/${id}/assoicate/${associatedId}`
                                            : `/product-details/${id}/assoicate/`
                                        : `/product-details/${id}`,
                                )
                            }
                            style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <img
                            className="product-img"
                            src={defaultImg}
                            alt="pic"
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </div>
                <div
                    className="name"
                    onClick={() => {
                        navigate(
                            type == 10
                                ? associatedId
                                    ? `/product-details/${id}/assoicate/${associatedId}`
                                    : `/product-details/${id}/assoicate/`
                                : `/product-details/${id}`,
                        );
                        window.scroll({ top: 0, behavior: 'smooth' });
                    }}>
                    {(title && <h3>{title}</h3>) || ''}
                    {(manufacturer?.length &&
                        manufacturer?.map((item, index) => (
                            <span key={index} className="manufacturer-span">
                                {' '}
                                {item.name || ''} {index < manufacturer?.length - 1 ? ' | ' : ''}
                            </span>
                        ))) ||
                        ''}
                    {(rateText && (
                        <span className="rate">
                            <img src={rate} alt="pic" />
                            {Number(rateText || 0)?.toFixed(1)}
                        </span>
                    )) ||
                        ''}
                </div>
                {(desc && <div dangerouslySetInnerHTML={{ __html: desc }} />) || ''}
                {(price && (
                    <div className="price flex align-items-center justify-content-between">
                        {price} {t('sar')}
                        {(cart?.allowToCart &&
                            !productCard?.isAllowedQuantities &&
                            stockStatus?.stockStatusId !== 0 &&
                            type !== 10 && (
                                <div
                                    className="cart-shop-icon"
                                    onClick={() => {
                                        toggleCart(id);
                                        // setVisible(true);
                                    }}>
                                    <img src={sliderCart} alt="pic" />
                                </div>
                            )) || (
                            <div
                                className="cart-shop-icon"
                                onClick={() => {
                                    navigate(
                                        type == 10
                                            ? associatedId
                                                ? `/product-details/${id}/assoicate/${associatedId}`
                                                : `/product-details/${id}/assoicate/`
                                            : `/product-details/${id}`,
                                    );
                                    window.scroll({ top: 0, behavior: 'smooth' });
                                }}>
                                <CiMenuKebab />
                            </div>
                        )}
                    </div>
                )) ||
                    ''}
                {(priceOld && <div className="price-old">{priceOld}</div>) || ''}
                <div className="flex align-items-center justify-content-between">
                    {stockStatus?.stockStatusId != null ? (
                        <div
                            className={`product-labels stock-label stock-${stockStatus?.stockStatusId}`}>
                            {stockStatus?.stockStatusId == 0
                                ? stockStatus?.stockStatus
                                : stockStatus?.stockStatusId == 1
                                  ? stockStatus?.stockStatus
                                  : stockStatus?.stockStatus}
                            {stockStatus?.displayStockQuantity && stockStatus?.stockQuantity ? (
                                <span className="stock-quantity">
                                    ({stockStatus?.stockQuantity})
                                </span>
                            ) : (
                                '' || ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}

                    {(shipping?.deliveryDate && (
                        <span className={`shipping-delivery delivery-${shipping?.deliveryDate}`}>
                            {shipping?.deliveryDate}
                        </span>
                    )) ||
                        ''}
                </div>
            </div>
            <MiniLoginModal visible={visible} setVisible={setVisible} />
            {title === responseData?.data?.productName && showMiniCartModal && (
                <MiniCartModal
                    visible={showMiniCartModal}
                    setVisible={setShowCartModal}
                    productData={{
                        media: [{ url: img ? img : defaultImg }],
                        name: title,
                    }}
                    responseData={responseData}
                />
            )}
        </>
    );
};

export default CartItem;
