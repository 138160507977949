import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { useTranslation } from 'react-i18next';
import { getProductAPI } from '../../api/productAPIs';
import NoData from '../../components/NoData/NoData';
import CartItem from '../../components/CartItem/CartItem';

const RecommendedSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    async function getPopularProducts() {
        dispatch(showLoader());

        try {
            const { data } = await getProductAPI();
            let newData = data?.data?.map((item) => {
                return {
                    ...item,
                    products: item?.products?.map((product) => {
                        return {
                            id: product?.id,
                            productCard: product,
                            type: product?.type || 0,
                            category: product?.productType || '',
                            label: product?.label || '',
                            manufacturer: product?.manufacturer || '',
                            cart: product?.cart || '',
                            saleText: product?.prices?.oldPrice ? t('sale') : '',
                            title: product?.name || '',
                            price: product?.prices?.price || '',
                            img: product?.imageURL || '',
                            rateText: product?.rating?.avgRate || '',
                            priceOld: product?.prices?.oldPrice || '',
                            desc: product?.shortDescription || '',
                            isWishlist: product?.isWishlist || false,
                            isCompare: product?.isCompare || false,
                            shipping: product?.shipping || {},
                            stockStatus: product?.stockStatus || {},
                            associatedId: product?.associatedId || null,
                        };
                    }),
                };
            });
            setData(newData);
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getPopularProducts();
    }, []);

    return (
        <section className="recommended-section">
            <div>
                {data?.length ? (
                    data?.map((tag) =>
                        tag?.products?.length > 0 ? (
                            <div
                                key={tag.id}
                                style={{ backgroundColor: tag?.bgColor || '#ffffff' }}>
                                <div className="container_bx py-5">
                                    {tag?.name && (
                                        <div className="flex align-items-center justify-content-between w-full mb-4">
                                            <h4
                                                className="tag-title"
                                                style={{ color: tag?.titleColor || '#ffffff' }}>
                                                {tag?.name}
                                            </h4>
                                            <Link
                                                className="tag-line"
                                                style={{ color: tag?.titleColor || '#ffffff' }}
                                                to={`/tag/${tag?.id}`}
                                                onClick={() => {
                                                    window.scroll({ top: 0, behavior: 'smooth' });
                                                }}>
                                                {t('see_all')}
                                            </Link>
                                        </div>
                                    )}
                                    <div className="grid-section">
                                        {tag?.products?.slice(0, 4)?.map((card) => (
                                            <div key={card.id} className="new-cart-item">
                                                <CartItem
                                                    key={card.id}
                                                    type={card?.type}
                                                    id={card?.id}
                                                    label={card.label}
                                                    manufacturer={card.manufacturer}
                                                    cart={card.cart}
                                                    saleText={card.saleText}
                                                    title={card.title}
                                                    price={card.price}
                                                    img={card.img}
                                                    rateText={card.rateText}
                                                    priceOld={card.priceOld}
                                                    desc={card.desc}
                                                    isWishlist={card.isWishlist}
                                                    isCompare={card.isCompare}
                                                    shipping={card.shipping}
                                                    stockStatus={card.stockStatus}
                                                    associatedId={card?.associatedId}
                                                    productCard={card?.productCard}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null,
                    )
                ) : (
                    <NoData />
                )}
            </div>
        </section>
    );
};

export default RecommendedSection;
