import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCategoriesAPI } from '../../api/categoriseAPIs';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import Carousel from 'react-multi-carousel';
import NoData from '../../components/NoData/NoData';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        partialVisibilityGutter: 40,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        partialVisibilityGutter: 40,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 40,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const CategoryItemSection = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [categoryList, setCategoryList] = useState([]);

    async function getCategoryList() {
        dispatch(showLoader());

        try {
            const { data } = await getCategoriesAPI({
                paging: { TotalItems: 0, PageNumber: 0, PageSize: 20 },
                data: { isHome: true, withAll: false, subCategorySize: 10 },
            });
            setCategoryList(data?.data?.list);
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getCategoryList();
    }, []);

    return (
        <section className="category-items-section container_bx">
            <h3 className="center-title">{t('categories')}</h3>

            {categoryList?.length > 0 ? (
                <div className="category-slider">
                    <Carousel
                        responsive={responsive}
                        draggable={false}
                        infinite={false}
                        swipeable={true}
                        rtl={i18n.language === 'ar' ? true : false}
                        arrows={true}>
                        {categoryList?.map((category, index) => (
                            <div className="item" key={index}>
                                <Link
                                    className="w-full"
                                    to={`/category/${category.name}/${category.id}`}
                                    onClick={() => {
                                        window.scroll({ top: 0, behavior: 'smooth' });
                                    }}>
                                    <div className="img-holder">
                                        <img src={category.image} alt="pic" />
                                    </div>
                                    <h3>{category.name}</h3>
                                </Link>
                            </div>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <NoData />
            )}
        </section>
    );
};

export default CategoryItemSection;
