import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Filter from '../Category/Filter';
import ProductsList from '../Category/ProductsList';
import '../../assets/css/pages/category.css';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { getSortingList } from '../../api/productAPIs';
import { getCategoryProducts } from '../../api/categoriseAPIs';
import { useDispatch } from 'react-redux';
import useForceRerender from '../../hooks/useForceRerender';

const ProductsSearch = () => {
    const params = new URLSearchParams(window.location.search);

    const dispatch = useDispatch();
    const [rerender, forceRerender] = useForceRerender();

    const [paging, setPaging] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(20);

    const [filterResult, setFilterResult] = useState({
        // tagId: params.get('tagId'),
        // brandId: params.get('brand'),
        // catalogID: params.get('category'),
        // keyword: params.get('keyword'),
    });

    const [selectedSort, setSelectedSort] = useState(null);
    const [sortingList, setSortingList] = useState([]);

    const [categoryProducts, setCategoryProducts] = useState([]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    async function getProductsHandler(keyword, brandId, categoryId) {
        dispatch(showLoader());
        try {
            const { data } = await getCategoryProducts({
                sortingId: selectedSort?.id,
                ...filterResult,
                keyword: keyword || filterResult.keyword,
                catalogID: categoryId || filterResult.catalogID,
                brandId: brandId || filterResult.brandId,
                paging: { PageNumber: page, PageSize: 20, TotalItems: 0 },
            });
            setCategoryProducts(data?.data?.list);
            setPaging(data?.data?.paging);
        } catch (error) {
            console.log(error);
        }

        try {
            const { data } = await getSortingList();
            setSortingList(data?.data);
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getProductsHandler(params.get('keyword'), params.get('brand'), params.get('category'));
    }, [
        first,
        page,
        rows,
        selectedSort?.id,
        rerender,
        params.get('keyword'),
        params.get('brand'),
        params.get('category'),
        params.get('ParentCategory'),
    ]);

    return (
        <>
            <section className="category-section container_bx">
                <Breadcrumb itemName={params.get('keyword') || params.get('name')} />

                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <Filter
                            filterResult={filterResult}
                            setFilterResult={setFilterResult}
                            forceRerender={forceRerender}
                            brandId={params.get('brand')}
                            categoryId={params.get('ParentCategory') || params.get('category')}
                            subCategoryId={
                                params.get('ParentCategory') ? params.get('category') : null
                            }
                        />
                    </div>

                    <div className="col-12 md:col-9 lg:col-9">
                        <ProductsList
                            categoryProducts={categoryProducts}
                            page={page}
                            paging={paging}
                            first={first}
                            rows={rows}
                            sortingList={sortingList}
                            selectedSort={selectedSort}
                            setSelectedSort={setSelectedSort}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProductsSearch;
