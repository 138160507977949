/* eslint-disable require-jsdoc */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getAssociateProductsAPI,
    getProductDetailsAPI,
    getProductReviewsAPI,
} from '../../api/productAPIs';
import '../../assets/css/pages/product-details.css';
import defaultImg from '../../assets/images/default-img.svg';
import emptyImg from '../../assets/images/empty_default.svg';
import AssociateItem from '../../components/AssociateItem/AssociateItem';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import DetailsBx from './DetailsBx';
import LikeSection from './LikeSection';
import Overview from './Overview';
import Reviews from './Reviews';

const ProductAssociate = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id, assoicateId } = useParams();
    const [productDetails, setProductDetails] = useState({});
    const [associateProducts, setAssociateProducts] = useState({});
    const [productReviews, setProductReviews] = useState({});
    const [associatePage, setAssociatePage] = useState(0);
    const [reviewPage, setReviewPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reviewsLoading, setReviewsLoading] = useState(false);
    const local = localStorage.getItem('language');

    const handleImageError = (e) => {
        e.target.src = defaultImg;
    };

    async function getProductDetails(productID) {
        dispatch(showLoader());
        try {
            const { data } = await getProductDetailsAPI(productID);
            setProductDetails(data?.data);
            if (productID !== assoicateId) getAssociateProducts(productID, associatePage);
            window.scroll({ top: 0, behavior: 'smooth' });
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getAssociateProducts(id, page = 0) {
        const reqData = {
            productId: id,
            paging: {
                PageNumber: page,
                PageSize: 4,
                TotalItems: 0,
            },
        };

        setLoading(true);
        try {
            const { data } = await getAssociateProductsAPI(reqData);
            setAssociateProducts(data?.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    async function getProductReviews(id, page = 0) {
        setReviewsLoading(true);
        try {
            const { data } = await getProductReviewsAPI(id, {
                paging: { PageNumber: page, PageSize: 5, TotalItems: 0 },
            });
            setProductReviews(data?.data);
        } catch (error) {
            console.log(error);
        }
        setReviewsLoading(false);
    }

    useEffect(() => {
        getProductReviews(id);
        if (id && !assoicateId) {
            getProductDetails(id);
        } else if (id && assoicateId) {
            getProductDetails(assoicateId);
            // getProductReviews(assoicateId);
        }

        return () => {
            setProductDetails({});
            setProductReviews({});
        };
    }, [local, id, assoicateId]);

    useEffect(() => {
        getAssociateProducts(id, associatePage);
    }, [associatePage]);

    useEffect(() => {
        getProductReviews(id, reviewPage);
    }, [reviewPage]);

    if (!_.keys(productDetails).length) {
        return <></>;
    }

    return (
        <>
            <section className="details-section container_bx">
                {productDetails ? (
                    <>
                        <Breadcrumb itemName={productDetails?.name} />
                        <div className="grid">
                            <div className="col-12 md:col-7 lg:col-7">
                                <div className="gallery-bx">
                                    <ImageGallery
                                        onImageError={handleImageError}
                                        onThumbnailError={handleImageError}
                                        items={productDetails?.media?.map((item) => ({
                                            original: item.url,
                                            thumbnail: item.url,
                                        }))}
                                        thumbnailPosition="left"
                                        showPlayButton={false}
                                        defaultImage={defaultImg}
                                    />
                                </div>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    associateProducts?.list && (
                                        <>
                                            <div className="associate-products">
                                                <div className="grid py-3">
                                                    {associateProducts?.list?.map((card, index) => (
                                                        <div
                                                            key={card.id}
                                                            className="col-6 md:col-4 xl:col-3">
                                                            <AssociateItem
                                                                id={card?.id}
                                                                productId={id}
                                                                price={card?.prices?.price}
                                                                priceOld={card?.prices?.oldPrice}
                                                                img={card.media?.[0]?.url}
                                                                name={card.name}
                                                                isWishlist={card.isWishlist}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            {(associateProducts?.paging?.TotalItems > 4 && (
                                                <Pagination
                                                    totalItems={
                                                        associateProducts?.paging?.TotalItems
                                                    }
                                                    itemsPerPage={4}
                                                    currentPage={associatePage}
                                                    onPageChange={(page) => {
                                                        setAssociatePage(page);
                                                    }}
                                                />
                                            )) ||
                                                ''}
                                        </>
                                    )
                                )}
                                <Overview productDetails={productDetails} />
                                <Reviews
                                    productReviews={productReviews}
                                    page={reviewPage}
                                    setPage={setReviewPage}
                                    loading={reviewsLoading}
                                />
                            </div>
                            <div className="col-12 md:col-5 lg:col-5">
                                <DetailsBx productDetails={productDetails} />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="empty-bx">
                        <img src={emptyImg} alt="pic" />
                        <p>{t('No_Product_details')}</p>
                    </div>
                )}
            </section>

            <LikeSection />
        </>
    );
};

export default React.memo(ProductAssociate);
