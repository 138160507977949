import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';

export default function RadioInputs({
    InputId = '',
    Name = '',
    LabelTitle = '',
    value = '',
    setValue = () => {},
    categoryOnChangeHandler = () => {},
}) {
    const { i18n } = useTranslation();
    return (
        <div className="flex align-items-center">
            <RadioButton
                inputId={InputId}
                name={Name}
                value={InputId}
                onChange={categoryOnChangeHandler}
                checked={value === InputId}
            />
            <label
                title={LabelTitle}
                htmlFor={InputId}
                className={i18n.language === 'ar' ? 'mr-2' : 'ml-2'}>
                {LabelTitle}
            </label>
        </div>
    );
}
