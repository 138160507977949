import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CartsItems from './CartsItems';

const ProductsList = ({
    categoryProducts = [],
    paging = {},
    page = 0,
    first = 0,
    rows = 20,
    sortingList = [],
    selectedSort = null,
    setSelectedSort = () => {},
    onPageChange = () => {},
}) => {
    const { t } = useTranslation();
    return (
        <div className="card-bx product-list-bx">
            <div className="filter-card-title">
                <p>
                    {t(`products_found`)} ({paging?.TotalItems || 0})
                </p>
                <div className="select-filter-item">
                    <Dropdown
                        value={selectedSort}
                        onChange={(e) => setSelectedSort(e.value)}
                        options={sortingList}
                        optionLabel="name"
                        placeholder="sort by"
                        className="w-full"
                    />
                </div>
            </div>

            <CartsItems
                paging={paging}
                categoryProducts={categoryProducts}
                rows={rows}
                first={first}
                onPageChange={onPageChange}
            />
        </div>
    );
};

export default ProductsList;
