/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductDetailsAPI, getProductReviewsAPI } from '../../api/productAPIs';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import _ from 'lodash';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Overview from './Overview';
import Reviews from './Reviews';
import ImageGallery from 'react-image-gallery';
import defaultImg from '../../assets/images/default-img.svg';
import emptyImg from '../../assets/images/empty_default.svg';
import LikeSection from './LikeSection';
import DetailsBx from './DetailsBx';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../../assets/css/pages/product-details.css';
import useForceRerender from '../../hooks/useForceRerender';

const ProductDetails = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [rerender, forceRerender] = useForceRerender();

    const [productDetails, setProductDetails] = useState({});
    const [productReviews, setProductReviews] = useState({});
    const [reviewPage, setReviewPage] = useState(0);
    const [reviewsLoading, setReviewsLoading] = useState(false);
    const local = localStorage.getItem('language');

    async function getProductDetails(id) {
        dispatch(showLoader());
        try {
            const { data } = await getProductDetailsAPI(id);
            if (data?.data?.type == 10) {
                if (data?.data?.associatedId) {
                    navigate(`/product-details/${id}/assoicate/${data?.data?.associatedId}`);
                } else {
                    navigate(`/product-details/${id}/assoicate/`);
                }
            }
            setProductDetails((prev) => ({ ...prev, ...data?.data }));
            window.scroll({ top: 0, behavior: 'smooth' });
            dispatch(hideLoader());
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getProductReviews(id, page = 0) {
        setReviewsLoading(true);
        try {
            const { data } = await getProductReviewsAPI(id, {
                paging: { PageNumber: page, PageSize: 5, TotalItems: 0 },
            });
            setProductReviews(data?.data);
        } catch (error) {
            console.log(error);
        }
        setReviewsLoading(false);
    }

    useEffect(() => {
        getProductDetails(id);
        getProductReviews(id);

        return () => {
            setProductDetails({});
            setProductReviews({});
        };
    }, [local, id, rerender]);

    useEffect(() => {
        getProductReviews(id, reviewPage);
    }, [reviewPage]);

    const handleImageError = (e) => {
        e.target.src = defaultImg;
    };

    if (!_.keys(productDetails).length) {
        return <></>;
    }

    return (
        <>
            <section className="details-section container_bx">
                {productDetails ? (
                    <>
                        <Breadcrumb itemName={productDetails?.name} />
                        <div className="grid">
                            <div className="col-12 md:col-7 lg:col-7">
                                <div className="gallery-bx">
                                    <ImageGallery
                                        onImageError={handleImageError}
                                        onThumbnailError={handleImageError}
                                        items={productDetails?.media?.map((item) => ({
                                            original: item.url,
                                            thumbnail: item.url,
                                        }))}
                                        thumbnailPosition="bottom"
                                        showPlayButton={false}
                                        defaultImage={defaultImg}
                                    />
                                </div>
                                <Overview productDetails={productDetails} />
                                <Reviews
                                    productReviews={productReviews}
                                    page={reviewPage}
                                    setPage={setReviewPage}
                                    loading={reviewsLoading}
                                    forceRerender={forceRerender}
                                />
                            </div>
                            <div className="col-12 md:col-5 lg:col-5">
                                <DetailsBx productDetails={productDetails} />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="empty-bx">
                        <img src={emptyImg} alt="pic" />
                        <p>{t('No_Product_details')}</p>
                    </div>
                )}
            </section>

            <LikeSection />
        </>
    );
};

export default React.memo(ProductDetails);
