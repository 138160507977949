import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Link } from 'react-router-dom';
import loginImg from '../../assets/images/sign_in.svg';
import { useTranslation } from 'react-i18next';

export default function MiniLoginModal({ visible, setVisible = () => {} }) {
    const { t } = useTranslation();
    return (
        <>
            <Dialog
                closable={true}
                contentClassName="cart-popup-bx"
                showHeader={false}
                visible={visible}
                style={{
                    width: '40vw',
                    height: 'auto',
                    maxHeight: '70vh',
                    borderRadius: '30px',
                    overflow: 'hidden',
                }}
                contentStyle={{ padding: '60px 50px', borderRadius: '30px' }}
                onHide={() => setVisible(false)}
                resizable={false}
                draggable={false}>
                <div className="popup-content">
                    <img src={loginImg} alt="pic" />
                    <p className="m-0">{t(`you_should_login_to_add_to_cart`)}</p>
                </div>

                <div className="popup-action-btn-bx">
                    <Button
                        label={`${t(`no_thanks`)}`}
                        onClick={() => setVisible(false)}
                        className="none-popup-btn"
                    />

                    <Link
                        to="/login"
                        onClick={() => {
                            window.scroll({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }}>
                        <Button label={`${t(`sign_in`)}`} className="login-popup-btn" />
                    </Link>
                </div>
            </Dialog>
        </>
    );
}
