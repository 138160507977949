import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { TfiAlignRight } from 'react-icons/tfi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import '../../assets/css/shared/menu.css';
import compare from '../../assets/images/compareOff.svg';
import fav from '../../assets/images/favOff.svg';
import shop from '../../assets/images/cart-icon.png';
import img1 from '../../assets/images/slide-4.jpg';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { getCategoriesAPI } from '../../api/categoriseAPIs';

const TopMenu = () => {
    const dispatch = useDispatch();

    const [stickyClass, setStickyClass] = useState('');
    const [categoryList, setCategoryList] = useState();

    const totalCount = useSelector((state) => state.addToCartReducer.totalCount);
    const totalCartPrice = useSelector((state) => state.addToCartReducer.totalCartPrice);

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            const windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 70 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
    };
    // translation
    const { t, i18n } = useTranslation();
    document.documentElement.lang = i18n.language;

    const navLinks = [
        { path: '/', text: 'home' },
        { path: '/category/electronicDevices/1147', text: 'electronicDevices' },
        { path: '/category/beauty/1164', text: 'beauty' },
        { path: '/category/furniture/1185', text: 'furniture' },
        { path: '/category/perfume/1174', text: 'perfume' },
    ];

    // async function getHomeCategoryHandler() {
    //     dispatch(showLoader());
    //     try {
    //         const { data } = await getCategoriesAPI({
    //             paging: { TotalItems: 0, PageNumber: 0, PageSize: 20 },
    //             data: { isHome: true, withAll: false, subCategorySize: 10 },
    //         });
    //         const firstFourCategories = data?.data?.list?.slice(0, 4);
    //         console.log(firstFourCategories);
    //         const updatedData = firstFourCategories.map((item) => {
    //             return {
    //                 text: item?.name,
    //                 path: `/category/${item?.name}/${item?.id}`,
    //             };
    //         });
    //         setCategoryList([{ path: '/', text: 'home' }, ...updatedData]);
    //     } catch (error) {
    //         console.log(error);
    //     } finally {
    //         dispatch(hideLoader());
    //     }
    // }

    // useEffect(() => {
    //     getHomeCategoryHandler();
    // }, []);

    // const [loading]=GetAllUserCartHook()
    return (
        <div className="container_bx">
            <nav className={`main-menu ${stickyClass}`}>
                <div className={`wrapper ${stickyClass ? 'container_bx' : ''}`}>
                    <input type="radio" name="slider" id="menu-btn" />
                    <input type="radio" name="slider" id="close-btn" />

                    <ul className="nav-links">
                        <label htmlFor="close-btn" className="btn close-btn">
                            <AiOutlineClose />
                        </label>
                        {navLinks.map((link, index) => (
                            <li
                                key={index}
                                className={window.location.pathname === link.path ? 'active' : ''}>
                                <NavLink
                                    to={link.path}
                                    exact // Use exact to ensure the active class is only applied for exact matches
                                >
                                    {t(link.text)}
                                </NavLink>
                            </li>
                        ))}
                        <li className="hidden">
                            <Link to="" className="desktop-item">
                                {t('decor_home')}
                            </Link>
                            <input type="checkbox" id="showMega1" />
                            <label htmlFor="showMega1" className="mobile-item">
                                {t('decor_home')}
                            </label>
                            <div className="mega-box hidden">
                                <div className="content">
                                    <div className="row">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="row">
                                        <header>{t('decor_home')}</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Graphics</Link>
                                            </li>
                                            <li>
                                                <Link to="">Vectors</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business cards</Link>
                                            </li>
                                            <li>
                                                <Link to="">Custom logo</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Email Services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Personal Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Mobile Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Web Marketing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Security services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Site Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">VPS Hosting</Link>
                                            </li>
                                            <li>
                                                <Link to="">Privacy Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">Website design</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="hidden">
                            <Link to="" className="desktop-item">
                                {t('woman')}
                            </Link>
                            <input type="checkbox" id="showMega2" />
                            <label htmlFor="showMega2" className="mobile-item">
                                woman
                            </label>
                            <div className="mega-box hidden">
                                <div className="content">
                                    <div className="row">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="row">
                                        <header>woman</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Graphics</Link>
                                            </li>
                                            <li>
                                                <Link to="">Vectors</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business cards</Link>
                                            </li>
                                            <li>
                                                <Link to="">Custom logo</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Email Services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Personal Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Mobile Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Web Marketing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Security services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Site Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">VPS Hosting</Link>
                                            </li>
                                            <li>
                                                <Link to="">Privacy Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">Website design</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="hidden">
                            <Link to="" className="desktop-item">
                                {t('men')}
                            </Link>
                            <input type="checkbox" id="showMega3" />
                            <label htmlFor="showMega3" className="mobile-item">
                                men
                            </label>
                            <div className="mega-box hidden">
                                <div className="content">
                                    <div className="row">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="row">
                                        <header>men</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Graphics</Link>
                                            </li>
                                            <li>
                                                <Link to="">Vectors</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business cards</Link>
                                            </li>
                                            <li>
                                                <Link to="">Custom logo</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Email Services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Personal Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Mobile Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Web Marketing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Security services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Site Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">VPS Hosting</Link>
                                            </li>
                                            <li>
                                                <Link to="">Privacy Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">Website design</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="hidden">
                            <Link to="" className="desktop-item">
                                {t('beauty')}
                            </Link>
                            <input type="checkbox" id="showMega4" />
                            <label htmlFor="showMega4" className="mobile-item">
                                beauty
                            </label>
                            <div className="mega-box hidden">
                                <div className="content">
                                    <div className="row">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="row">
                                        <header>beauty</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Graphics</Link>
                                            </li>
                                            <li>
                                                <Link to="">Vectors</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business cards</Link>
                                            </li>
                                            <li>
                                                <Link to="">Custom logo</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Email Services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Personal Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Mobile Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Web Marketing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Security services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Site Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">VPS Hosting</Link>
                                            </li>
                                            <li>
                                                <Link to="">Privacy Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">Website design</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="hidden">
                            <Link to="" className="desktop-item">
                                {t('kids')}
                            </Link>
                            <input type="checkbox" id="showMega5" />
                            <label htmlFor="showMega5" className="mobile-item">
                                kids
                            </label>
                            <div className="mega-box hidden">
                                <div className="content">
                                    <div className="row">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="row">
                                        <header>kids</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Graphics</Link>
                                            </li>
                                            <li>
                                                <Link to="">Vectors</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business cards</Link>
                                            </li>
                                            <li>
                                                <Link to="">Custom logo</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Email Services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Personal Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Business Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Mobile Email</Link>
                                            </li>
                                            <li>
                                                <Link to="">Web Marketing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Security services</header>
                                        <ul className="mega-links">
                                            <li>
                                                <Link to="">Site Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">VPS Hosting</Link>
                                            </li>
                                            <li>
                                                <Link to="">Privacy Seal</Link>
                                            </li>
                                            <li>
                                                <Link to="">Website design</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {/* <li><Link to=''>Feedback</Link></li> */}
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn">
                        <TfiAlignRight />
                    </label>

                    <div className="labels-bx">
                        <div className="item">
                            <Link to={'/compare'}>
                                <img src={compare} alt="pic" />
                                {/* <span>2</span> */}
                            </Link>
                        </div>
                        <div className="item">
                            <Link to={'/wishlist'}>
                                <img src={fav} alt="pic" />
                                {/* <span>2</span> */}
                            </Link>
                        </div>
                        <div className="item">
                            <Link to={'/cart/couponId='} className="cart-btn">
                                <div className="position-relative">
                                    <img src={shop} alt="pic" />
                                    <span className="num">
                                        {typeof totalCount === 'number' ||
                                        typeof totalCount === 'string'
                                            ? totalCount >= 100
                                                ? '99'
                                                : parseFloat(totalCount || 0)?.toFixed(0)
                                            : null}
                                    </span>
                                </div>
                                <div>
                                    {typeof totalCartPrice === 'number' ||
                                    typeof totalCartPrice === 'string'
                                        ? parseFloat(totalCartPrice || 0)?.toFixed(2)
                                        : null}{' '}
                                    <span className="currency">{t('sar')}</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default TopMenu;
