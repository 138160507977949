import React, { useContext, useState, useRef, useEffect } from 'react';
import { Steps } from 'primereact/steps';
import { useForm, Controller } from 'react-hook-form';
import { FormStepContext } from '../../utils/contexts/FormStepContext';
import { STEPS } from '../../utils/constants';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import defaultFile from '../../assets/images/vendor/wizard/defaultFile.svg';
import buisness from '../../assets/images/vendor/wizard/business.svg';
import document from '../../assets/images/vendor/wizard/documents.svg';
import info from '../../assets/images/vendor/wizard/broduct-info.svg';
import upload from '../../assets/images/vendor/wizard/upload.svg';
import arrow from '../../assets/images/btn-arrow.svg';

const StepTwo = () => {
    const { t } = useTranslation();
    const [activeIndex] = useState(1);
    const [errors, setErrors] = useState({
        imageFirst: "",
        imageSecond: "",
    });
    const items = [
        {
            label: (
                <div className='step-title'><img src={buisness} alt='pic' />{t("business_details")}</div>
            )
        },
        {
            label: (
                <div className='step-title'><img src={document} alt='pic' />{t("documents")}</div>
            )
        },
        {
            label: (
                <div className='step-title'><img src={info} alt='pic' /> {t("products_info")}</div>
            )
        }
    ];

    const {
        control,
        handleSubmit,
        reset,
        watch
    } = useForm({ mode: 'onChange' });

    const { setStep, updateFormState, formState } = useContext(FormStepContext)

    const [totalSize, setTotalSize] = useState(0);
    const firstImage = useRef(null);
    const secondImage = useRef(null);

    const TradeLicenseFile = firstImage?.current?.getFiles()?.[0];
    const NationalIDFile = secondImage?.current?.getFiles()?.[0];

    const onSubmit = () => {
        if (totalSize === 0) {
            setErrors({ ...errors, imageFirst: t("upload_pdf_images_only"), imageSecond: t("upload_pdf_images_only") });
            return;
        }

        if (errors?.imageFirst || errors?.imageSecond) {
            return;
        }

        if (!formState?.NationalIDFile) {
            setErrors({ ...errors, imageSecond: t("upload_pdf_images_only") });
            return;
        }

        if (!formState?.TradeLicenseFile) {
            setErrors({ ...errors, imageFirst: t("upload_pdf_images_only") });
            return;
        }

        if (formState?.TradeLicenseFile && (formState?.TradeLicenseFile?.type?.includes("pdf") === false && formState?.TradeLicenseFile?.type?.includes("image") === false)) {
            setErrors({ ...errors, imageFirst: t("upload_pdf_images_only") });
            return;
        }

        if (formState?.NationalIDFile && (formState?.NationalIDFile?.type?.includes("pdf") === false && formState?.NationalIDFile?.type?.includes("image") === false)) {
            setErrors({ ...errors, imageSecond: t("upload_pdf_images_only") });
            return;
        }

        setErrors({});
        updateFormState({
            TradeLicenseFile: formState?.TradeLicenseFile || null,
            NationalIDFile: formState?.NationalIDFile || null,
        });
        setStep(STEPS.INFO);
    };

    const goBack = () => {
        updateFormState({ TradeLicenseFile: formState?.TradeLicenseFile || null, NationalIDFile: formState?.NationalIDFile || null, });

        setStep(STEPS.BUSIENSS);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        if (formState?.TradeLicenseFile || formState?.NationalIDFile) {

            reset({ TradeLicenseFile: formState?.TradeLicenseFile || null });
            reset({ NationalIDFile: formState?.NationalIDFile || null });

            firstImage?.current?.setFiles([formState?.TradeLicenseFile || null]);
            secondImage?.current?.setFiles([formState?.NationalIDFile || null]);

            setTotalSize(formState?.TradeLicenseFile?.size || 0 + formState?.NationalIDFile?.size || 0);

            // check if file is pdf or image
            if (formState?.TradeLicenseFile?.type?.includes("pdf") === false && formState?.TradeLicenseFile?.type?.includes("image") === false) {
                setErrors({ ...errors, imageFirst: t("upload_pdf_images_only") });
            }

            if (formState?.NationalIDFile?.type?.includes("pdf") === false && formState?.NationalIDFile?.type?.includes("image") === false) {
                setErrors({ ...errors, imageSecond: t("upload_pdf_images_only") });
            }
        }
    }, [activeIndex === 1]);

    const onTemplateSelectLicense = (e) => {
        if (errors?.imageFirst) setErrors({ ...errors, imageFirst: "" });

        const fileSelected = e?.files?.[0];

        const allowedFilesFirst = (fileSelected?.type?.includes("image") || fileSelected?.type?.includes("pdf"))

        if (allowedFilesFirst === false) { setErrors({ ...errors, imageFirst: t("upload_pdf_images_only") }); }

        updateFormState({ TradeLicenseFile: fileSelected });

        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => { _totalSize += files[key].size || 0; });

        setTotalSize(_totalSize);
    };

    const onTemplateSelectNationalID = (e) => {
        if (errors?.imageSecond) setErrors({ ...errors, imageSecond: "" });

        const fileSelected = e?.files?.[0];

        const allowedFilesSecond = (fileSelected?.type?.includes("image") || fileSelected?.type?.includes("pdf"))

        if (allowedFilesSecond === false) { setErrors({ ...errors, imageSecond: t("upload_pdf_images_only") }); }

        updateFormState({ NationalIDFile: fileSelected });

        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => { _totalSize += files[key].size || 0; });

        setTotalSize(_totalSize);
    };

    const onTemplateRemove = (file, callback, props) => {
        const firstImage = props?.props?.name === "firstImage";
        const secondImage = props?.props?.name === "secondImage";

        if (firstImage) {
            reset({ TradeLicenseFile: null });
            updateFormState({ TradeLicenseFile: null });
            setErrors({ ...errors, imageFirst: "" });
        }

        if (secondImage) {
            reset({ NationalIDFile: null });
            updateFormState({ NationalIDFile: null });
            setErrors({ ...errors, imageSecond: "" });
        }

        setTotalSize(totalSize || 0 - file?.size || 0);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        updateFormState({ TradeLicenseFile: null, NationalIDFile: null });
        reset({ TradeLicenseFile: null, NationalIDFile: null });
        setErrors({ imageFirst: "", imageSecond: "" });
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center img-data">
                    {/* if file is not image then set a default image */}
                    {file?.type?.includes("image") === false && <img alt={file?.name} role="presentation" src={defaultFile} width={100} />}
                    {file?.type?.includes("image") && <img alt={file?.name} role="presentation" src={file?.objectURL} width={100} />}
                    <span className="flex flex-column text-left ml-3">
                        {file?.name}
                        <small>{new Date().toLocaleDateString()}</small>
                        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove, props)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column upload-click">
                <div className='upload-icon'><img src={upload} alt='pic' /></div>
                {/* <i className="pi pi-image mt-3 p-5" style={{ fontSize: '3em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i> */}
                <span className="my-2 upload-click-text">
                    <span className='colored'>
                        {t("upload")}
                    </span> {t("or_drag_drop")}
                </span>
            </div>
        );
    };

    const chooseOptions = { iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    useEffect(() => {
        updateFormState({ TradeLicenseFile: formState?.TradeLicenseFile || null });
    }, [TradeLicenseFile, firstImage])

    useEffect(() => {
        updateFormState({ NationalIDFile: formState?.NationalIDFile || null });
    }, [NationalIDFile, secondImage])

    return (
        <>
            <div className='steps-items'>
                <Steps model={items} activeIndex={activeIndex} readOnly={false} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className='card-bx mt_40'>
                    <div className='upload-bx'>
                        <ul>
                            <li>
                                <div className='upload-title'>
                                    <h3>
                                        {t("trade_license")}
                                    </h3>
                                    <p>
                                        {t("trade_license_validation")}
                                    </p>
                                </div>
                                <div className='step-upload'>
                                    <FileUpload
                                        ref={firstImage}
                                        name="firstImage"
                                        url="/api/upload"
                                        accept="image/*, .pdf"
                                        maxFileSize={2098000}
                                        onSelect={onTemplateSelectLicense}
                                        onError={onTemplateClear}
                                        onClear={onTemplateClear}
                                        itemTemplate={itemTemplate}
                                        emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        invalidFileSizeMessageDetail={t("maximum_upload_size")}
                                    />
                                    {errors?.imageFirst && <div className='error-msg'><p> {errors?.imageFirst} </p></div>}
                                </div>
                            </li>

                            <li>
                                <div className='upload-title'>
                                    <h3>
                                        {t("national_id")}
                                    </h3>
                                    <p>
                                        {t("national_id_validation")}
                                    </p>
                                </div>
                                <div className='step-upload'>
                                    <FileUpload
                                        ref={secondImage}
                                        name="secondImage"
                                        url="/api/upload"
                                        accept="image/*, .pdf"
                                        maxFileSize={2098000}
                                        onSelect={onTemplateSelectNationalID}
                                        onError={onTemplateClear}
                                        onClear={onTemplateClear}
                                        itemTemplate={itemTemplate}
                                        emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        invalidFileSizeMessageDetail={t("maximum_upload_size")}
                                    />
                                    {errors?.imageSecond && <div className='error-msg'><p> {errors?.imageSecond} </p></div>}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='btns-content'>
                    <button className='back' onClick={goBack}><img src={arrow} alt='pic' />
                        {t("previous")}
                    </button>
                    <button
                        className='next'
                        onClick={() => { window.scroll({ top: 0, behavior: "smooth", }); }}
                    >
                        {t("next")}
                        <img src={arrow} alt='pic' />
                    </button>
                </div>
            </form>
        </>
    );
}

export default StepTwo;
