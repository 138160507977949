import React, { useState, useEffect, useRef } from 'react';
import '../../assets/css/shared/dropdown.css';

const DropDown = ({ content, title }) => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef();

    useEffect(() => {
        const handler = (e) => {
            setOpen(false);
        };

        document.addEventListener('mousedown', handler);
    });

    return (
        <div className="dropdown-bx">
            <div className="menu-container" ref={menuRef}>
                <div
                    className="menu-trigger"
                    onClick={() => {
                        setOpen(!open);
                    }}>
                    {title}
                </div>

                <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`}>{content}</div>
            </div>
        </div>
    );
};

export default DropDown;
