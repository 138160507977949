import React, { useEffect } from 'react';
import './OrderDetails.css';
import { useTranslation } from 'react-i18next';
import OrderStatus from './OrderStatus';

export default function OrderSummary({ orderDetails = {} }) {
    const { t } = useTranslation();

    return (
        <div className="order-summary__wrapper">
            <h2 className="order-summary__number">#{orderDetails?.customOrderNumber}</h2>
            <div className="order-summary__date">
                <h2>{t(`orderDate`)}</h2>
                <p>{orderDetails?.createdOn?.split('T')?.[0]?.split('-').reverse().join('/')}</p>
            </div>
            <div>
                <h2>{t(`orderStatus`)}</h2>
                <div>
                    <OrderStatus orderStatus={orderDetails?.orderStatus} />
                </div>
            </div>
            <div className="order-summary__price">
                <h2>{t(`total`)}</h2>
                <p>
                    {parseFloat(orderDetails?.priceDetails?.total).toFixed(2) ?? 0}
                    <span className="mx-1">
                        {orderDetails?.currency?.code
                            ? t(`${orderDetails.currency.code}`)
                            : t(`SAR`)}
                    </span>
                </p>
            </div>
        </div>
    );
}
