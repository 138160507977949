import axios from 'axios';
import { baseInstance } from './baseInstance';
import { store } from '../redux/store';
import Cookies from 'js-cookie';

export const registerAPI = (data) => baseInstance.post('/Authenticate/CustomRegister', data);

export const otpAPI = (data) => baseInstance.post('/Authenticate/ValidateOTP', data);

export const loginAPI = (data) =>
    axios.post(`${process.env.REACT_APP_BASE_URL}Authenticate/Login`, data, {
        headers: {
            'Content-Type': 'application/json',
            platform: '4',
            language: Cookies.get('i18next') === 'ar' ? 2 : 1,
            apiKey: store?.getState()?.authReducer?.apiKey,
        },
    });

export const deleteAPI = (userId) => baseInstance.delete(`/Authenticate/DeleteAccount/${userId}`);

export const loginSocialMediaAPI = (data) =>
    baseInstance.post('/Authenticate/CustomSocialRegister', data);

export const resetPasswordAPI = (data) =>
    baseInstance.post('/Authenticate/CustomeResetPassword', data);

export const changePasswordAPI = (data) =>
    baseInstance.post('/Authenticate/CustomChangePassword', data);

export const forgetPasswordAPI = (data) =>
    baseInstance.post('/Authenticate/CustomeForgetPassword', data);

export const resendOtpAPI = (data) =>
    baseInstance.post(`/Authenticate/ResendOtp?Key=${data.key}&AuthType=${data.authType}`);
