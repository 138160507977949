import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDataSvg } from "../../assets/images/empty_default.svg";
import Empty from "../../assets/empty.json";
import Lottie from "lottie-react";
import "./NoData.css";

const NoData = ({ text = "", productShape = false }) => {
    const { t } = useTranslation();
    return (
        <div className="no-data">
            {productShape ?
                <NoDataSvg style={{ height: "250px", width: "100%", marginTop: "50px", marginBottom: "50px" }} />
                :
                <Lottie
                    style={{ height: "70vh", width: "100%" }}
                    animationData={Empty}
                    loop={false}
                    autoplay={true}
                />
            }

            <h1 className="text-center mb-5">
                {text ? text : t('no_data')}
            </h1>
        </div>
    );
};

export default NoData;
