import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../LoadingButton/LoadingButton';

export default function PaymentCardsDelete({
    showModal,
    setShowModal = () => {},
    deleteCardHandler = () => {},
}) {
    const { t } = useTranslation();

    const ref = useRef(null);

    function handleReset() {
        setShowModal(false);
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                handleReset();
            }
        });
        return () => {
            document.removeEventListener('click', () => handleReset());
        };
    }, []);
    return (
        <>
            <Dialog
                header={null}
                visible={showModal}
                style={{ width: '50%', maxWidth: '490px' }}
                className="add-card-modal delete-card-modal"
                draggable={false}
                resizable={false}
                onHide={handleReset}>
                <div ref={ref}>
                    <h2>{t(`delete-card`)}</h2>
                    <p className="my-2">{t(`delete-card-text`)}</p>
                    <div className="flex justify-content-end gap-2 mt-4">
                        <button className="text-btn" onClick={handleReset}>
                            {t(`cancel`)}
                        </button>
                        <LoadingButton
                            btnClick={deleteCardHandler}
                            btnText={t(`delete`)}
                            btnClassName="delete-card-btn"
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
}
