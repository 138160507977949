import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductsList from './ProductsList';
import Filter from './Filter';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { getCategoryProducts } from '../../api/categoriseAPIs';
import { getSortingList } from '../../api/productAPIs';
import useForceRerender from '../../hooks/useForceRerender';

export default function TagProducts() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [rerender, forceRerender] = useForceRerender();

    const [filterResult, setFilterResult] = useState({});

    const [paging, setPaging] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(20);

    const [selectedSort, setSelectedSort] = useState(null);
    const [sortingList, setSortingList] = useState([]);

    const [categoryProducts, setCategoryProducts] = useState([]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    async function getProductsHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getCategoryProducts({
                tagId: id,
                sortingId: selectedSort?.id,
                ...filterResult,
                paging: { PageNumber: page, PageSize: 20, TotalItems: 0 },
            });
            setCategoryProducts(data?.data?.list);
            setPaging(data?.data?.paging);
        } catch (error) {
            console.log(error);
        }

        try {
            const { data } = await getSortingList();
            setSortingList(data?.data);
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getProductsHandler();
    }, [first, id, page, rows, selectedSort?.id, rerender]);

    return (
        <>
            <section className="category-section container_bx">
                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <Filter
                            filterResult={filterResult}
                            setFilterResult={setFilterResult}
                            forceRerender={forceRerender}
                        />
                    </div>

                    <div className="col-12 md:col-9 lg:col-9">
                        <ProductsList
                            categoryProducts={categoryProducts}
                            page={page}
                            paging={paging}
                            first={first}
                            rows={rows}
                            sortingList={sortingList}
                            selectedSort={selectedSort}
                            setSelectedSort={setSelectedSort}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
