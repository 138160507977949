import { ScrollTop } from 'primereact/scrolltop';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import MainHeader from '../components/MainHeader/MainHeader.jsx';
import NoData from '../components/NoData/NoData';
import Footer from '../components/footer/Footer.js';
import MainLoader from '../components/loaders/mainLoader.js';
import TopMenu from '../components/navbar/TopMenu.js';
import TopBar from '../components/topBar/TopBar.js';
import Brands from '../pages/Brands/Brands.jsx';
import Category from '../pages/Category/Category.jsx';
import Home from '../pages/Home/Home.jsx';
import ProductAssociate from '../pages/ProductDetails/ProductAssociate.jsx';
import ProductDetails from '../pages/ProductDetails/ProductDetails.jsx';
import ProductsSearch from '../pages/ProductsSearch/ProductsSearch.jsx';
import Addresses from '../pages/Profile/Addresses/Addresses.jsx';
import Cart from '../pages/Cart/Cart';
import Orders from '../pages/Orders/Orders';
import OrderDetails from '../pages/Orders/OrderDetails/OrderDetails';
import Checkout from '../pages/Checkout/Checkout';
import Compare from '../pages/Profile/Compare/Compare.jsx';
import MyOrders from '../pages/Profile/MyOrders.jsx';
import MyProfile from '../pages/Profile/MyProfile.jsx';
import Notification from '../pages/Profile/Notification/Notification.jsx';
import Profile from '../pages/Profile/Profile.jsx';
import Wishlist from '../pages/Profile/Wishlist.jsx';
import VendorApply from '../pages/VendorApply/VendorApply.jsx';
import VendorWizard from '../pages/VendorApply/VendorWizard.jsx';
import Payments from '../pages/Profile/Payments/Payments.jsx';
import PaymentResult from '../pages/Checkout/PaymentResult.jsx';
import VendorProducts from '../pages/Vendor/VendorProducts.jsx';
import TagProducts from '../pages/Category/TagProducts.jsx';
import TermsAndCondition from '../pages/UsefulLinks/TermsAndCondition.jsx';
import PrivacyPolicy from '../pages/UsefulLinks/PrivacyPolicy.jsx';
import ContactUs from '../pages/UsefulLinks/ContactUs.jsx';

const Layout = () => {
    const { userType } = useSelector((state) => state.authReducer);
    return (
        <>
            <MainLoader />
            <TopBar />
            <MainHeader />
            <TopMenu />

            <Routes>
                <Route path="/" element={<Home />} />,
                <Route path="/product-details/:id" element={<ProductDetails />} />,
                <Route path="/product-details/:id/assoicate/" element={<ProductAssociate />} />,
                <Route
                    path="/product-details/:id/assoicate/:assoicateId"
                    element={<ProductAssociate />}
                />
                ,
                <Route path="/category/:name/:id" element={<Category />} />,
                <Route path="/tag/:id" element={<TagProducts />} />
                {userType !== 1 && <Route path="/vendor-apply" element={<VendorApply />} />},
                {userType !== 1 && <Route path="/vendor-wizard" element={<VendorWizard />} />},
                <Route path="/vendor/vendor-products/:id" element={<VendorProducts />} />
                <Route path="/brands" element={<Brands />} />,
                <Route path="/brand/:name/:id" element={<Brands />} />,
                <Route path="/search/:keyword/" element={<ProductsSearch />} />,
                <Route path="/compare" element={<Compare />} />,
                <Route path="/wishlist" element={<Wishlist />} />,
                <Route path="/cart/:couponId" element={<Cart />} />,
                <Route path="/checkout/:addressId/:couponId" element={<Checkout />} />,
                <Route path="/checkout/payment-result" element={<PaymentResult />} />
                <Route path="/notification" element={<Notification />} />,
                <Route path="/profile" element={<Profile />}>
                    <Route path="info" element={<MyProfile />} />
                    <Route index element={<MyProfile />} />
                    {/* <Route path="orders" element={<MyOrders />} /> */}
                    <Route path="addresses" element={<Addresses />} />
                    <Route path="orders" element={<Orders />} />,
                    <Route path="payments" element={<Payments />} />,
                    <Route path="orders/:orderId" element={<OrderDetails />} />,
                </Route>
                <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<NoData />} />
            </Routes>

            <Footer />

            <ScrollTop className="scroll-top-icon" />
        </>
    );
};

export default Layout;
