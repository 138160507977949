import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { forgetPasswordAPI } from '../../api/authAPIs';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { getCountries } from '../../api/commonAPIs';
import { authTypes } from '../../utils/constants';
import Swal from 'sweetalert2';
import sweetAlert from '../../helpers/sweetAlert';
import countryOptionTemplate from '../../components/countryOptions/countryOptions';
import selectedCountryTemplate from '../../components/selectedCountry/selectedCountry';

const ForgetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [countriesList, setCountreisList] = useState([]);

    const {
        control,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        reset,
        resetField,
        watch,
    } = useForm({ mode: 'onChange' });

    const [watchedCountryCode] = watch(['countryCode']);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    async function getCountriesList() {
        dispatch(showLoader());

        try {
            const res = await getCountries(true);
            setCountreisList(res.data.data);
            reset({
                countryCode: {
                    id: res.data.data[0]?.id,
                    name: res.data.data[0]?.phoneCode,
                    code: res.data.data[0]?.twoLetterCode,
                    hintText: res.data.data[0]?.hintText,
                    phoneLength: res.data.data[0]?.phoneLength,
                },
            });
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    const onSubmit = (data) => {
        let finalData = {
            countryId: data?.countryCode?.id,
            phoneNumber: data?.phoneNumber?.toString(),
            authType: authTypes.phone,
        };

        dispatch(showLoader());
        forgetPasswordAPI(finalData)
            .then((res) => {
                if (res.status === 204) {
                    Swal.fire({
                        icon: 'error',
                        title: t('error'),
                        confirmButtonText: t('close'),
                    });
                    return;
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: t('success'),
                        text: t('otp_sent'),
                        confirmButtonText: t('close'),
                    });
                    navigate(`/forget-password-otp?Key=${res.data.data.key}`);
                }
            })
            .catch((error) => {
                const errorList = error.response?.data?.Message('server error') || ['server error'];
                errorList.forEach((err) => {
                    toast.current.show({
                        severity: 'error',
                        summary: t('error'),
                        detail: err,
                        life: 3000,
                    });
                });
            })
            .finally(() => {
                dispatch(hideLoader());
            });

        reset();
    };

    useEffect(() => {
        getCountriesList();
    }, []);

    return (
        <div className="auth-bx">
            <div className="title">
                <h3>{t('reset_password')}</h3>
                <div className="text reset-text">{t('reset_password_pref')}</div>
            </div>

            <div className="form-bx">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column">
                    <Toast ref={toast} />

                    <div className="phone-num field">
                        <label
                            htmlFor="countryCode"
                            className={classNames({ 'p-error': errors.countryCode })}>
                            <span className="red-asterisk">*</span> {t('phone_number')}
                        </label>
                        <div className="phone-fields">
                            <div className="field">
                                <Controller
                                    name="countryCode"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            value={field.value || ''}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                resetField('phoneNumber');
                                            }}
                                            options={countriesList.map((country) => ({
                                                id: country?.id,
                                                name: country?.phoneCode,
                                                code: country?.twoLetterCode,
                                                hintText: country?.hintText,
                                                phoneLength: country?.phoneLength,
                                            }))}
                                            placeholder={t('country_code')}
                                            optionLabel="code"
                                            filter
                                            valueTemplate={selectedCountryTemplate}
                                            itemTemplate={countryOptionTemplate}
                                        />
                                    )}
                                />
                                {getFormErrorMessage('phoneCode')}
                            </div>

                            <div className="field w-full">
                                <span className="phone_num">
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{
                                            required: t('this_field_is_required'),
                                            minLength: {
                                                value: watchedCountryCode?.phoneLength,
                                                message: t('phone_number_min_length', {
                                                    length: watchedCountryCode?.phoneLength || 0,
                                                }),
                                            },
                                            maxLength: {
                                                value: watchedCountryCode?.phoneLength,
                                                message: t('phone_number_max_length', {
                                                    length: watchedCountryCode?.phoneLength || 15,
                                                }),
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                {...field}
                                                id={field.name}
                                                value={field.value || ''}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                className={classNames({
                                                    'p-invalid': fieldState.error,
                                                })}
                                                placeholder={
                                                    watchedCountryCode?.hintText ||
                                                    t('phone_number_placeholder')
                                                }
                                                maxLength={watchedCountryCode?.phoneLength}
                                                keyfilter="pint"
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage('phoneNumber')}
                            </div>
                        </div>
                    </div>

                    <Button
                        className="btn"
                        label={t('submit')}
                        type="submit"
                        disabled={!isDirty || !isValid}
                    />
                </form>
            </div>
        </div>
    );
};

export default ForgetPassword;
