import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { FormStepContext } from '../../utils/contexts/FormStepContext';
import { STEPS } from '../../utils/constants';
import { getCurrentForm } from '../../utils/helpers';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { applyAsVendorAPI } from '../../api/VendorAPIs';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { updateUserTypeToVendor } from '../../redux/authSlice';
import '../../assets/css/pages/wizard-file.css';

const VendorWizard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [step, setStep] = useState(STEPS.BUSIENSS);
    const [formState, setFormState] = useState({});
    const [status, setStatus] = useState(0);

    const updateFormState = (data, status) => {
        setFormState({ ...formState, ...data });
        if (status == 3) {
            setStatus(3);
        }
    };

    useEffect(() => {
        if (status == 3) {
            submitForm();
        }
    }, [status]);

    const submitForm = () => {
        if (!formState.IsAcceptTerms) {
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('accept_terms_and_conditions'),
                confirmButtonText: t('close'),
            });
            return;
        }

        let data = {
            CountryID: formState.CountryID.id,
            CityID: formState.CityID.id,
            StateId: formState.StateId.id,
            CountryCode: formState.CountryCode.name,
            PhoneNumber: formState.PhoneNumber,
            BusinessTypeId: formState.BusinessTypeId,
            BusinessName: formState.BusinessName,
            BusinessNameAR: formState.BusinessNameAR,
            BusinessAddress: formState.BusinessAddress,
            CategoryId: formState.ProductCategories?.[0]?.categoryId,
            Description: formState.ProductCategories?.[0]?.descriptions,
            TradeLicenseFile: formState.TradeLicenseFile,
            NationalIDFile: formState.NationalIDFile,
            IsAcceptTerms: formState.IsAcceptTerms,
            ProductCategories: formState.ProductCategories,
        };

        const formData = new FormData();
        _.forOwn(data, (value, key) => {
            if (key == 'ProductCategories') {
                value.forEach((item, index) => {
                    formData.append(`ProductCategories[${index}].CategoryId`, item.categoryId);
                    formData.append(`ProductCategories[${index}].Descriptions`, item.descriptions);
                });
                return;
            }

            formData.append(key, value);
        });

        dispatch(showLoader());

        applyAsVendorAPI(formData)
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'success',
                    text: t('vendor_apply_success'),
                    confirmButtonText: t('close'),
                });

                dispatch(updateUserTypeToVendor());

                dispatch(hideLoader());

                setTimeout(() => {
                    navigate('/');
                }, 1000);
            })
            .catch((err) => {
                const errorList = err?.response?.data?.statusMessage || [
                        err?.response?.data?.message,
                    ] || ['server error'];
                Swal.fire({
                    icon: 'error',
                    title: errorList,
                    cancelButtonText: t('close'),
                });

                dispatch(hideLoader());
                setStatus(0);
                setStep(STEPS.BUSIENSS);
            });
    };

    return (
        <section className="wizard-section container_bx">
            <div className="wizard-bx">
                <FormStepContext.Provider
                    value={{ step, setStep, formState, updateFormState, submitForm }}>
                    {getCurrentForm(step)}
                </FormStepContext.Provider>
            </div>
        </section>
    );
};

export default VendorWizard;
