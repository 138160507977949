import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SendSmsOtp, addCustomerAddress, updateCustomerAddress } from '../../api/CustomerAPIs';
import AddressModal from '../../components/Modal/AddressModal';
import ChangeAddressModal from '../../components/Modal/ChangeAddressModal';
import VerifyPhoneModal from '../../components/Modal/VerifyPhoneModal';
import sweetAlert from '../../helpers/sweetAlert';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { getCountries } from '../../api/commonAPIs';

const AddressList = ({
    list = [],
    defaultAddress = {},
    forceRerender = () => {},
    rerender = '',
    setDefaultAddress = () => {},
    selectedAddressID,
    setSelectedAddressID = () => {},
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [radioValue, setRadioValue] = useState(defaultAddress?.id);
    const [toggleFirstModal, setToggleFirstModal] = useState(false);
    const [toggleSecondModal, setToggleSecondModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [toggleOtpModal, setToggleOtpModal] = useState(false);
    const [toggleChangeModal, setToggleChangeModal] = useState(false);
    const [otpInfo, setOtpInfo] = useState({ otp: '', key: '', countryId: '', addressId: '' });
    const [countriesList, setCountriesList] = useState([]);
    const [pin, setPin] = useState({ lat: 31.1999679, lng: 29.9186483 });

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        resetField,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            otp: '',
            isDefault: false,
        },
    });

    const watchedData = watch();

    function handleReset() {
        setToggleFirstModal(false);
        setToggleSecondModal(false);
        setEditMode(false);
        reset({
            firstName: '',
            lastName: '',
            addressDetails: '',
            addressLabel: '',
            phoneNumber: '',
            otp: '',
            countryCode: {
                id: countriesList?.[0]?.id,
                name: countriesList?.[0]?.phoneCode,
                code: countriesList?.[0]?.twoLetterCode,
                hintText: countriesList?.[0]?.hintText,
                phoneLength: countriesList?.[0]?.phoneLength,
            },
        });
    }

    function editHandler(address) {
        const countryCodeInfo = countriesList.find(
            (country) => country?.id === address?.country?.id,
        );
        setEditMode(true);
        setPin({ lat: address?.latitude, lng: address?.longitude });
        reset({
            id: address?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            addressDetails: address?.addressLine,
            addressLabel: address?.addressType?.id,
            phoneNumber: address?.phone,
            isDefault: address?.isDefault,
            countryCode: {
                id: countryCodeInfo?.id,
                name: countryCodeInfo?.phoneCode,
                code: countryCodeInfo?.twoLetterCode,
                hintText: countryCodeInfo?.hintText,
                phoneLength: countryCodeInfo?.phoneLength,
            },
        });
        setToggleFirstModal(false);
        setToggleSecondModal(true);
    }

    async function verifyPhoneHandler() {
        if (!defaultAddress?.id || defaultAddress?.phoneVerified) return;
        const formData = {
            addressId: defaultAddress?.id,
            isResend: false,
        };

        try {
            const { data } = await SendSmsOtp(formData);
            sweetAlert.success(t('phone_number_otp_send'));
            setToggleOtpModal(true);
            setOtpInfo({
                otp: '',
                key: data?.data?.key,
                countryId: defaultAddress?.country?.id,
                addressId: defaultAddress?.id,
            });
            resetField('otp');
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    async function getCountriesList() {
        try {
            const { data } = await getCountries(true);
            setCountriesList(data?.data);
            setValue('countryCode', {
                id: data?.data[0]?.id,
                name: data?.data[0]?.phoneCode,
                code: data?.data[0]?.twoLetterCode,
                hintText: data?.data[0]?.hintText,
                phoneLength: data?.data[0]?.phoneLength,
            });
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    async function addAddress() {
        const formData = {
            firstName: watchedData?.firstName || '',
            lastName: watchedData?.lastName || '',
            countryId: watchedData?.countryCode?.id || '',
            addressTypeId: watchedData?.addressLabel?.toString() || '',
            phoneNumber: watchedData?.phoneNumber || '',
            additionalNotes: watchedData?.addressDetails || '',
            addressLineAr: watchedData?.addressDetails || '',
            addressLineEn: watchedData?.addressDetails || '',
            latitude: pin.lat || '',
            longitude: pin.lng || '',
            isDefault: list?.list?.length === 0 ? true : false,
        };
        try {
            const { data } = await addCustomerAddress(formData);
            setSelectedAddressID(data?.data?.id);
            sweetAlert.success(t('added_successfully'));
            handleReset();
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    async function editAddress() {
        const formData = {
            id: watchedData?.id || '',
            firstName: watchedData?.firstName || '',
            lastName: watchedData?.lastName || '',
            countryId: watchedData?.countryCode?.id || '',
            addressTypeId: watchedData?.addressLabel?.toString() || '',
            phoneNumber: watchedData?.phoneNumber || '',
            additionalNotes: watchedData?.addressDetails || '',
            addressLineAr: watchedData?.addressDetails || '',
            addressLineEn: watchedData?.addressDetails || '',
            isDefault: watchedData?.isDefault || false,
            latitude: pin.lat || '',
            longitude: pin.lng || '',
        };

        try {
            await updateCustomerAddress(formData);
            sweetAlert.success(t('address_updated_successfully'));
            handleReset();
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
    }

    useEffect(() => {
        getCountriesList();
    }, []);

    return (
        <>
            <h2>{t('shipping_details')}</h2>
            <div className="address-card card-border">
                {(defaultAddress?.id && (
                    <>
                        <div className="px-3 py-4 flex gap-2">
                            <RadioButton
                                name="address"
                                id={defaultAddress.id}
                                value={defaultAddress.id}
                                checked={radioValue == defaultAddress.id}
                                className="me-2 hidden"
                                onChange={(e) => {
                                    console.log(e);
                                    setRadioValue(e.value);
                                }}
                            />
                            <div className="default_address flex justify-content-between gap-2 align-items-start flex-1">
                                <div>
                                    <div className="flex gap-2 align-items-center">
                                        <span className="inline-block flex justify-content-center align-items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="23"
                                                height="23"
                                                viewBox="0 0 23 23"
                                                fill="none">
                                                <path
                                                    d="M11.5 2.41602C13.6549 2.41602 15.7215 3.27204 17.2452 4.79577C18.769 6.31951 19.625 8.38613 19.625 10.541C19.625 13.2984 18.1205 15.5899 16.47 17.2772C15.6467 18.1092 14.7486 18.8637 13.7871 19.5311C13.7864 19.5316 13.7858 19.532 13.7852 19.5325L13.3851 19.8048L13.3829 19.8063L13.1976 19.9295L12.8446 20.1543L12.5311 20.3455L12.141 20.5724C12.1404 20.5728 12.1398 20.5731 12.1392 20.5735C11.9443 20.684 11.7241 20.7421 11.5 20.7421C11.2759 20.7421 11.0557 20.684 10.8608 20.5735C10.8602 20.5731 10.8596 20.5728 10.859 20.5724L10.4698 20.346L9.98208 20.0459L9.80774 19.9324L9.80342 19.9296L9.41797 19.6729C9.41745 19.6726 9.41694 19.6722 9.41642 19.6719C8.37871 18.9693 7.41197 18.1673 6.52993 17.2772C4.8792 15.5877 3.375 13.2982 3.375 10.541C3.375 8.38613 4.23102 6.31951 5.75476 4.79577C7.27849 3.27204 9.34512 2.41602 11.5 2.41602ZM11.5 7.16602C11.0568 7.16602 10.6179 7.25331 10.2084 7.42292C9.79897 7.59253 9.42691 7.84113 9.11351 8.15453C8.80012 8.46793 8.55152 8.83999 8.38191 9.24946C8.2123 9.65893 8.125 10.0978 8.125 10.541C8.125 10.9842 8.2123 11.4231 8.38191 11.8326C8.55152 12.242 8.80012 12.6141 9.11351 12.9275C9.42691 13.2409 9.79897 13.4895 10.2084 13.6591C10.6179 13.8287 11.0568 13.916 11.5 13.916C12.3951 13.916 13.2535 13.5604 13.8865 12.9275C14.5194 12.2946 14.875 11.4361 14.875 10.541C14.875 9.64591 14.5194 8.78747 13.8865 8.15453C13.2535 7.5216 12.3951 7.16602 11.5 7.16602Z"
                                                    stroke="#312B29"
                                                />
                                            </svg>
                                        </span>
                                        <p className="address_first-name">
                                            {defaultAddress.firstName}
                                        </p>
                                    </div>
                                    <div className="address-details">
                                        <p>
                                            {defaultAddress.addressLine}{' '}
                                            {defaultAddress?.country?.name}
                                        </p>
                                        <div className="address-phone__wrapper flex gap-2 align-items-center">
                                            <p>{defaultAddress?.phone}</p>
                                            <span className="inline-block">
                                                {defaultAddress?.phoneVerified ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="16"
                                                        viewBox="0 0 15 16"
                                                        fill="none">
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9.36578 1.18766C8.84564 0.74382 8.18426 0.5 7.50048 0.5C6.8167 0.5 6.15533 0.74382 5.63518 1.18766C5.17498 1.57977 4.6028 1.81675 4.00011 1.86485C3.31863 1.91909 2.67881 2.21451 2.19556 2.69804C1.71232 3.18158 1.41729 3.82155 1.36348 4.50303C1.31643 5.1046 1.0778 5.67593 0.68626 6.13803C0.243293 6.65791 0 7.31857 0 8.00156C0 8.68455 0.243293 9.34521 0.68626 9.86509C1.07948 10.3255 1.31643 10.8985 1.36348 11.5001C1.41735 12.1818 1.71261 12.822 2.1962 13.3056C2.67979 13.7892 3.32001 14.0844 4.00179 14.1383C4.60339 14.1853 5.17642 14.4223 5.63686 14.8138C6.15677 15.2567 6.81746 15.5 7.50048 15.5C8.18351 15.5 8.8442 15.2567 9.3641 14.8138C9.82431 14.4217 10.3965 14.1847 10.9992 14.1366C11.6807 14.0828 12.3207 13.7878 12.8042 13.3045C13.2878 12.8213 13.5832 12.1815 13.6375 11.5001C13.6851 10.8976 13.9215 10.3255 14.313 9.86509C14.7564 9.34509 15 8.68409 15 8.00072C15 7.31735 14.7564 6.65635 14.313 6.13635C13.9209 5.67616 13.6839 5.10402 13.6358 4.50135C13.5816 3.82017 13.2864 3.18059 12.8032 2.69741C12.32 2.21422 11.6804 1.91904 10.9992 1.86485C10.3965 1.81675 9.82599 1.57977 9.36578 1.18766ZM10.8815 7.24203C11.0043 7.12952 11.1036 6.9938 11.1736 6.8427C11.2436 6.69161 11.2829 6.52814 11.2894 6.36175C11.2959 6.19535 11.2693 6.02933 11.2112 5.87327C11.1531 5.71721 11.0646 5.5742 10.9509 5.45253C10.8372 5.33085 10.7006 5.23291 10.5488 5.16436C10.397 5.09582 10.2332 5.05803 10.0667 5.05317C9.90027 5.04832 9.7345 5.07651 9.579 5.13609C9.4235 5.19568 9.28135 5.2855 9.16077 5.40034L6.42163 7.95787L5.8402 7.41679C5.59532 7.19238 5.27174 7.0737 4.93983 7.08657C4.60791 7.09944 4.2945 7.24282 4.06773 7.48552C3.84097 7.72822 3.71918 8.05062 3.72886 8.38263C3.73854 8.71463 3.8789 9.02939 4.11942 9.25847L5.55956 10.6028C5.79299 10.8208 6.10051 10.9421 6.41995 10.9421C6.73939 10.9421 7.04692 10.8208 7.28034 10.6028L10.8815 7.24203Z"
                                                            fill="#16CD74"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <span
                                                        className="orange-text cursor-pointer"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            verifyPhoneHandler();
                                                        }}>
                                                        {t(`verify`)}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="cursor-pointer orange-text"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            editHandler(defaultAddress);
                                        }}>
                                        {t(`edit`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="line-break" />
                    </>
                )) ||
                    ''}
                <div className="add_address__wrapper mx-3 my-4 flex justify-content-between align-items-center flex-wrap gap-4">
                    <div className="new_address">
                        <div
                            className="cursor-pointer add_address__title"
                            onClick={(e) => {
                                e.preventDefault();
                                setToggleFirstModal(true);
                            }}>
                            +{t('add_new_address')}
                        </div>
                    </div>
                    {list?.list?.length > 0 && (
                        <div className="address_actions flex justify-content-between align-items-center gap-2">
                            <Button
                                label={t('change')}
                                className="change_btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggleChangeModal(true);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <VerifyPhoneModal
                toggleOtpModal={toggleOtpModal}
                setToggleOtpModal={setToggleOtpModal}
                otpInfo={otpInfo}
                setOtpInfo={setOtpInfo}
                control={control}
                reset={reset}
                resetField={resetField}
                isDirty={isDirty}
                isValid={isValid}
                forceRerender={forceRerender}
            />
            <AddressModal
                toggleFirstModal={toggleFirstModal}
                setToggleFirstModal={setToggleFirstModal}
                toggleSecondModal={toggleSecondModal}
                setToggleSecondModal={setToggleSecondModal}
                control={control}
                errors={errors}
                watchedData={watchedData}
                handleSubmit={handleSubmit}
                resetField={resetField}
                countriesList={countriesList}
                editMode={editMode}
                addAddress={addAddress}
                editAddress={editAddress}
                handleReset={handleReset}
                pin={pin}
                setPin={setPin}
            />
            <ChangeAddressModal
                addressList={list?.list}
                showChangeModal={toggleChangeModal}
                setShowChangeModal={setToggleChangeModal}
                forceRerender={forceRerender}
                selectedAddressID={selectedAddressID}
                setSelectedAddressID={setSelectedAddressID}
            />
        </>
    );
};

export default AddressList;
