import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Overview = ({ productDetails }) => {
    const { t } = useTranslation();
    const { fullDescription, specifications, shortDescription } = productDetails;
    const [showMore, setShowMore] = useState(false);

    console.log(productDetails, 'product details');

    return (
        <div className="card-bx">
            <h3>{t('description')}</h3>
            <div style={{ paddingBottom: '10px', overflow: 'hidden' }}>
                <div>{parse(showMore ? fullDescription : shortDescription)}</div>
                <div
                    className="more-details-icon"
                    style={{ color: '#F86B34', cursor: 'pointer', borderBottom: '20px' }}
                    onClick={() => setShowMore(!showMore)}>
                    {fullDescription ? (showMore ? t('show_less') : t('show_more')) : null}
                </div>
            </div>

            <div>
                <div className="features-bx">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            <div className="specifications">
                                {specifications.length > 0 ? (
                                    <>
                                        <h3>{t('specifications')}</h3>
                                        <DataTable value={specifications} stripedRows>
                                            <Column field="key"></Column>
                                            <Column field="value"></Column>
                                        </DataTable>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
