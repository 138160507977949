import React from 'react';
import { Paginator } from 'primereact/paginator';
import { Skeleton } from 'primereact/skeleton';
import CartItem from '../../components/CartItem/CartItem';
import NoData from '../../components/NoData/NoData';

const CartsItems = ({ categoryProducts, isLoading, paging, rows, onPageChange, first }) => {
    // if (isLoading) return <Skeleton />;

    return (
        <>
            <div className="grid pt_30">
                {categoryProducts?.length > 0 ? (
                    categoryProducts?.map((card) => (
                        <div key={card.id} className="col-12 md:col-3 lg:col-4">
                            <div className="new-cart-item">
                                <CartItem
                                    key={card.id}
                                    id={card?.id}
                                    type={card?.type}
                                    label={card.label}
                                    manufacturer={card.manufacturer}
                                    cart={card.cart}
                                    saleText={card.saleText}
                                    title={card.name}
                                    price={card?.prices?.price}
                                    img={card.imageURL}
                                    rateText={card?.rating?.avgRate}
                                    priceOld={card?.prices?.oldPrice}
                                    desc={card.shortDescription}
                                    shipping={card.shipping}
                                    stockStatus={card.stockStatus}
                                    displayStockQuantity={card.displayStockQuantity}
                                    stockQuantity={card.stockQuantity}
                                    isWishlist={card.isWishlist}
                                    associatedId={card?.associatedId}
                                    productCard={card}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <NoData />
                )}
            </div>

            {paging?.TotalItems > 20 && (
                <div className="pt_20">
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={paging?.TotalItems}
                        onPageChange={onPageChange}
                    />
                </div>
            )}
        </>
    );
};

export default CartsItems;
