import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { registerAPI } from '../../api/authAPIs';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { Dropdown } from 'primereact/dropdown';
import { getCountries, getAuthProviders } from '../../api/commonAPIs';
import { authTypes } from '../../utils/constants';
import { useSocialLogin } from '../../hooks/useSocialLogin';
import Swal from 'sweetalert2';
import sweetAlert from '../../helpers/sweetAlert';
import PasswordChecklist from 'react-password-checklist';
import countryOptionTemplate from '../../components/countryOptions/countryOptions';
import selectedCountryTemplate from '../../components/selectedCountry/selectedCountry';
import '../../assets/css/pages/auth.css';
import { getAddToWishList } from '../../api/wishListAPI';
import { getAddToCompareList } from '../../api/compareListAPIs';

const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socialLogin = useSocialLogin();

    const [password, setPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');
    const [countriesList, setCountreisList] = useState([]);
    const [activeSocialLoginProviders, setActiveSocialLoginProviders] = useState([]);

    function handleSetPassword(event) {
        setPassword(event.target.value);
    }

    function handleSetMatchPassword(event) {
        setMatchPassword(event.target.value);
    }

    const {
        control,
        watch,
        formState: { errors, isDirty, isValid },
        reset,
        resetField,
        handleSubmit,
    } = useForm({ mode: 'onChange' });

    const [watchedPassword, watchedCountryCode] = watch(['password', 'countryCode']);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    async function getActiveSocialLoginProviders() {
        dispatch(showLoader());

        try {
            const { data } = await getAuthProviders();
            setActiveSocialLoginProviders(data?.data?.filter((provider) => provider?.enabled));
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function getCountriesList() {
        dispatch(showLoader());

        try {
            const res = await getCountries(true);
            setCountreisList(res.data.data);
            reset({
                countryCode: {
                    id: res.data.data[0]?.id,
                    name: res.data.data[0]?.phoneCode,
                    code: res.data.data[0]?.twoLetterCode,
                    hintText: res.data.data[0]?.hintText,
                    phoneLength: res.data.data[0]?.phoneLength,
                },
            });
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    const onSubmit = (data) => {
        let finalData = {
            ...data,
            countryId: data?.countryCode?.id,
            phoneNumber: data?.phoneNumber?.toString(),
            authType: authTypes.phone,
        };

        delete finalData.countryCode;

        dispatch(showLoader());
        registerAPI({ model: finalData })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: t('signup_success'),
                    text: t('otp_sent_msg'),
                    confirmButtonText: t('close'),
                });
                setTimeout(() => {
                    navigate(`/otp?Key=${res.data.data.key}`);
                }, 1000);
            })
            .catch((error) => {
                const errorList = error.response?.data?.statusMessage || ['server error'];
                Swal.fire({
                    icon: 'error',
                    title: errorList,
                    confirmButtonText: t('close'),
                });
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };

    useEffect(() => {
        getCountriesList();
        getActiveSocialLoginProviders();
    }, []);

    return (
        <div className="auth-bx">
            <div className="title">
                <h3>{t('sign_up')}</h3>
            </div>

            <div className="form-bx">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
                    <div className="field">
                        <label
                            htmlFor="firstName"
                            className={classNames({ 'p-error': errors.name })}>
                            <span className="red-asterisk">*</span> {t('first_name')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: t('this_field_is_required') }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        className={classNames({ 'p-invalid': fieldState.error })}
                                        placeholder={t('first_name_placeholder')}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                        </span>
                        {getFormErrorMessage('firstName')}
                    </div>

                    <div className="field">
                        <label
                            htmlFor="lastName"
                            className={classNames({ 'p-error': errors.name })}>
                            <span className="red-asterisk">*</span> {t('last_name')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        className={classNames({ 'p-invalid': fieldState.error })}
                                        placeholder={t('last_name_placeholder')}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                        </span>
                        {getFormErrorMessage('lastName')}
                    </div>

                    <div className="field">
                        <label htmlFor="email" className={classNames({ 'p-error': errors.name })}>
                            <span className="red-asterisk">*</span> {t('email')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t('invalid_email_address'),
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        className={classNames({ 'p-invalid': fieldState.error })}
                                        placeholder={t('email_placeholder')}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                        </span>
                        {getFormErrorMessage('email')}
                    </div>

                    <div className="field phone-num">
                        <label
                            htmlFor="countryCode"
                            className={classNames({ 'p-error': errors.countryCode })}>
                            <span className="red-asterisk">*</span> {t('phone_number')}
                        </label>
                        <div className="phone-fields">
                            <div className="field">
                                <Controller
                                    name="countryCode"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            value={field.value || ''}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                resetField('phoneNumber');
                                            }}
                                            options={countriesList.map((country) => ({
                                                id: country?.id,
                                                name: country?.phoneCode,
                                                code: country?.twoLetterCode,
                                                hintText: country?.hintText,
                                                phoneLength: country?.phoneLength,
                                            }))}
                                            placeholder={t('country_code')}
                                            optionLabel="code"
                                            filter
                                            valueTemplate={selectedCountryTemplate}
                                            itemTemplate={countryOptionTemplate}
                                        />
                                    )}
                                />
                                {getFormErrorMessage('phoneCode')}
                            </div>

                            <div className="field w-full">
                                <span className="phone_num">
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{
                                            required: t('this_field_is_required'),
                                            minLength: {
                                                value: watchedCountryCode?.phoneLength,
                                                message: t('phone_number_min_length', {
                                                    length: watchedCountryCode?.phoneLength || 0,
                                                }),
                                            },
                                            maxLength: {
                                                value: watchedCountryCode?.phoneLength,
                                                message: t('phone_number_max_length', {
                                                    length: watchedCountryCode?.phoneLength || 15,
                                                }),
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                {...field}
                                                id={field.name}
                                                value={field.value || ''}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                className={classNames({
                                                    'p-invalid': fieldState.error,
                                                })}
                                                placeholder={
                                                    watchedCountryCode?.hintText ||
                                                    t('phone_number_placeholder')
                                                }
                                                maxLength={watchedCountryCode?.phoneLength}
                                                keyfilter="pint"
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage('phoneNumber')}
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label
                            htmlFor="password"
                            className={classNames({ 'p-error': errors.name })}>
                            <span className="red-asterisk">*</span> {t('password')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    minLength: {
                                        value: 8,
                                    },
                                    maxLength: {
                                        value: 16,
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                        message: 'password must have special charcter',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Password
                                            id={field.name}
                                            {...field}
                                            inputRef={field.ref}
                                            className={classNames({
                                                'p-invalid': fieldState.error,
                                            })}
                                            toggleMask
                                            placeholder={t('password_placeholder')}
                                            feedback={false}
                                            value={password}
                                            maxLength="16"
                                            onChange={(e) => {
                                                handleSetPassword(e);
                                                field.onChange(e.target.value);
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </span>
                        <PasswordChecklist
                            rules={['minLength', 'capital', 'lowercase', 'specialChar', 'number']}
                            minLength={8}
                            maxLength={16}
                            value={password}
                            valueAgain={matchPassword}
                            className="check-list-items"
                            messages={{
                                minLength: t('password_has_at_least_characters'),
                                specialChar: t('password_has_special_characters'),
                                capital: t('password_has_a_capital_letter'),
                                lowercase: t('password_has_a_lowercase_letter'),
                                number: t('password_has_a_number'),
                            }}
                        />
                        {/* {getFormErrorMessage('password')} */}
                    </div>

                    <div className="field">
                        <label
                            htmlFor="confirmPassword"
                            className={classNames({ 'p-error': errors.name })}>
                            <span className="red-asterisk">*</span> {t('confirm_password')}
                        </label>
                        <span className="p-float-label mb_15">
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    validate: (value) =>
                                        value === watchedPassword || 'The passwords do not match',
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Password
                                            id={field.name}
                                            {...field}
                                            inputRef={field.ref}
                                            className={classNames({
                                                'p-invalid': fieldState.error,
                                            })}
                                            feedback={false}
                                            value={matchPassword}
                                            placeholder={t('confirm_password_placeholder')}
                                            onChange={(e) => {
                                                handleSetMatchPassword(e);
                                                field.onChange(e.target.value);
                                            }}
                                            maxLength="16"
                                            toggleMask
                                        />
                                    </>
                                )}
                            />
                        </span>
                        {/* {getFormErrorMessage('confirmPassword')} */}
                    </div>

                    <Button
                        className="btn"
                        type="submit"
                        label={t('sign_up')}
                        disabled={!isDirty || !isValid}
                        onClick={() => {
                            window.scroll({ top: 0, behavior: 'smooth' });
                        }}
                    />
                </form>
                <div className="social-bx">
                    {activeSocialLoginProviders?.length ? (
                        <>
                            <div className="title">{t('or_signup_by')}</div>
                            <div className="icons">
                                {activeSocialLoginProviders?.map((provider) => (
                                    <button
                                        key={provider?.id}
                                        onClick={() => {
                                            socialLogin(provider?.title, provider?.type);
                                        }}>
                                        <img src={provider?.icon} alt={provider?.title} />
                                    </button>
                                ))}
                            </div>
                        </>
                    ) : null}
                    <div className="text">
                        {t('already_have_account')}
                        <Link
                            to="/login"
                            onClick={() => {
                                window.scroll({ top: 0, behavior: 'smooth' });
                            }}>
                            {t('sign_in')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
