import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import "./Loader.css";

const Loader = () => {
    return (
        <div className="loader-h-100">
            <PuffLoader
                color="#DB5F19"
                loading={true}
                size={70}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
};

export default Loader;
