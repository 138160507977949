import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseInstance } from './baseInstance';

export const getWishList = createAsyncThunk('/Wishlist/MyWishList', async (body) => {
    try {
        const res = await baseInstance.post(`/Wishlist/MyWishList`, body);
        return res.data;
    } catch (error) {
        console.log(error);
    }
});

export const getAddToWishList = createAsyncThunk('/Wishlist/AddToMyWishList', async (ids) => {
    try {
        const res = await baseInstance.post(`/Wishlist/AddToMyWishList`, {
            ids: [ids.productId],
            fromcache: true,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
});

export const getRemoveFromWishList = createAsyncThunk('/Wishlist/AddToMyWishList', async (ids) => {
    try {
        await baseInstance.post(`/Wishlist/RemoveFromMyWishList`, {
            ids: [ids.productId],
            fromcache: true,
        });
        const { data } = await baseInstance.post(`/Wishlist/MyWishList`, {
            paging: { PageNumber: 0, PageSize: 20 },
            isGuest: false,
        });
        return data;
    } catch (error) {
        console.log(error);
    }
});

export const addWishListFromLogin = (ids) => {
    return baseInstance.post(`/Wishlist/AddToMyWishList`, {
        ids,
        fromcache: true,
    });
};
