import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Breadcrumb = ({ itemName = "" }) => {
    const { t } = useTranslation();

    return (
        <div className="breadcrumb-bx">
            <ul>
                <li><Link to="/">{t('home')}</Link></li>
                <li className="active">{t(itemName)}</li>
            </ul>
        </div>
    );
};

export default Breadcrumb;
