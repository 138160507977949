import {getBusinessTypes, getCities , getCountries , getGovernorates } from '../../api/commonAPIs'; 

export const getSelectsData = async () => {
    const allRes = await Promise.all([
        getBusinessTypes(),
        getCountries(true),
    ]);

    const businessTypes = allRes[0].data.data;
    const countriesList = allRes[1].data.data 

    return {businessTypes , countriesList};
}