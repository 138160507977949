import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import getFormErrorMessage from '../formError/formError';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import LoadingButton from '../LoadingButton/LoadingButton';
import { useSelector } from 'react-redux';

export default function AddDebitCardModal({
    showModal,
    setShowModal = () => {},
    handleSubmit = () => {},
    control,
    addNewCard = () => {},
    errors,
    watch = () => {},
    isValid,
    setValue = () => {},
    reset = () => {},
    withDefault = false,
}) {
    const { t } = useTranslation();
    const ref = useRef();
    const calendarRef = useRef();

    const isMiniLoader = useSelector((state) => state.mainLoader.isMiniLoader);

    const handleReset = () => {
        setShowModal(false);
        reset({
            id: '',
            cardNumber: '',
            holderName: '',
            expiryDate: '',
            default: false,
        });
    };

    //    useEffect(() => {
    //       document.addEventListener('click', (e) => {
    //          if (
    //             ref.current &&
    //             !ref.current.contains(e.target) &&
    //             calendarRef.current &&
    //             !calendarRef.current.contains(e.target)
    //          ) {
    //             handleReset();
    //          }
    //       });
    //       return () => {
    //          document.removeEventListener('click', (e) => {
    //             if (
    //                ref.current &&
    //                !ref.current.contains(e.target) &&
    //                calendarRef.current &&
    //                !calendarRef.current.contains(e.target)
    //             ) {
    //                handleReset();
    //             }
    //          });
    //       };
    //    }, []);
    return (
        <>
            <Dialog
                header={null}
                visible={showModal}
                style={{ width: '50%', maxWidth: '490px' }}
                className="add-card-modal"
                draggable={false}
                resizable={false}
                onHide={handleReset}>
                <div ref={ref}>
                    <h2>{t('addCard')}</h2>
                    <form onSubmit={handleSubmit(addNewCard)} className="mt-2">
                        <div className="p-fluid">
                            <div className="p-field mb-3">
                                <label className="block mb-2" htmlFor="holderName">
                                    {t('name')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="p-float-label">
                                    <Controller
                                        name="holderName"
                                        control={control}
                                        rules={{
                                            required: t('required'),
                                            minLength: {
                                                value: 8,
                                                message: t('cardNameValidation'),
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('cardNameValidation'),
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id="holderName"
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                placeholder={t('addName')}
                                                keyfilter={/^[a-zA-Z\s]*$/}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors['holderName'])}
                            </div>
                            <div className="p-field mb-3">
                                <label className="block mb-2" htmlFor="cardNumber">
                                    {t('cardNumber')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="p-float-label">
                                    <Controller
                                        name="cardNumber"
                                        control={control}
                                        placeholder={t('cardNumberPlaceholder')}
                                        rules={{
                                            required: t('required'),
                                            pattern: {
                                                value: /^[0-9]{16}$/,
                                                message: t('cardNumberValidation'),
                                            },
                                            maxLength: {
                                                value: 16,
                                                message: t('cardNumberValidation'),
                                            },
                                            minLength: {
                                                value: 16,
                                                message: t('cardNumberValidation'),
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id="cardNumber"
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                keyfilter="pnum"
                                                placeholder={t('cardNumberPlaceholder')}
                                                maxLength={16}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors['cardNumber'])}
                            </div>
                            <div className="p-field mb-3">
                                <label className="block mb-2" htmlFor="expiryDate">
                                    {t('expiryDate')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="p-float-label">
                                    <Controller
                                        name="expiryDate"
                                        control={control}
                                        placeholder={t('expiryDatePlaceholder')}
                                        rules={{ required: t('required') }}
                                        render={({ field, fieldState }) => (
                                            <div ref={calendarRef}>
                                                <Calendar
                                                    id="expiryDate"
                                                    {...field}
                                                    className={classNames({
                                                        'p-invalid': fieldState.invalid,
                                                    })}
                                                    placeholder="MM / YY"
                                                    dateFormat="mm/y"
                                                    view="month"
                                                    yearNavigator
                                                    yearRange={`${new Date().getFullYear()}:${
                                                        new Date().getFullYear() + 30
                                                    }`}
                                                    minDate={
                                                        new Date(
                                                            `${new Date().getFullYear()}-${
                                                                new Date().getMonth() + 2
                                                            }-01`,
                                                        )
                                                    }
                                                    appendTo={document.body}
                                                />
                                            </div>
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors['expiryDate'])}
                            </div>
                            <div className="p-field mb-4">
                                <div className="flex align-items-center">
                                    {withDefault ? (
                                        <>
                                            <Checkbox
                                                inputId="default"
                                                name="default"
                                                value="default"
                                                onChange={(e) => setValue('default', e.checked)}
                                                checked={watch('default')}
                                            />
                                            <label htmlFor="default" className="ml-2">
                                                {t('default_card')}
                                            </label>
                                        </>
                                    ) : (
                                        <>
                                            <Checkbox
                                                inputId="isSaved"
                                                name="isSaved"
                                                value="isSaved"
                                                onChange={(e) => setValue('isSaved', e.checked)}
                                                checked={watch('isSaved')}
                                            />
                                            <label htmlFor="isSaved" className="ml-2">
                                                {t('saveCard')}
                                            </label>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="p-field w-full flex align-items-center justify-content-center gap-2">
                                <button
                                    type="button"
                                    className="main-btn flex-1 cancel-btn"
                                    disabled={isMiniLoader}
                                    onClick={handleReset}>
                                    {t(`cancel`)}
                                </button>

                                <LoadingButton
                                    btnClick={handleSubmit(addNewCard)}
                                    btnText={t('addCard')}
                                    btnDisabled={!isValid}
                                    btnClassName="main-btn w-full flex-1 add-card-btn"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
}
