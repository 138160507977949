import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../../assets/css/pages/my-account.css';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { clearMiniCartModal } from '../../redux/addToCart';
import { logoutAction } from '../../redux/authSlice';

const Profile = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState();
    const { firstName, lastName, isLogged } = useSelector((state) => state.authReducer);

    function changeBreadcrumb(pathname) {
        if (pathname?.includes('orders')) return 'orders';
        pathname = pathname?.split('/')?.pop();
        return pathname?.replace(/-/g, ' ') || 'profile';
    }

    useEffect(() => {
        if (pathname === '/profile') {
            navigate('/profile/info');
        }
    }, [pathname]);

    return (
        <>
            <section className="breadcrumb-section profile-breadcrumb container_bx">
                <Breadcrumb itemName={changeBreadcrumb(pathname)} />
            </section>

            <section className="my-account-section container_bx">
                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <div className="filter-catug">
                            <div className="acount-head">
                                <span>{isLogged && `${firstName} ${lastName}`}</span>
                                <p>
                                    {`${t(`not`)} ${firstName}`}?{' '}
                                    <span
                                        onClick={() => {
                                            dispatch(logoutAction());
                                            dispatch(clearMiniCartModal());
                                            navigate('/');
                                            window.scroll({
                                                top: 0,
                                                behavior: 'smooth',
                                            });
                                        }}>
                                        {t(`logout`)}
                                    </span>
                                </p>
                            </div>
                            <nav className="account-links">
                                <NavLink
                                    to="/profile/info"
                                    className=""
                                    onClick={() => setShow(true)}>
                                    <div
                                        onClick={() => {
                                            window.scroll({
                                                top: 0,
                                                behavior: 'smooth',
                                            });
                                        }}>
                                        {t('my_profile')}
                                    </div>
                                </NavLink>
                                <NavLink
                                    className=""
                                    to="/profile/orders"
                                    onClick={() => setShow(true)}>
                                    <div
                                        onClick={() => {
                                            window.scroll({
                                                top: 0,
                                                behavior: 'smooth',
                                            });
                                        }}>
                                        {t('my_orders')}
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="hidden"
                                    to="/profile/payments"
                                    onClick={() => setShow(true)}>
                                    <div
                                        onClick={() => {
                                            window.scroll({
                                                top: 0,
                                                behavior: 'smooth',
                                            });
                                        }}>
                                        {t('payments')}
                                    </div>
                                </NavLink>
                                <NavLink
                                    className=""
                                    to="/profile/addresses"
                                    onClick={() => setShow(true)}>
                                    <div
                                        onClick={() => {
                                            window.scroll({ top: 0, behavior: 'smooth' });
                                        }}>
                                        {t('adresses')}
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="gray-color"
                                    onClick={() => {
                                        dispatch(logoutAction());
                                        dispatch(clearMiniCartModal());
                                        navigate('/');
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                    to={'/'}>
                                    {t('Sign_out')}
                                </NavLink>
                            </nav>
                        </div>
                    </div>

                    <div className="col-12 md:col-9 lg:col-9">
                        <div
                            className={`${
                                pathname?.includes('orders') ? '' : 'basic-info-bx'
                            } mb_20`}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Profile;
