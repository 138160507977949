import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";

import '../../assets/css/pages/vendor-apply-file.css';
import arrow from '../../assets/images/btn-arrow.svg';
import bannerImg from '../../assets/images/vendor/vendor-banner.svg';
import increasedReach from '../../assets/images/vendor/increased_reach.svg';
import makeMoney from '../../assets/images/vendor/make_money.svg';
import manageOrders from '../../assets/images/vendor/manage_orders.svg';
import videoBg from '../../assets/images/vendor/vendor-video.svg';
import register from '../../assets/images/vendor/register.svg';
import store from '../../assets/images/vendor/store.svg';
import requierments from '../../assets/images/vendor/requirements.svg';
//import crime from '../../assets/crime.mp4';
import connectedMail from '../../assets/images/connected-mail.svg';

const VendorApply = () => {

    const [open, setOpen] = useState(false);

    const toast = useRef(null);

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Form Submitted', detail: getValues('value') });
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        reset
    } = useForm({ mode: 'onChange' });

    const onSubmit = (data) => {
        data.value && show();

        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error"></small>;
    };

    return (
        <>
            <section className='banner-section container_bx'>
                <div className='banner-item'>
                    <div className='text'>
                        <h3>sell on shaari</h3>
                        <p>start selling online today!</p>
                        <Link
                            to='/vendor-wizard'
                            className='apply-btn'
                            onClick={() => {
                                window.scroll({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            sign up <img src={arrow} alt='pic' />
                        </Link>
                    </div>

                    <div className='banner-img'><img src={bannerImg} alt='pic' /></div>
                </div>
            </section>

            <section className='start-section container_bx'>
                <h3 className='center-title'>Start selling on shaari today</h3>

                <div className='section-items'>
                    <div className='grid'>
                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className='start-item'>
                                <img src={increasedReach} alt='pic' />
                                <h4>increased reach</h4>
                                <p>brand owners and resellers. Fresh new startups and Fortune 500s. They all sell on shaari </p>
                            </div>
                        </div>

                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className='start-item'>
                                <img src={makeMoney} alt='pic' />
                                <h4>make money</h4>
                                <p>brand owners and resellers. Fresh new startups and Fortune 500s. They all sell on shaari </p>
                            </div>
                        </div>

                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className='start-item'>
                                <img src={manageOrders} alt='pic' />
                                <h4>manage orders</h4>
                                <p>brand owners and resellers. Fresh new startups and Fortune 500s. They all sell on shaari </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='why-section container_bx'>
                <h3 className='center-title'>why sell on shaari?</h3>

                <div className='video-bx'>
                    <div className='video-bg'>
                        <button className='play-btn' onClick={() => setOpen(true)}>
                            <i className='pi pi-youtube'></i>
                        </button>
                        <img src={videoBg} alt='pic' />
                    </div>
                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        plugins={[Video]}
                        slides={[
                            {
                                type: "video",
                                width: 1280,
                                height: 720,
                                sources: [
                                    {
                                        src: 'https://mohamed-doghaim.net/assets/any.mp4',
                                        //src: crime,
                                        type: "video/mp4",
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
            </section>

            <section className='start-sell-section container_bx'>
                <div className='start-bx'>
                    <div className='title'>
                        <h3>How do I start selling on shaari?</h3>
                        <p>Start selling on shaari in just 3 steps</p>
                    </div>

                    <div className='grid'>
                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className='start-sell-item'>
                                <img src={register} alt='pic' />
                                <p>1.Register as a seller</p>
                            </div>
                        </div>

                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className='start-sell-item'>
                                <img src={store} alt='pic' />
                                <p>2.Create your store</p>
                            </div>
                        </div>

                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className='start-sell-item'>
                                <img src={requierments} alt='pic' />
                                <p>3.Seller Requirements</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='connected-section container_bx'>
                <div className='grid'>
                    <div className='col-12 md:col-6 lg:col-6'>
                        <div className='connected-form'>
                            <h3>Stay connected</h3>
                            <p>Sign up for helpful tips on building shaari business</p>
                            <div className='form-content'>
                                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
                                    <Toast ref={toast} />
                                    <div className='content'>
                                        <div className='form-input'>
                                            <Controller
                                                name="value"
                                                control={control}
                                                rules={{ required: 'Name - Surname is required.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText
                                                            id={field.name}
                                                            value={field.value}
                                                            placeholder='Enter your email'
                                                            className={classNames({ 'p-invalid': fieldState.error })}
                                                            onChange={(e) => field.onChange(e.target.value)}
                                                        />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                            <div className='guide-text'>*You can unsubscribe at any time..</div>
                                        </div>
                                        <Button label="Submit" type="submit" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 md:col-6 lg:col-6'>
                        <div className='connected-img'>
                            <img src={connectedMail} alt='pic' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VendorApply;
