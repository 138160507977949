import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCartQuantity } from '../../api/shopingCartAPIs';
import { getAddToWishList, getRemoveFromWishList } from '../../api/wishListAPI';
import placeholderImage from '../../assets/images/placeholderImage.png';
import sweetAlert from '../../helpers/sweetAlert';
import Counter from '../../pages/ProductDetails/Counter';
import { hideMiniLoader, showMiniLoader } from '../../redux/loaderSlice';
import { Dropdown } from 'primereact/dropdown';

export default function CartProduct({ item = {}, forceRerender = () => {} }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [quantityLoading, setQuantityLoading] = useState(false);
    const [customQuantities, setCustomQuantities] = useState([]);

    async function updateQuantity(itemNumber) {
        if (!itemNumber || typeof +itemNumber !== 'number') return;
        setQuantityLoading(true);
        dispatch(showMiniLoader());
        try {
            const { data } = await updateCartQuantity({
                id: item.cart.cartId,
                quantity: itemNumber,
            });
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.statusMessage) {
                sweetAlert.error(error.response.data.statusMessage);
            }
        }
        setQuantityLoading(false);
        dispatch(hideMiniLoader());
    }

    async function addToWishListHandler() {
        setQuantityLoading(true);
        dispatch(showMiniLoader());
        try {
            if (item?.isWishlist) {
                dispatch(getRemoveFromWishList({ productId: item?.id }));
            } else {
                dispatch(getAddToWishList({ productId: item?.id }));
            }
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.statusMessage) {
                sweetAlert.error(error.response.data.statusMessage);
            }
        }
        setQuantityLoading(false);
        setTimeout(() => {
            dispatch(hideMiniLoader());
        }, 1200);
    }

    useEffect(() => {
        if (item?.allowedQuantities && item?.allowedQuantities?.length > 0) {
            const uniqueQuantities = item?.allowedQuantities.filter(
                (value, index, self) => self.indexOf(value) === index,
            );
            const customQuantities = uniqueQuantities.map((value) => {
                return { name: value, code: value };
            });
            setCustomQuantities(customQuantities);
        }
    }, [item]);

    return (
        <div className="product-item__wrapper flex align-items-center m-1">
            <div className="flex align-items-center justify-content-between flex-1 flex-wrap gap-1">
                <div
                    className="product_name flex align-items-center gap-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                        navigate(
                            item?.type == 10
                                ? `/product-details/${item?.id}/assoicate/`
                                : item?.type == 6
                                  ? `/product-details/${item?.groupId}/assoicate/${item?.id}`
                                  : `/product-details/${item?.id}`,
                        )
                    }>
                    <div
                        className="product-image__wrapper"
                        style={{ width: '50px', height: '50px', overflow: 'hidden' }}>
                        <img
                            src={item?.imageURL}
                            alt={item?.name}
                            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = placeholderImage;
                            }}
                        />
                    </div>
                    <div className="product_name__wrapper">
                        <Tooltip
                            target={`.product_name__title-${item.id}`}
                            position="top"
                            content={item.name}
                        />
                        <h1 className={`product_name__title-${item.id} one-line-text main-text`}>
                            {item.name}
                        </h1>
                        {item?.selectedAttributes?.length > 0 && (
                            <div className="flex gap-2 my-1 align-items-center">
                                {item?.selectedAttributes?.map((attr, index) => {
                                    if (attr?.type === 3) {
                                        return (
                                            <>
                                                <span
                                                    className="inline-block"
                                                    style={{
                                                        backgroundColor: attr?.name,
                                                        width: '20px',
                                                        height: '20px',
                                                        borderRadius: '50%',
                                                    }}></span>
                                                {index !== item?.selectedAttributes?.length - 1
                                                    ? ', '
                                                    : ''}
                                            </>
                                        );
                                    } else {
                                        return (
                                            <span key={index} className="one-line-text">
                                                {attr?.name}
                                                {index !== item?.selectedAttributes?.length - 1
                                                    ? ', '
                                                    : ''}
                                            </span>
                                        );
                                    }
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="product_quantity flex gap-2 align-items-center">
                    <span>{t(`QTY`)}</span>

                    {item?.allowedQuantities && item?.allowedQuantities?.length > 0 ? (
                        <Dropdown
                            value={{ name: item?.cart?.quantity, code: item?.cart?.quantity }}
                            onChange={(e) => updateQuantity(e.value?.name)}
                            options={customQuantities}
                            optionLabel="name"
                            placeholder={t('select_quantity')}
                            className="w-full"
                            disabled={quantityLoading}
                        />
                    ) : (
                        <Counter
                            num={item?.cart?.quantity}
                            disabled={quantityLoading}
                            setNum={updateQuantity}
                        />
                    )}
                </div>
                <div className="item__actions flex align-items-center justify-content-between gap-4">
                    <div className="product_price">
                        <span>{t(`total`)}: </span>
                        <span>
                            {item?.prices?.price ? parseFloat(item?.prices?.price).toFixed(2) : 0}
                        </span>
                    </div>
                    <div
                        className="flex gap-2 align-items-center cursor-pointer"
                        onClick={addToWishListHandler}
                        style={{ color: item?.isWishlist ? 'red' : 'inherit' }}>
                        <span className="inline-block flex align-items-center justify-content-center">
                            <HeartIcon isWishlist={item?.isWishlist} />
                        </span>
                        {/* {t(`wishlist`)} */}
                    </div>
                    <div
                        className="flex gap-2 align-items-center cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            updateQuantity('0');
                        }}>
                        <span className="inline-block flex align-items-center justify-content-center">
                            <RemoveIcon />
                        </span>
                        {/* {t(`remove`)} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

const HeartIcon = ({ isWishlist = false }) => {
    return (
        <svg
            stroke={isWishlist ? 'red' : ''}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none">
            <mask
                id="mask0_2_29207"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="21">
                <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2_29207)">
                <path
                    d="M16.1815 4.87943C15.1834 3.66676 13.7958 3 12.2727 3C11.3142 3 10.3912 3.2417 9.58279 3.70426C8.77437 3.24378 7.85134 3 6.8929 3C5.37189 3 3.98424 3.66676 2.98412 4.87943C1.89233 6.20461 1.44019 8.0132 1.77357 9.7176C2.56949 13.7994 6.75331 16.6644 8.51393 17.7083C8.84313 17.9042 9.21192 18 9.58279 18C9.95367 18 10.3225 17.9021 10.6517 17.7083C12.4123 16.6644 16.5961 13.7994 17.392 9.7176C17.7254 8.01111 17.2712 6.20253 16.1815 4.87943ZM16.5753 9.55924C15.8439 13.3098 11.8935 16.006 10.2287 16.9915C9.83074 17.227 9.33902 17.227 8.94106 16.9915C7.27628 16.006 3.32583 13.3077 2.59449 9.55924C2.30904 8.09446 2.69659 6.54424 3.63003 5.41075C4.46763 4.39394 5.62609 3.83553 6.89499 3.83553C7.78259 3.83553 8.63477 4.07932 9.35986 4.54396C9.49737 4.63148 9.67239 4.63148 9.80782 4.54396C10.5329 4.0814 11.3851 3.83553 12.2727 3.83553C13.5416 3.83553 14.7001 4.39603 15.5377 5.41075C16.4711 6.54424 16.8586 8.09654 16.5732 9.55924H16.5753Z"
                    fill="#312B29"
                />
            </g>
        </svg>
    );
};

const RemoveIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none">
            <path
                d="M8.5 4.5H11.5C11.5 4.10218 11.342 3.72064 11.0607 3.43934C10.7794 3.15804 10.3978 3 10 3C9.60218 3 9.22064 3.15804 8.93934 3.43934C8.65804 3.72064 8.5 4.10218 8.5 4.5ZM7.5 4.5C7.5 3.83696 7.76339 3.20107 8.23223 2.73223C8.70107 2.26339 9.33696 2 10 2C10.663 2 11.2989 2.26339 11.7678 2.73223C12.2366 3.20107 12.5 3.83696 12.5 4.5H17.5C17.6326 4.5 17.7598 4.55268 17.8536 4.64645C17.9473 4.74021 18 4.86739 18 5C18 5.13261 17.9473 5.25979 17.8536 5.35355C17.7598 5.44732 17.6326 5.5 17.5 5.5H16.446L15.252 15.844C15.1676 16.5752 14.8173 17.2498 14.2679 17.7396C13.7184 18.2293 13.008 18.4999 12.272 18.5H7.728C6.99195 18.4999 6.28161 18.2293 5.73214 17.7396C5.18266 17.2498 4.8324 16.5752 4.748 15.844L3.554 5.5H2.5C2.36739 5.5 2.24021 5.44732 2.14645 5.35355C2.05268 5.25979 2 5.13261 2 5C2 4.86739 2.05268 4.74021 2.14645 4.64645C2.24021 4.55268 2.36739 4.5 2.5 4.5H7.5ZM5.741 15.73C5.79743 16.2174 6.03105 16.667 6.39742 16.9934C6.76379 17.3198 7.23735 17.5001 7.728 17.5H12.272C12.7627 17.5001 13.2362 17.3198 13.6026 16.9934C13.969 16.667 14.2026 16.2174 14.259 15.73L15.439 5.5H4.561L5.741 15.73ZM8.5 8C8.63261 8 8.75979 8.05268 8.85355 8.14645C8.94732 8.24021 9 8.36739 9 8.5V14.5C9 14.6326 8.94732 14.7598 8.85355 14.8536C8.75979 14.9473 8.63261 15 8.5 15C8.36739 15 8.24021 14.9473 8.14645 14.8536C8.05268 14.7598 8 14.6326 8 14.5V8.5C8 8.36739 8.05268 8.24021 8.14645 8.14645C8.24021 8.05268 8.36739 8 8.5 8ZM12 8.5C12 8.36739 11.9473 8.24021 11.8536 8.14645C11.7598 8.05268 11.6326 8 11.5 8C11.3674 8 11.2402 8.05268 11.1464 8.14645C11.0527 8.24021 11 8.36739 11 8.5V14.5C11 14.6326 11.0527 14.7598 11.1464 14.8536C11.2402 14.9473 11.3674 15 11.5 15C11.6326 15 11.7598 14.9473 11.8536 14.8536C11.9473 14.7598 12 14.6326 12 14.5V8.5Z"
                fill="black"
            />
        </svg>
    );
};
