import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from '../../../assets/images/plus.svg';
import { getCardsAPI, addCardAPI, deleteCardAPI, updateCardAPI } from '../../../api/checkoutAPIs';
import sweetAlert from '../../../helpers/sweetAlert';
import NoData from '../../../components/NoData/NoData';
import Pagination from '../../../components/Pagination/Pagination';
import './Payments.css';
import { hideLoader, hideMiniLoader, showLoader, showMiniLoader } from '../../../redux/loaderSlice';
import PaymentCard from './PaymentCard';
import AddDebitCardModal from '../../../components/Modal/AddDebitCardModal';
import PaymentCardsDelete from '../../../components/Modal/PaymentCardsDelete';
import PaymentCardsEdit from '../../../components/Modal/PaymentCardsEdit';

const Payments = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [cards, setCards] = useState([]);
    const [addCard, setAddCard] = useState(false);
    const [editCard, setEditCard] = useState(false);
    const [deleteCard, setDeleteCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
    const [pageSize] = useState(10);
    const [page, setPage] = useState(0);
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            id: '',
            cardNumber: '',
            holderName: '',
            expiryDate: '',
            default: false,
        },
    });

    async function getCards() {
        dispatch(showLoader());
        handleReset();
        const cardsData = {
            expired: true,
            paging: {
                PageNumber: page,
                PageSize: pageSize,
            },
        };
        try {
            const { data } = await getCardsAPI(cardsData);
            setCards(data?.data);
        } catch (error) {
            console.error(error);
        }
        dispatch(hideLoader());
    }

    async function addNewCard(values) {
        if (values?.id) delete values?.id;
        const cardData = {
            ...values,
            expiryDate: values?.expiryDate?.toLocaleDateString('en-GB', {
                month: '2-digit',
                year: '2-digit',
            }),
        };
        dispatch(showMiniLoader());
        try {
            await addCardAPI(cardData);
            sweetAlert.success(t('success'));
            getCards();
        } catch (error) {
            sweetAlert.error(
                error?.response?.data
                    ? error?.response?.data?.statusMessage || t('error')
                    : t('error'),
            );
        }
        dispatch(hideMiniLoader());
    }

    const deleteCardHandler = async () => {
        if (!selectedCard?.id) return;
        dispatch(showMiniLoader());
        try {
            await deleteCardAPI(selectedCard?.id);
            sweetAlert.success(t('success'));
            dispatch(hideMiniLoader());
            setSelectedCard({});
            setTimeout(() => {
                setDeleteCard(false);
                getCards();
            }, 1200);
        } catch (error) {
            sweetAlert.error(
                error?.response?.data
                    ? error?.response?.data?.statusMessage || t('error')
                    : t('error'),
            );
        }
        dispatch(hideMiniLoader());
    };

    const handleDefaultCard = async (card) => {
        try {
            await updateCardAPI({ ...card, default: true });
            sweetAlert.success(t('success'));
            getCards();
        } catch (error) {
            sweetAlert.error(
                error?.response?.data
                    ? error?.response?.data?.statusMessage || t('error')
                    : t('error'),
            );
        }
    };

    const editCardHandler = async (values) => {
        const cardData = {
            ...values,
            expiryDate: values?.expiryDate?.toLocaleDateString('en-GB', {
                month: '2-digit',
                year: '2-digit',
            }),
        };
        dispatch(showMiniLoader());
        try {
            await updateCardAPI(cardData);
            sweetAlert.success(t('success'));
            dispatch(hideMiniLoader());
            setTimeout(() => {
                setEditCard(false);
                getCards();
            }, 400);
        } catch (error) {
            sweetAlert.error(
                error?.response?.data
                    ? error?.response?.data?.statusMessage || t('error')
                    : t('error'),
            );
        }
        dispatch(hideMiniLoader());
    };

    const handleEditCard = (card) => {
        reset({
            id: card?.id,
            cardNumber: card?.cardNumber,
            holderName: card?.holderName,
            expiryDate: new Date(
                `20${card?.expiryDate.slice(-2)}/${card?.expiryDate.slice(0, 2)}/01`,
            ),
            default: card?.default,
        });
        setEditCard(true);
    };

    const handleReset = () => {
        reset({
            id: '',
            cardNumber: '',
            holderName: '',
            expiryDate: '',
            default: false,
        });
        setAddCard(false);
        setEditCard(false);
    };

    useEffect(() => {
        getCards();
    }, [page]);

    return (
        <>
            <div className="tab-title">
                <div className="tab-header">
                    <h3>{t('paymentCard')}</h3>
                    <button
                        className="main-btn hidden"
                        onClick={() => setAddCard(true)}
                        style={{ minWidth: 'fit-content', borderRadius: '10px' }}>
                        {t('add')}
                        <span
                            className="flex align-items-center justify-content-center mx-1"
                            style={{ width: '10px', height: '10px' }}>
                            <Plus className="w-full h-full" />
                        </span>
                    </button>
                    <p className="">{t('payment_info')}</p>
                </div>

                {cards?.list?.length ? (
                    <>
                        {(cards?.list?.filter((card) => card?.default == true)?.length && (
                            <div className="default-address hidden">
                                <h3>{t('default_card')}</h3>
                                {cards?.list?.filter((card) => card?.default == true)?.length &&
                                    cards?.list
                                        ?.filter((card) => card?.default == true)
                                        ?.map((card, index) => (
                                            <div key={index}>
                                                <PaymentCard
                                                    card={card}
                                                    handleEditCard={handleEditCard}
                                                />
                                            </div>
                                        ))}
                            </div>
                        )) ||
                            ''}
                        {(cards?.list?.filter((card) => card?.default == false)?.length && (
                            <>
                                <div className="other-address hidden">
                                    <h3>{t('other_card')}</h3>
                                    {cards?.list
                                        ?.filter((card) => card?.default == false)
                                        ?.map((card, index) => (
                                            <div key={index}>
                                                <PaymentCard
                                                    card={card}
                                                    deleteCard={() => {
                                                        setDeleteCard(true);
                                                        setSelectedCard(card);
                                                    }}
                                                    handleEditCard={handleEditCard}
                                                    handleDefaultCard={handleDefaultCard}
                                                />
                                            </div>
                                        ))}
                                </div>
                                {(cards?.paging?.TotalItems > pageSize && (
                                    <div className="hidden">
                                        <div className="w-full flex justify-content-center mt-4">
                                            <Pagination
                                                totalItems={cards?.paging?.TotalItems}
                                                itemsPerPage={pageSize}
                                                currentPage={page}
                                                onPageChange={setPage}
                                            />
                                        </div>
                                    </div>
                                )) ||
                                    ''}
                            </>
                        )) ||
                            ''}
                    </>
                ) : (
                    ''
                )}
                <NoData productShape={true} />

                <AddDebitCardModal
                    showModal={addCard}
                    setShowModal={setAddCard}
                    handleSubmit={handleSubmit}
                    addNewCard={addNewCard}
                    errors={errors}
                    isValid={isValid}
                    reset={reset}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    withDefault={true}
                />

                <PaymentCardsDelete
                    showModal={deleteCard}
                    setShowModal={setDeleteCard}
                    deleteCardHandler={deleteCardHandler}
                />

                <PaymentCardsEdit
                    showModal={editCard}
                    setShowModal={setEditCard}
                    control={control}
                    errors={errors}
                    isValid={isValid}
                    setValue={setValue}
                    watch={watch}
                    editCardHandler={editCardHandler}
                    handleReset={handleReset}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    );
};

export default Payments;
