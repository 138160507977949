import { baseInstance } from './baseInstance';

export const getBusinessTypes = () => {
    return baseInstance.get('/Common/GetBusinessTypes');
};

export const getCountries = (isLogin = false) => {
    return baseInstance.get(`/Common/GetCountries?isLogin=${isLogin}`);
};

export const getGovernorates = (countrId) => {
    return baseInstance.get(`/Common/GetGovernorates?countryID=${countrId}`);
};

export const getCities = (governorateId) => {
    return baseInstance.get(`/Common/GetCities?governorateID=${governorateId}`);
};

export const getAuthProviders = () => {
    return baseInstance.get('/Lookup/GetSoicalConfig');
};

export const getBanners = () => {
    return baseInstance.post('/Catalog/HomeSlider');
};

export const getAppSettings = () => {
    return baseInstance.get(`Lookup/GetAppSettings`);
};
