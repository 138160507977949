const countryOptionTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            <img
                alt={option?.name || ''}
                src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                className={`flag flag-${option?.code?.toLowerCase()}`}
                style={{ width: '18px', marginInlineEnd: '8px' }}
            />
            <div>{`${option?.name || '00'} - ${option?.code || ''}`}</div>
        </div>
    );
};

export default countryOptionTemplate;
