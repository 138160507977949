import React, { useEffect, useState } from 'react';
import {
    addCustomerAddress,
    deleteCustomerAddress,
    getCustomerAddress,
    updateCustomerAddress,
    SendSmsOtp,
} from '../../../api/CustomerAPIs';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/loaderSlice';
import { Dialog } from 'primereact/dialog';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Back } from '../../../assets/images/back.svg';
import { ReactComponent as Arrow } from '../../../assets/images/btn-arrow.svg';
import { ReactComponent as AddressHome } from '../../../assets/images/address-home.svg';
import { ReactComponent as AddressWork } from '../../../assets/images/address-work.svg';
import { Dropdown } from 'primereact/dropdown';
import { getCountries } from '../../../api/commonAPIs';
import GoogleMap from '../../../components/generalMap/generalMap';
import sweetAlert from '../../../helpers/sweetAlert';
import getFormErrorMessage from '../../../components/formError/formError';
import GeneralMap from '../../../components/generalMap/generalMap';
import NoData from '../../../components/NoData/NoData';
import countryOptionTemplate from '../../../components/countryOptions/countryOptions';
import selectedCountryTemplate from '../../../components/selectedCountry/selectedCountry';
import './Addresses.css';
import VerifyPhoneModal from '../../../components/Modal/VerifyPhoneModal';
import useForceRerender from '../../../hooks/useForceRerender';

const Addresses = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        resetField,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            id: '',
            countryCode: { id: '', name: '', code: '', hintText: '', phoneLength: '' },
            addressLabel: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            addressDetails: '',
            otp: '',
            isDefault: false,
        },
    });
    const [rerender, forceRerender] = useForceRerender();
    const [toggleFirstModal, setToggleFirstModal] = useState(false);
    const [toggleSecondModal, setToggleSecondModal] = useState(false);
    const [toggleOtpModal, setToggleOtpModal] = useState(false);
    const [otpInfo, setOtpInfo] = useState({ otp: '', key: '', countryId: '', addressId: '' });
    const [editMode, setEditMode] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [countriesList, setCountreisList] = useState([]);
    const [pin, setPin] = useState({ lat: 0, lng: 0 });

    const watchedData = watch();

    function loadGoogleMapComponent() {
        return <GoogleMap center={{ lat: pin.lat, lng: pin.lng }} />;
    }

    function getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPin({ lat: position.coords.latitude, lng: position.coords.longitude });
            });
        }
    }

    function handleNext() {
        setToggleFirstModal(false);
        setToggleSecondModal(true);
    }

    function handleBack() {
        setToggleFirstModal(true);
        setToggleSecondModal(false);
    }

    function handleReset() {
        setToggleFirstModal(false);
        setToggleSecondModal(false);
        setEditMode(false);
        setToggleOtpModal(false);
        setOtpInfo({ otp: '', key: '', countryId: '', addressId: '' });
        reset({
            firstName: '',
            lastName: '',
            addressDetails: '',
            addressLabel: '',
            phoneNumber: '',
            otp: '',
            countryCode: {
                id: countriesList?.[0]?.id,
                name: countriesList?.[0]?.phoneCode,
                code: countriesList?.[0]?.twoLetterCode,
                hintText: countriesList?.[0]?.hintText,
                phoneLength: countriesList?.[0]?.phoneLength,
            },
        });
        getCurrentLocation();
    }

    async function verifyPhoneHandler(address) {
        dispatch(showLoader());

        const formData = {
            addressId: address?.id,
            isResend: false,
        };

        try {
            const { data } = await SendSmsOtp(formData);
            sweetAlert.success(t('phone_number_otp_send'));
            setToggleOtpModal(true);
            setOtpInfo({
                otp: '',
                key: data?.data?.key,
                countryId: address?.country?.id,
                addressId: address?.id,
            });
            resetField('otp');
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function changeDefaultAddress(address) {
        dispatch(showLoader());

        const formData = {
            id: address?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            countryId: address?.country?.id,
            addressTypeId: address?.addressType?.id,
            phoneNumber: address?.phone,
            additionalNotes: address?.additionalNotes,
            addressLineAr: address?.addressLine,
            addressLineEn: address?.addressLine,
            latitude: address?.latitude,
            longitude: address?.longitude,
            isDefault: true,
        };

        try {
            await updateCustomerAddress(formData);
            sweetAlert.success(t('default_address_updated_successfully'));
            getAddresses();
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function getCountriesList() {
        dispatch(showLoader());

        try {
            const { data } = await getCountries(true);
            setCountreisList(data?.data);
            setValue('countryCode', {
                id: data?.data[0]?.id,
                name: data?.data[0]?.phoneCode,
                code: data?.data[0]?.twoLetterCode,
                hintText: data?.data[0]?.hintText,
                phoneLength: data?.data[0]?.phoneLength,
            });
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function getAddresses() {
        dispatch(showLoader());
        try {
            const { data } = await getCustomerAddress();
            setAddresses(data?.data?.list);
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function addAddress() {
        const formData = {
            firstName: watchedData?.firstName || '',
            lastName: watchedData?.lastName || '',
            countryId: watchedData?.countryCode?.id || '',
            addressTypeId: watchedData?.addressLabel?.toString() || '',
            phoneNumber: watchedData?.phoneNumber || '',
            additionalNotes: watchedData?.addressDetails || '',
            addressLineAr: watchedData?.addressDetails || '',
            addressLineEn: watchedData?.addressDetails || '',
            latitude: pin.lat || '',
            longitude: pin.lng || '',
            isDefault: addresses?.length === 0 ? true : false,
        };

        dispatch(showLoader());

        try {
            await addCustomerAddress(formData);
            sweetAlert.success(t('added_successfully'));
            handleReset();
            getAddresses();
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function editAddress() {
        const formData = {
            id: watchedData?.id || '',
            firstName: watchedData?.firstName || '',
            lastName: watchedData?.lastName || '',
            countryId: watchedData?.countryCode?.id || '',
            addressTypeId: watchedData?.addressLabel?.toString() || '',
            phoneNumber: watchedData?.phoneNumber || '',
            additionalNotes: watchedData?.addressDetails || '',
            addressLineAr: watchedData?.addressDetails || '',
            addressLineEn: watchedData?.addressDetails || '',
            isDefault: watchedData?.isDefault || false,
            latitude: pin.lat || '',
            longitude: pin.lng || '',
        };

        dispatch(showLoader());

        try {
            await updateCustomerAddress(formData);
            sweetAlert.success(t('address_updated_successfully'));
            handleReset();
            getAddresses();
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function editHandler(address) {
        const countryCodeInfo = countriesList.find(
            (country) => country?.id === address?.country?.id,
        );
        setEditMode(true);
        setPin({ lat: address?.latitude, lng: address?.longitude });
        reset({
            id: address?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            addressDetails: address?.addressLine,
            addressLabel: address?.addressType?.id,
            phoneNumber: address?.phone,
            isDefault: address?.isDefault,
            countryCode: {
                id: countryCodeInfo?.id,
                name: countryCodeInfo?.phoneCode,
                code: countryCodeInfo?.twoLetterCode,
                hintText: countryCodeInfo?.hintText,
                phoneLength: countryCodeInfo?.phoneLength,
            },
        });
        setToggleFirstModal(true);
    }

    async function deleteHandler(address) {
        dispatch(showLoader());

        try {
            await deleteCustomerAddress(address?.id);
            sweetAlert.success(t('address_deleted_successfully'));
            getAddresses();
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getAddresses();
        getCountriesList();
        getCurrentLocation();
        loadGoogleMapComponent();

        return () => {};
    }, [rerender]);

    return (
        <>
            <div className="tab-title">
                <div className="tab-header">
                    <h3>{t('addresses')}</h3>
                    <button className="main-btn" onClick={() => setToggleFirstModal(true)}>
                        {t('add')}
                    </button>
                    <p>{t('add_new_address_description')}</p>
                </div>

                {addresses?.length ? (
                    <>
                        {(addresses?.filter((address) => address?.isDefault === true)?.length && (
                            <div className="default-address">
                                <h3>{t('default_address')}</h3>
                                {addresses?.filter((address) => address?.isDefault === true)
                                    ?.length &&
                                    addresses
                                        ?.filter((address) => address?.isDefault === true)
                                        ?.map((address, index) => (
                                            <div className="address-item flex-wrap" key={index}>
                                                <div className="address-item-body">
                                                    <h4>
                                                        {address?.firstName} {address?.lastName}
                                                    </h4>
                                                    <p>{address?.addressLine}</p>
                                                    <p>
                                                        {address?.phone}
                                                        <span>
                                                            {address?.phoneVerified ? (
                                                                <span className="verified mx-2">
                                                                    {t('verified')}
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="main-color mx-2"
                                                                    onClick={() =>
                                                                        verifyPhoneHandler(address)
                                                                    }>
                                                                    {t('not_verified')}
                                                                </span>
                                                            )}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="address-item-actions">
                                                    <div className="address-item-header-btns flex align-items-center justify-content-end gap-3 flex-wrap">
                                                        <button
                                                            disabled
                                                            className="text-btn min-w-0 text-color"
                                                            onClick={() => {
                                                                deleteHandler(address);
                                                            }}>
                                                            {t('delete')}
                                                        </button>
                                                        <button
                                                            className="text-btn min-w-0 main-color"
                                                            onClick={() => {
                                                                editHandler(address);
                                                            }}>
                                                            {t('edit')}
                                                        </button>
                                                        <div className="inline-flex align-items-center gap-3">
                                                            <span className="text-color">
                                                                {t('default_address')}
                                                            </span>
                                                            <InputSwitch
                                                                checked={address?.isDefault}
                                                                disabled
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                            </div>
                        )) ||
                            ''}
                        {(addresses?.filter((address) => address?.isDefault === false)?.length && (
                            <div className="other-address">
                                <h3>{t('other_address')}</h3>
                                {addresses
                                    ?.filter((address) => address?.isDefault === false)
                                    ?.map((address, index) => (
                                        <div className="address-item flex-wrap" key={index}>
                                            <div className="address-item-body">
                                                <h3>
                                                    {address?.firstName} {address?.lastName}
                                                </h3>
                                                <p>{address?.addressLine}</p>
                                                <p>
                                                    {address?.phone}
                                                    <span>
                                                        {address?.phoneVerified ? (
                                                            <span className="verified mx-3">
                                                                {t('verified')}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="main-color mx-3"
                                                                onClick={() =>
                                                                    verifyPhoneHandler(address)
                                                                }>
                                                                {t('not_verified')}
                                                            </span>
                                                        )}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="address-item-actions">
                                                <div className="address-item-header-btns flex align-items-center justify-content-end gap-3 flex-wrap">
                                                    <button
                                                        className="text-btn min-w-0 text-color"
                                                        onClick={() => {
                                                            deleteHandler(address);
                                                        }}>
                                                        {t('delete')}
                                                    </button>
                                                    <button
                                                        className="text-btn min-w-0 main-color"
                                                        onClick={() => {
                                                            editHandler(address);
                                                        }}>
                                                        {t('edit')}
                                                    </button>
                                                    <div className="inline-flex align-items-center gap-3">
                                                        <span className="text-color">
                                                            {t('default_address')}
                                                        </span>
                                                        <InputSwitch
                                                            checked={address?.isDefault}
                                                            onChange={() => {
                                                                changeDefaultAddress(address);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )) ||
                            ''}
                    </>
                ) : (
                    <NoData productShape={true} />
                )}

                <Dialog
                    header={<Back className="back-btn" onClick={handleReset} />}
                    visible={toggleFirstModal}
                    onHide={handleReset}
                    draggable={false}
                    resizable={false}
                    style={{ width: '65vw', maxWidth: '750px' }}>
                    <div className="confirm-popup-body">
                        <div className="flex align-items-center justify-content-between mb-3">
                            <h3>{t('add_new_address')}</h3>
                        </div>
                        <div className="map-info">
                            <GeneralMap editable={true} pin={pin} setPin={setPin} />
                            <button className="main-btn next-step-btn" onClick={handleNext}>
                                {t('confirm')}
                                <Arrow />
                            </button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header={<Back className="back-btn" onClick={handleBack} />}
                    visible={toggleSecondModal}
                    onHide={handleReset}
                    draggable={false}
                    resizable={false}
                    style={{ width: '65vw', maxWidth: '750px' }}>
                    <div className="confirm-popup-body">
                        <div className="flex align-items-center justify-content-between mb-3">
                            <h3>{t('add_new_address')}</h3>
                        </div>
                        <form
                            onSubmit={
                                editMode ? handleSubmit(editAddress) : handleSubmit(addAddress)
                            }>
                            <h3>{t('location_info')}</h3>

                            <div className="map-info">
                                <GeneralMap editable={false} pin={pin} />
                                <button type="button" className="edit-map-btn" onClick={handleBack}>
                                    {t('edit')}
                                </button>
                            </div>

                            <h3>{t('additional_info')}</h3>

                            <div className="p-field">
                                <label htmlFor="addressDetails" className="p-d-block">
                                    {t('address_details')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="p-float-label">
                                    <Controller
                                        name="addressDetails"
                                        control={control}
                                        rules={{
                                            required: t('address_details_required'),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                placeholder={t('address_details_placeholder')}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors, 'addressDetails')}
                            </div>
                            <div className="p-field checkbox-field">
                                <label htmlFor="addressLabel" className="p-d-block">
                                    {t('address_label')}
                                </label>
                                <Controller
                                    name="addressLabel"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <div className="flex align-items-center gap-4">
                                            <div className="flex align-items-center">
                                                <RadioButton
                                                    inputId="addressLabel"
                                                    {...field}
                                                    className={classNames({
                                                        'p-invalid': fieldState.invalid,
                                                    })}
                                                    value={0}
                                                    checked={field.value === 0}
                                                />
                                                <label
                                                    className="mx-2 my-0 flex align-items-center gap-2"
                                                    htmlFor="Home">
                                                    {t('address_home')} <AddressHome />{' '}
                                                </label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <RadioButton
                                                    inputId="addressLabel"
                                                    {...field}
                                                    className={classNames({
                                                        'p-invalid': fieldState.invalid,
                                                    })}
                                                    value={1}
                                                    checked={field.value === 1}
                                                />
                                                <label
                                                    className="mx-2 my-0 flex align-items-center gap-2"
                                                    htmlFor="Work">
                                                    {t('address_work')} <AddressWork />{' '}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {getFormErrorMessage(errors, 'addressLabel')}
                            </div>

                            <h3>{t('personal_info')}</h3>

                            <div className="p-field">
                                <label htmlFor="firstName" className="p-d-block">
                                    {t('first_name')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="p-float-label">
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        rules={{
                                            required: t('first_name_required'),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                placeholder={t('first_name_placeholder')}
                                                keyfilter={/^[a-zA-Z\u0600-\u06FF\s]+$/}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors, 'firstName')}
                            </div>
                            <div className="p-field">
                                <label htmlFor="lastName" className="p-d-block">
                                    {t('last_name')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="p-float-label">
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        rules={{
                                            required: t('last_name_required'),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                placeholder={t('last_name_placeholder')}
                                                keyfilter={/^[a-zA-Z\u0600-\u06FF\s]+$/}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors, 'lastName')}
                            </div>
                            <div className="p-field phone-num">
                                <label
                                    htmlFor="countryCode"
                                    className={classNames({ 'p-error': errors.countryCode })}>
                                    {t('phone_number')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <div className="phone-fields">
                                    <div className="field">
                                        <Controller
                                            name="countryCode"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                            }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        resetField('phoneNumber');
                                                    }}
                                                    options={countriesList.map((country) => ({
                                                        id: country?.id,
                                                        name: country?.phoneCode,
                                                        code: country?.twoLetterCode,
                                                        hintText: country?.hintText,
                                                        phoneLength: country?.phoneLength,
                                                    }))}
                                                    placeholder={t('country_code')}
                                                    optionLabel="code"
                                                    filter
                                                    valueTemplate={selectedCountryTemplate}
                                                    itemTemplate={countryOptionTemplate}
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage('phoneCode')}
                                    </div>

                                    <div className="field w-full">
                                        <span className="phone_num">
                                            <Controller
                                                name="phoneNumber"
                                                control={control}
                                                rules={{
                                                    required: t('this_field_is_required'),
                                                    minLength: {
                                                        value: watchedData?.countryCode
                                                            ?.phoneLength,
                                                        message: t('phone_number_min_length', {
                                                            length:
                                                                watchedData?.countryCode
                                                                    ?.phoneLength || 0,
                                                        }),
                                                    },
                                                    maxLength: {
                                                        value: watchedData?.countryCode
                                                            ?.phoneLength,
                                                        message: t('phone_number_max_length', {
                                                            length:
                                                                watchedData?.countryCode
                                                                    ?.phoneLength || 15,
                                                        }),
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        {...field}
                                                        id={field.name}
                                                        value={field.value || ''}
                                                        onChange={(e) =>
                                                            field.onChange(e.target.value)
                                                        }
                                                        className={classNames({
                                                            'p-invalid': fieldState.error,
                                                        })}
                                                        placeholder={
                                                            watchedData?.countryCode?.hintText ||
                                                            t('phone_number_placeholder')
                                                        }
                                                        maxLength={
                                                            watchedData?.countryCode?.phoneLength
                                                        }
                                                        keyfilter="pint"
                                                    />
                                                )}
                                            />
                                        </span>
                                        {getFormErrorMessage('phoneNumber')}
                                    </div>
                                </div>
                            </div>

                            <div className="form-submit">
                                <button type="submit" className="main-btn">
                                    {t('save')}
                                </button>
                                <button type="button" className="second-btn" onClick={handleReset}>
                                    {t('cancel')}
                                </button>
                            </div>
                        </form>
                    </div>
                </Dialog>

                <VerifyPhoneModal
                    toggleOtpModal={toggleOtpModal}
                    setToggleOtpModal={setToggleOtpModal}
                    otpInfo={otpInfo}
                    setOtpInfo={setOtpInfo}
                    control={control}
                    reset={reset}
                    resetField={resetField}
                    isDirty={isDirty}
                    isValid={isValid}
                    forceRerender={forceRerender}
                />
            </div>
        </>
    );
};

export default Addresses;
