import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    userId: '',
    firstName: '',
    lastName: '',
    phone: '',
    emailVerified: false,
    phoneVerified: false,
    country: {},
    token: null,
    userType: '',
    authType: '',
    isLogged: false,
    apiKey: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginAction: (state, action) => {
            state.email = action.payload.email;
            state.userId = action.payload.userId;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.phone = action.payload.phone;
            state.country = action.payload.country;
            state.emailVerified = action.payload.emailVerified;
            state.phoneVerified = action.payload.phoneVerified;
            state.token = action.payload.token;
            state.userType = action.payload.userType;
            state.authType = action.payload.authType;
            state.isLogged = true;

            localStorage.setItem('userData', JSON.stringify(action.payload));
            localStorage.removeItem('compareList');
            localStorage.removeItem('wishlist');
            localStorage.removeItem('cart');
        },

        updateUserTypeToVendor: (state, action) => {
            state.userType = 1;
            localStorage.setItem('userData', JSON.stringify({ ...JSON.parse(localStorage.getItem('userData')), userType: 1 }));
        },

        logoutAction: (state) => {
            state.email = '';
            state.userId = '';
            state.firstName = '';
            state.lastName = '';
            state.phone = '';
            state.emailVerified = false;
            state.phoneVerified = false;
            state.country = {};
            state.token = null;
            state.userType = '';
            state.authType = '';
            state.isLogged = false;

            localStorage.removeItem('userData');
            localStorage.removeItem('compareList');
            localStorage.removeItem('wishlist');
            localStorage.removeItem('cart');
        },

        saveDataFromLocalStorage: (state, action) => {
            state.email = action.payload.email;
            state.userId = action.payload.userId;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.phone = action.payload.phone;
            state.emailVerified = action.payload.emailVerified;
            state.phoneVerified = action.payload.phoneVerified;
            state.country = action.payload.country;
            state.token = action.payload.token;
            state.userType = action.payload.userType;
            state.authType = action.payload.authType;
            state.isLogged = true;
        },

        setApiKeyAction: (state, action) => {
            state.apiKey = action.payload;
            localStorage.setItem('apiKey', action.payload);
        },
    },
});

// Action creators are generated for each case reducer function
export const { loginAction, logoutAction, saveDataFromLocalStorage, setApiKeyAction, updateUserTypeToVendor } = authSlice.actions;

export default authSlice.reducer;
