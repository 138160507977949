import React, { useEffect } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

export default function CountDownOTP({ countdown, startCountdown = () => {} }) {
    const { t } = useTranslation();

    const Completionist = () => <p className="resend-title">{t('enable_resend')}</p>;
    const renderer = ({ seconds, completed }) => {
        if (completed) return <Completionist />;
        else
            return (
                <p className="resend-title">
                    {t('resend_in')} <span>00:{seconds}</span>
                </p>
            );
    };

    useEffect(() => {
        startCountdown();
    }, []);

    return (
        <>
            <Countdown date={Date.now() + countdown * 1000} renderer={renderer} />
        </>
    );
}
