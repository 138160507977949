import { baseInstance } from './baseInstance';

export const getCategoriesAPI = (data) =>
    baseInstance.post('/Catalog/CustomHomePageCategories', data);

export const getSubCategoriesAPI = (data) =>
    baseInstance.post('/Catalog/CustomSubCategories', data);

export const getCategoryProducts = (data) => baseInstance.post(`/Product/GetProducts`, data);

export const getDiscountTags = () => baseInstance.get(`/Product/GetAllDiscountTags`);

export const getCategoriesAttributes = (CategoryId) =>
    baseInstance.get(`/Product/GetAttributesByCategoryId?CategoryId=${CategoryId}`);
