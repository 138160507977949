import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAppSettings } from '../../api/commonAPIs';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import parse from 'html-react-parser';
import './UsefulLinks.css';
import NoData from '../../components/NoData/NoData';

export default function TermsAndCondition() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [termsAndConditions, setTermsAndConditions] = useState('');

    async function getTermsAndConditions() {
        dispatch(showLoader());
        try {
            const { data } = await getAppSettings();
            const termsData = data?.data?.find((item) => item?.key === 'WebTermsAndConditions');
            setTermsAndConditions(termsData?.value);
        } catch (error) {
            console.error('Error fetching terms and conditions', error);
        } finally {
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        getTermsAndConditions();
    }, []);

    return (
        <section className="termsAndConditions basic-info-bx mb_20">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="basic-info-bx-inner">
                            <h2 className="main-heading">{t('terms_and_conditions')}</h2>
                            <div className="order-list">
                                {termsAndConditions ? (
                                    <>{parse(`${termsAndConditions}`)}</>
                                ) : (
                                    <NoData />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
