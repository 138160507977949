import React from 'react';
import { useTranslation } from 'react-i18next';
import CartProduct from '../../components/Cart/CartProduct';

const ItemsList = ({ list = {}, forceRerender = () => {} }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="cart-items-list flex align-items-center gap-2">
                <h2>{t('orderDetails')}</h2>
            </div>
            <div className="items-list card-border">
                <ul className="grid">
                    {list?.map((item, index) => (
                        <li key={index} className="col-12">
                            <CartProduct item={item} forceRerender={forceRerender} />
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default ItemsList;
