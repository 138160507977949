import React, { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { CiSearch } from 'react-icons/ci';

const DropDownList = ({ PlaceholderText, value = '', setValue = () => {} }) => {
    const [items, setItems] = useState([]);

    const search = (event) => {
        setItems([...Array(10).keys()].map((item) => event.query + '-' + item));
    };

    return (
        <span className="p-input-icon-right">
            {/* <i className="pi pi-search" /> */}
            <span className="search-icon">
                <CiSearch />
            </span>
            <AutoComplete
                value={value}
                suggestions={items}
                // completeMethod={search}
                onChange={(e) => setValue(e.value)}
                placeholder={PlaceholderText}
                // virtualScrollerOptions={{ itemSize: 38 }}
            />
        </span>
    );
};

export default DropDownList;
