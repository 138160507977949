import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCategoryProducts, getSubCategoriesAPI } from '../../api/categoriseAPIs';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Filter from './Filter';
import ProductsList from './ProductsList';
import SubCutgSlider from './SubCutgSlider';
import '../../assets/css/pages/category.css';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { getSortingList } from '../../api/productAPIs';
import useForceRerender from '../../hooks/useForceRerender';

const Category = () => {
    const { name: categoryName, id } = useParams();
    const dispatch = useDispatch();
    const [rerender, forceRerender] = useForceRerender();

    const [filterResult, setFilterResult] = useState({});

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(20);

    const [selectedSort, setSelectedSort] = useState(null);
    const [sortingList, setSortingList] = useState([]);

    const [categoryProducts, setCategoryProducts] = useState([]);

    const [subCategoryList, setSubCategoryList] = useState([]);
    const [paging, setPaging] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [categoryId, setCategoryId] = useState(id || '');
    const [subCategoryId, setSubCategoryId] = useState('');

    const onPageChange = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    useEffect(() => {
        getSubCategoriesAPI({
            paging: {
                PageNumber: 0,
                PageSize: 20,
            },
            data: {
                categoryId: id,
            },
        })
            .then((res) => {
                setSubCategoryList(res.data.data.list);
                setPaging(res.data.data.paging);
            })
            .catch((error) => {})
            .finally(() => {
                setLoaded(true);
            });
    }, [id]);

    async function getProductsHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getCategoryProducts({
                catalogID: subCategoryId ? subCategoryId : id,
                sortingId: selectedSort?.id,
                ...filterResult,
                paging: { PageNumber: page, PageSize: 20, TotalItems: 0 },
            });
            setCategoryProducts(data?.data?.list);
            setPaging(data?.data?.paging);
        } catch (error) {
            console.log(error);
        }

        try {
            const { data } = await getSortingList();
            setSortingList(data?.data);
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    useEffect(() => {
        getProductsHandler();
    }, [first, id, categoryId, subCategoryId, page, rows, selectedSort?.id, rerender]);

    useEffect(() => {
        if (id) {
            setCategoryId(id);
        }
    }, [id]);

    return (
        <>
            <section className="category-section container_bx">
                <Breadcrumb itemName={categoryName} />

                <SubCutgSlider
                    setCategoryId={setSubCategoryId}
                    subCategoryList={subCategoryList}
                    loaded={loaded}
                />

                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <Filter
                            filterResult={filterResult}
                            setFilterResult={setFilterResult}
                            forceRerender={forceRerender}
                            categoryId={categoryId}
                            subCategoryId={subCategoryId}
                        />
                    </div>
                    <div className="col-12 md:col-9 lg:col-9">
                        <ProductsList
                            categoryProducts={categoryProducts}
                            page={page}
                            paging={paging}
                            first={first}
                            rows={rows}
                            sortingList={sortingList}
                            selectedSort={selectedSort}
                            setSelectedSort={setSelectedSort}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Category;
