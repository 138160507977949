import { initializeApp } from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider,
    OAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import { t } from "i18next";
import { loginAction } from '../redux/authSlice';
import { useNavigate } from "react-router";
import { store } from "../redux/store";
import { loginSocialMediaAPI } from "../api/authAPIs";
import sweetAlert from "../helpers/sweetAlert";
import Cookies from "js-cookie";

const firebaseConfig = {
    apiKey: "AIzaSyBmoKsmgZhRx50_hXvAg3buhHHxy03_Sv0",
    authDomain: "aswaq-d9173.firebaseapp.com",
    projectId: "aswaq-d9173",
    storageBucket: "aswaq-d9173.appspot.com",
    messagingSenderId: "869336318227",
    appId: "1:869336318227:web:2418e815803a788cb15ae6",
    measurementId: "G-S9275RGYQP"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const Gprovider = new GoogleAuthProvider();
export const FBprovider = new FacebookAuthProvider();
export const Twtrprovider = new TwitterAuthProvider();
export const Appleprovider = new OAuthProvider('apple.com');
export const auth = getAuth(app);

const socialLoginProviders = {
    Facebook: {
        app: FBprovider,
        class: FacebookAuthProvider,
    },
    Gmail: {
        app: Gprovider,
        class: GoogleAuthProvider,
    },
    Twitter: {
        app: Twtrprovider,
        class: TwitterAuthProvider,
    },
    Apple: {
        app: Appleprovider,
        class: OAuthProvider,
    },
};

export const useSocialLogin = () => {
    if (Cookies.get('i18next') === "en") {
        auth.languageCode = "en";
    } else {
        auth.languageCode = "ar";
    }

    const navigate = useNavigate();
    return (platform, authType) => {
        if (!Object.keys(socialLoginProviders).includes(platform)) {
            throw Error(platform + " is not existed in the available platforms");
        }
        // add scope for apple
        if (platform === "Apple") {
            socialLoginProviders[platform]?.app?.addScope('email');
            socialLoginProviders[platform]?.app?.addScope('name');
        }
        signInWithPopup(auth, socialLoginProviders[platform].app).then((result) => {
            const credential = socialLoginProviders[platform].class.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            return user;
        }).then(async (user) => {
            const loginData = {
                model: {
                    firstName: user?.displayName?.split(' ')?.[0] || "",
                    lastName: user?.displayName?.split(' ')?.[1] || "",
                    email: user?.email || "",
                    uid: user?.uid || "",
                    tokenCode: user?.accessToken || "",
                    authType: authType,
                }
            };
            const { data } = await loginSocialMediaAPI(loginData);
            return data;
        }).then((data) => {
            if (data.statusMessage === "OK" && data.statusCode === 200) {
                sweetAlert.success(t("loginSuccess"));
                store.dispatch(loginAction(data.data));
                navigate("/");
            }
        }).catch((error) => {
            console.log("error", error)
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error?.response?.data?.statusMessage || t("loginFailed");
            // The email of the user's account used.
            if (errorCode === "auth/account-exists-with-different-credential") sweetAlert.error("You have logged In with different provider")
            else sweetAlert.error(errorMessage);
        });
    };
};