import { GoogleMap } from '@react-google-maps/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddressHome } from '../../assets/images/address-home.svg';
import { ReactComponent as AddressWork } from '../../assets/images/address-work.svg';
import { ReactComponent as Back } from '../../assets/images/back.svg';
import { ReactComponent as Arrow } from '../../assets/images/btn-arrow.svg';
import countryOptionTemplate from '../countryOptions/countryOptions';
import getFormErrorMessage from '../formError/formError';
import GeneralMap from '../generalMap/generalMap';
import selectedCountryTemplate from '../selectedCountry/selectedCountry';

export default function AddressModal({
    toggleFirstModal,
    setToggleFirstModal = () => {},
    toggleSecondModal,
    setToggleSecondModal = () => {},
    handleSubmit = () => {},
    control,
    errors = {},
    watchedData,

    resetField = () => {},
    countriesList = [],
    editMode = false,
    addAddress = () => {},
    editAddress = () => {},
    handleReset = () => {},
    pin = { lat: 31.1999679, lng: 29.9186483 },
    setPin = () => {},
}) {
    const { t } = useTranslation();

    function loadGoogleMapComponent() {
        return <GoogleMap center={{ lat: pin.lat, lng: pin.lng }} />;
    }

    function getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPin({ lat: position.coords.latitude, lng: position.coords.longitude });
            });
        }
    }

    function handleNext() {
        setToggleFirstModal(false);
        setToggleSecondModal(true);
    }

    function handleBack() {
        setToggleFirstModal(true);
        setToggleSecondModal(false);
    }

    useEffect(() => {
        getCurrentLocation();
        loadGoogleMapComponent();

        return () => {};
    }, []);

    return (
        <>
            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={toggleFirstModal}
                onHide={handleReset}
                style={{ width: '65vw', maxWidth: '600px' }}
                draggable={false}
                resizable={false}
                className="add-address__modal">
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-between mb-3">
                        <h3>{t('add_new_address')}</h3>
                    </div>
                    <div className="map-info">
                        <GeneralMap editable={true} pin={pin} setPin={setPin} />
                        <button className="main-btn next-step" onClick={handleNext}>
                            {t('confirm')}
                            <Arrow />
                        </button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                header={<Back className="back-btn" onClick={handleBack} />}
                visible={toggleSecondModal}
                onHide={handleReset}
                style={{ width: '50vw', maxWidth: '600px' }}
                draggable={false}
                resizable={false}
                className="add-address__modal">
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-between mb-3">
                        <h3>{t('add_new_address')}</h3>
                    </div>
                    <form
                        onSubmit={editMode ? handleSubmit(editAddress) : handleSubmit(addAddress)}>
                        <h3>{t('location_info')}</h3>

                        <div className="map-info">
                            <GeneralMap editable={false} pin={pin} />
                            <button type="button" className="edit-map-btn" onClick={handleBack}>
                                {t('edit')}
                            </button>
                        </div>

                        <h3>{t('additional_info')}</h3>

                        <div className="p-field">
                            <label htmlFor="addressDetails" className="p-d-block">
                                {t('address_details')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <span className="p-float-label">
                                <Controller
                                    name="addressDetails"
                                    control={control}
                                    rules={{
                                        required: t('address_details_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                'p-invalid': fieldState.invalid,
                                            })}
                                            placeholder={t('address_details_placeholder')}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage(errors, 'addressDetails')}
                        </div>
                        <div className="p-field checkbox-field">
                            <label htmlFor="addressLabel" className="p-d-block">
                                {t('address_label')}
                            </label>
                            <Controller
                                name="addressLabel"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <div className="flex align-items-center gap-4">
                                        <div className="flex align-items-center">
                                            <RadioButton
                                                inputId="addressLabel"
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                value={0}
                                                checked={field.value === 0}
                                            />
                                            <label
                                                className="mx-2 my-0 flex align-items-center gap-2"
                                                htmlFor="Home">
                                                {t('address_home')} <AddressHome />{' '}
                                            </label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton
                                                inputId="addressLabel"
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.invalid,
                                                })}
                                                value={1}
                                                checked={field.value === 1}
                                            />
                                            <label
                                                className="mx-2 my-0 flex align-items-center gap-2"
                                                htmlFor="Work">
                                                {t('address_work')} <AddressWork />{' '}
                                            </label>
                                        </div>
                                    </div>
                                )}
                            />
                            {getFormErrorMessage(errors, 'addressLabel')}
                        </div>

                        <h3>{t('personal_info')}</h3>

                        <div className="p-field">
                            <label htmlFor="firstName" className="p-d-block">
                                {t('first_name')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <span className="p-float-label">
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{
                                        required: t('first_name_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                'p-invalid': fieldState.invalid,
                                            })}
                                            placeholder={t('first_name_placeholder')}
                                            keyfilter={/^[a-zA-Z\u0600-\u06FF\s]+$/}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage(errors, 'firstName')}
                        </div>
                        <div className="p-field">
                            <label htmlFor="lastName" className="p-d-block">
                                {t('last_name')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <span className="p-float-label">
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: t('last_name_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                'p-invalid': fieldState.invalid,
                                            })}
                                            placeholder={t('last_name_placeholder')}
                                            keyfilter={/^[a-zA-Z\u0600-\u06FF\s]+$/}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage(errors, 'lastName')}
                        </div>
                        <div className="p-field phone-num">
                            <label
                                htmlFor="countryCode"
                                className={classNames({ 'p-error': errors.countryCode })}>
                                {t('phone_number')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <div className="phone-fields">
                                <div className="field">
                                    <Controller
                                        name="countryCode"
                                        control={control}
                                        rules={{
                                            required: t('this_field_is_required'),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                    resetField('phoneNumber');
                                                }}
                                                options={countriesList.map((country) => ({
                                                    id: country?.id,
                                                    name: country?.phoneCode,
                                                    code: country?.twoLetterCode,
                                                    hintText: country?.hintText,
                                                    phoneLength: country?.phoneLength,
                                                }))}
                                                placeholder={t('country_code')}
                                                optionLabel="code"
                                                filter
                                                valueTemplate={selectedCountryTemplate}
                                                itemTemplate={countryOptionTemplate}
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage('phoneCode')}
                                </div>

                                <div className="field w-full">
                                    <span className="phone_num">
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                minLength: {
                                                    value: watchedData?.countryCode?.phoneLength,
                                                    message: t('phone_number_min_length', {
                                                        length:
                                                            watchedData?.countryCode?.phoneLength ||
                                                            0,
                                                    }),
                                                },
                                                maxLength: {
                                                    value: watchedData?.countryCode?.phoneLength,
                                                    message: t('phone_number_max_length', {
                                                        length:
                                                            watchedData?.countryCode?.phoneLength ||
                                                            15,
                                                    }),
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    id={field.name}
                                                    value={field.value || ''}
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                    className={classNames({
                                                        'p-invalid': fieldState.error,
                                                    })}
                                                    placeholder={
                                                        watchedData?.countryCode?.hintText ||
                                                        t('phone_number_placeholder')
                                                    }
                                                    maxLength={
                                                        watchedData?.countryCode?.phoneLength
                                                    }
                                                    keyfilter="pint"
                                                />
                                            )}
                                        />
                                    </span>
                                    {getFormErrorMessage('phoneNumber')}
                                </div>
                            </div>
                        </div>

                        <div className="form-submit">
                            <button type="submit" className="main-btn">
                                {t('save')}
                            </button>
                            <button type="button" className="second-btn" onClick={handleReset}>
                                {t('cancel')}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
}
