import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import countryOptionTemplate from '../countryOptions/countryOptions';
import selectedCountryTemplate from '../selectedCountry/selectedCountry';
import '../../assets/css/shared/flags.css';

const countries = [
    { name: 'saudiArabia', code: 'SA' },
    { name: 'Egypt', code: 'EG' },
];

const LanguageSelect = () => {
    const { t } = useTranslation();

    const [selectedCountry, setSelectedCountry] = useState(countries[0] || null);

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3 select-footer">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };

    return (
        <div className="lang-bx">
            <Dropdown
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.value)}
                options={countries}
                optionLabel="name"
                placeholder={t('Select_a_Country')}
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="select-lang-dropdown hidden"
                panelFooterTemplate={panelFooterTemplate}
            />
            <div className="py-2 px-3 select-footer">
                {selectedCountry ? (
                    <div className="flex align-items-center">
                        <img
                            alt={selectedCountry?.name || '00'}
                            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                            className={`flag flag-${selectedCountry?.code?.toLowerCase()}`}
                            style={{ width: '18px', marginInlineEnd: '8px' }}
                        />
                        <div style={{ color: 'white' }}>{`${
                            t(`${selectedCountry?.name}`) || '00'
                        }`}</div>
                    </div>
                ) : (
                    'No country selected.'
                )}
            </div>
        </div>
    );
};

export default LanguageSelect;
