import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import DropDown from '../dropDown/DropDown';
import LogOut from '../logout/LogOut';
import logo from '../../assets/images/logo-EN.png';
import user from '../../assets/images/user_outline.svg';
import alert from '../../assets/images/alert.svg';
import logoutPower from '../../assets/images/power.svg';
import '../../assets/css/shared/main-header.css';
import MainSearch from '../MainSearch/MainSearch';

const MainHeader = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { firstName, lastName, isLogged } = useSelector((state) => state.authReducer);

    const fireLoader = () => {
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 700);
    };

    return (
        <div className="container_bx">
            <div className="main-header">
                <div className="logo-bx">
                    <Link to="/">
                        <img src={logo} alt="pic" />
                    </Link>
                </div>

                <div className="header-search-bx">
                    <MainSearch />
                </div>

                <div className="user-links">
                    <div className="user-link-item hidden">
                        <Link to="/notification" onClick={fireLoader}>
                            <img src={alert} alt="pic" />
                            <span className="label hidden">3</span>
                        </Link>
                    </div>

                    <div className="user-link-item">
                        <DropDown
                            title={
                                <div className="account">
                                    <img src={user} alt="pic" />
                                    <span>
                                        {isLogged ? `${firstName} ${lastName}` : t('account')}
                                    </span>
                                </div>
                            }
                            content={
                                <ul className="account-dropdown-bx">
                                    {isLogged ? (
                                        <>
                                            <li className="drop-item">
                                                <Link to="/profile/info" onClick={fireLoader}>
                                                    <span>
                                                        <img
                                                            className="drop-power-icon"
                                                            src={user}
                                                            alt="pic"
                                                        />
                                                        {t('profile')}
                                                    </span>
                                                </Link>
                                            </li>

                                            <li onClick={fireLoader}>
                                                <LogOut
                                                    logoutContent={{
                                                        photo: (
                                                            <img
                                                                className="drop-power-icon"
                                                                src={logoutPower}
                                                                alt=""
                                                            />
                                                        ),
                                                        text: t('logout'),
                                                    }}
                                                />
                                            </li>
                                        </>
                                    ) : (
                                        <li>
                                            <Link
                                                className="color-link"
                                                to="/login"
                                                onClick={fireLoader}>
                                                {t('sign_in')}
                                            </Link>
                                            <div className="reg-text">
                                                <h3>{t('dont_have_account')}</h3>
                                                <p>{t('register_dropdown_text')}</p>
                                            </div>
                                            <Link
                                                className="color-link"
                                                to="/register"
                                                onClick={fireLoader}>
                                                {t('register')}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainHeader;
