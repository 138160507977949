import React, { useContext, useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { Steps } from 'primereact/steps';
import { FormStepContext } from '../../utils/contexts/FormStepContext';
import { STEPS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { getCities, getGovernorates } from '../../api/commonAPIs';
import { getSelectsData } from './initData';
import countryOptionTemplate from "../../components/countryOptions/countryOptions";
import selectedCountryTemplate from "../../components/selectedCountry/selectedCountry";
import buisness from '../../assets/images/vendor/wizard/business.svg';
import document from '../../assets/images/vendor/wizard/documents.svg';
import info from '../../assets/images/vendor/wizard/broduct-info.svg';
import arrow from '../../assets/images/btn-arrow.svg';

const StepOne = () => {
    const [activeIndex] = useState(0);
    const { t } = useTranslation();
    const [countriesList, setCountreisList] = useState([]);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [governatesList, setGovernatesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const items = [
        {
            label: (
                <div className='step-title'><img src={buisness} alt='pic' />{t("business_details")}</div>
            )
        },
        {
            label: (
                <div className='step-title'><img src={document} alt='pic' />{t("documents")}</div>
            )
        },
        {
            label: (
                <div className='step-title'><img src={info} alt='pic' /> {t("products_info")}</div>
            )
        }
    ];

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        resetField,
        watch
    } = useForm({ mode: 'onChange' });

    const countryId = watch('CountryID');
    const StateId = watch('StateId');
    const CountryCode = watch('CountryCode');

    const { setStep, updateFormState, formState } = useContext(FormStepContext)

    const onSubmit = (data) => {
        updateFormState(data);
        setStep(STEPS.DOCUMENT);
    };

    useEffect(() => {
        getSelectsData().then(res => {
            setCountreisList(res.countriesList)
            setBusinessTypes(res.businessTypes)
        })
    }, [])

    useEffect(() => {
        if (countryId) {
            getGovernorates(countryId.id).then(res => {
                setGovernatesList(res.data.data)
            })
            const country = countriesList.find(country => country?.id === countryId?.id)
            resetField("CountryCode", { defaultValue: { id: countryId?.id, name: country?.phoneCode, code: country?.twoLetterCode } })
            updateFormState({ ...formState, CountryCode: { id: countryId?.id, name: country?.phoneCode, code: country?.twoLetterCode } })
        }
    }, [countryId])

    useEffect(() => {
        if (StateId) {
            getCities(StateId.id).then(res => {
                setCitiesList(res.data.data)
            })
        }
    }, [StateId])

    useEffect(() => {
        reset({
            BusinessName: formState.BusinessName || "",
            BusinessNameAR: formState.BusinessNameAR || "",
            BusinessTypeId: formState.BusinessTypeId || "",
            BusinessAddress: formState.BusinessAddress || "",
            CountryID: formState.CountryID || "",
            StateId: formState.StateId || "",
            CityID: formState.CityID || "",
            PhoneNumber: formState.PhoneNumber || "",
            CountryCode: formState.CountryCode || "",
        })
    }, [activeIndex])

    useEffect(() => {
        if (formState?.CountryCode?.id && formState?.CountryID?.id) {
            const country = countriesList.find(country => country?.id === formState?.CountryID?.id)
            resetField("CountryCode", { defaultValue: { id: formState?.CountryID?.id, name: country?.phoneCode, code: country?.twoLetterCode } })

        }
    }, [formState?.CountryCode?.id, countriesList])

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error"></small>;
    };

    const selectedCountryTemplateVendor = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code?.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplateVendor = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code?.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{`${option.name}`}</div>
            </div>
        );
    };

    const selectedGovernorateTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const governorateOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    const selectedCityTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const cityOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <>
            <div className='steps-items'>
                <Steps model={items} activeIndex={activeIndex} readOnly={false} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='card-bx mt_40'>
                    <div className='field'>
                        <label htmlFor='BusinessName' className={classNames({ 'p-error': errors.BusinessName })}>
                            {t('business_name')}
                        </label>
                        <span className="">
                            <Controller
                                name="BusinessName"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    pattern: {
                                        value: /^[a-zA-Z]/i,
                                        message: t('invalid_business_name')
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} maxLength='100' placeholder={t('business_name_placeholder')} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />

                        </span>
                        {getFormErrorMessage('BusinessName')}
                    </div>

                    <div className='field'>
                        <label htmlFor='BusinessNameAR' className={classNames({ 'p-error': errors.BusinessNameAR })}>
                            {t('business_name_ar')}
                        </label>
                        <span className="">
                            <Controller
                                name="BusinessNameAR"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    pattern: {
                                        value: /^[أ-ي]/i,
                                        message: t('invalid_business_name_ar')
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} maxLength='100'
                                        placeholder={t('business_name_ar_placeholder')}
                                        className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />

                        </span>
                        {getFormErrorMessage('BusinessNameAR')}
                    </div>

                    <div className='field'>
                        <label htmlFor='BusinessTypeId' className={classNames({ 'p-error': errors.BusinessTypeId })}>
                            {t('business_type')}
                        </label>
                        <span className="">
                            <Controller
                                name="BusinessTypeId"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    message: t('invalid_business_type')
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Dropdown
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={businessTypes.map(type => ({ value: type.Id, name: type.Name }))}
                                            optionLabel="name"
                                            placeholder={t('business_type_placeholder')}
                                            className="w-full"
                                        />
                                    </>
                                )}
                            />

                        </span>
                        {getFormErrorMessage('BusinessTypeId')}
                    </div>

                    <div className='field'>
                        <label htmlFor='BusinessAddress' className={classNames({ 'p-error': errors.BusinessAddress })}>
                            {t('business_address')}
                        </label>
                        <span className="">
                            <Controller
                                name="BusinessAddress"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                    message: t('invalid_business_address')
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} maxLength='100' placeholder={t('business_address_placeholder')} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />

                        </span>
                        {getFormErrorMessage('BusinessAddress')}
                    </div>

                    <div className='field'>
                        <span className="">
                            <Controller
                                name="CountryID"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={(e) => { field.onChange(e.value); resetField("CityID", { defaultValue: "" }); resetField("StateId", { defaultValue: "" }); setGovernatesList([]); setCitiesList([]) }}
                                        options={countriesList.map(country => ({ id: country.id, name: country.name, code: country.twoLetterCode }))}
                                        placeholder={t('country')}
                                        optionLabel='name'
                                        filter valueTemplate={selectedCountryTemplateVendor}
                                        itemTemplate={countryOptionTemplateVendor}
                                        className="w-full"
                                    />
                                )}
                            />

                        </span>
                        {getFormErrorMessage('CountryID')}
                    </div>

                    <div className='field'>
                        <span className="">
                            <Controller
                                name="StateId"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Dropdown
                                            value={field.value}
                                            onChange={(e) => { field.onChange(e.value); resetField("CityID", { defaultValue: "" }); setCitiesList([]) }}
                                            options={governatesList.map(gov => ({ id: gov.Id, name: gov.Name }))}
                                            optionLabel="name"
                                            placeholder={t('governate')}
                                            filter valueTemplate={selectedGovernorateTemplate}
                                            itemTemplate={governorateOptionTemplate}
                                            className="w-full"
                                        />
                                    </>
                                )}
                            />

                        </span>
                        {getFormErrorMessage('StateId')}
                    </div>

                    <div className='field'>
                        <span className="">
                            <Controller
                                name="CityID"
                                control={control}
                                rules={{
                                    required: t('this_field_is_required'),
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Dropdown
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={citiesList.map(city => ({ id: city.Id, name: city.Name }))}
                                            optionLabel="name"
                                            placeholder={t('city')}
                                            filter valueTemplate={selectedCityTemplate}
                                            itemTemplate={cityOptionTemplate}
                                            className="w-full"
                                        />
                                    </>
                                )}
                            />

                        </span>
                        {getFormErrorMessage('CityID')}
                    </div>

                    <div className='phone-num'>
                        <label htmlFor='CountryCode ' className={classNames({ 'p-error': errors.CountryCode })}>
                            {t('phone_number')}
                        </label>
                        <div className='phone-fields'>
                            <div className="field">
                                <Controller
                                    name="CountryCode"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={(e) => { field.onChange(e.value); resetField("PhoneNumber", { defaultValue: "" }) }}
                                            options={countriesList.map(country => ({ id: country?.id, name: country?.phoneCode, code: country?.twoLetterCode }))}
                                            placeholder={t('country_code')}
                                            optionLabel='code'
                                            filter valueTemplate={selectedCountryTemplate}
                                            itemTemplate={countryOptionTemplate}
                                        />
                                    )}
                                />
                                {getFormErrorMessage('phoneCode')}
                            </div>

                            <div className='field w-full'>
                                <span className="phone_num">
                                    <Controller
                                        name="PhoneNumber"
                                        control={control}
                                        rules={{
                                            required: t('this_field_is_required'),
                                            minLength: {
                                                value: countriesList?.find(country => country?.id === CountryCode?.id)?.phoneLength,
                                                message: t('phone_number_min_length', { length: countriesList?.find(country => country?.id === CountryCode?.id)?.phoneLength || 0 })
                                            },
                                            maxLength: {
                                                value: countriesList?.find(country => country?.id === CountryCode?.id)?.phoneLength,
                                                message: t('phone_number_max_length', { length: countriesList?.find(country => country?.id === CountryCode?.id)?.phoneLength || 15 })
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                {...field}
                                                id={field.name}
                                                value={field.value || ""}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                className={classNames({ 'p-invalid': fieldState.error })}
                                                placeholder={countriesList?.find(country => country?.id === CountryCode?.id)?.hintText || t('phone_number_placeholder')}
                                                maxLength={countriesList?.find(country => country?.id === CountryCode?.id)?.phoneLength}
                                                keyfilter="pint"
                                            />

                                        )}
                                    />

                                </span>
                                {getFormErrorMessage('PhoneNumber')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='btns-content'>
                    <span></span>
                    <button
                        className='next'
                        onClick={() => {
                            window.scroll({
                                top: 0,
                                behavior: "smooth",
                            });
                        }}
                    >
                        {t('next')}
                        <img src={arrow} alt='pic' />
                    </button>
                </div>
            </form>
        </>
    );
}

export default StepOne;
