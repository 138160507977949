import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRelatedProductByID } from '../../api/productAPIs';
import NoData from '../../components/NoData/NoData';
import CartItem from '../../components/CartItem/CartItem';
import Pagination from '../../components/Pagination/Pagination';
import Loader from '../../components/Loader/Loader';

const LikeSection = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    async function getPopularProducts() {
        setLoading(true);
        const obj = { productId: id, paging: { PageNumber: page, PageSize: 8 } };

        try {
            const { data } = await getRelatedProductByID(obj);
            setTotal(data?.data?.paging?.TotalItems);
            const newData = data?.data?.list?.map((item) => {
                return {
                    id: item?.id,
                    productCard: item,
                    type: item?.type || 0,
                    category: item?.productType || '',
                    label: item?.label || '',
                    manufacturer: item?.manufacturer || '',
                    cart: item?.cart || '',
                    saleText: item?.prices?.oldPrice > 0 ? t('sale') : '',
                    title: item?.name || '',
                    price: item?.prices?.price || '',
                    img: item?.imageURL || '',
                    rateText: item?.rating?.avgRate || '',
                    priceOld: item?.prices?.oldPrice || '',
                    desc: item?.shortDescription || '',
                    isCompare: item?.isCompare || false,
                    isRated: item?.isRated || false,
                    isWishlist: item?.isWishlist || false,
                    shipping: item?.shipping || {},
                    displayStockQuantity: item?.stockStatus?.displayStockQuantity || false,
                    stockQuantity: item?.stockStatus?.stockQuantity || 0,
                    stockStatus: item?.stockStatus,
                };
            });
            setData(newData);
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (id) getPopularProducts();
    }, [id, page]);

    return (
        data?.length && (
            <section className="like-section container_bx">
                <h3>{t('you_may_like')}</h3>

                {!loading ? (
                    <div className="grid pt_30">
                        {data?.length ? (
                            data?.map((card) => (
                                <div key={card?.id} className="col-12 md:col-3 lg:col-3">
                                    <div className="new-cart-item">
                                        <CartItem
                                            key={card.id}
                                            type={card?.type}
                                            id={card?.id}
                                            label={card.label}
                                            manufacturer={card.manufacturer}
                                            cart={card.cart}
                                            saleText={card.saleText}
                                            title={card.title}
                                            price={card.price}
                                            img={card.img}
                                            rateText={card.rateText}
                                            priceOld={card.priceOld}
                                            desc={card.desc}
                                            isCompare={card.isCompare}
                                            isWishlist={card.isWishlist}
                                            shipping={card.shipping}
                                            displayStockQuantity={card.displayStockQuantity}
                                            stockQuantity={card.stockQuantity}
                                            stockStatus={card.stockStatus}
                                            associatedId={card?.associatedId}
                                            productCard={card?.productCard}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <NoData />
                        )}
                    </div>
                ) : (
                    <Loader />
                )}

                {total > 8 && (
                    <Pagination
                        totalItems={total}
                        itemsPerPage={8}
                        currentPage={page}
                        onPageChange={(page) => {
                            setPage(page);
                        }}
                        handelScroll={() => {
                            const details = document.querySelector('.like-section');
                            details.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }}
                    />
                )}
            </section>
        )
    );
};

export default LikeSection;
