import { baseInstance } from './baseInstance';

export const getCustomerInfo = () => {
    return baseInstance.get('/Customer/GetCustomerInfo');
};

export const editCustomerInfo = (data = {}) => {
    return baseInstance.post('/Customer/UpdateCustomerInfo', data);
};

export const SendSmsOtp = (data = {}) => {
    return baseInstance.post('/Customer/SendSmsOtp', data);
};

export const sendVerificationEmail = (data = {}) => {
    return baseInstance.post('/Customer/VerifyEmail', data);
};

export const verifyPhone = (data = {}) => {
    return baseInstance.post('/Customer/VerifyUpdatePhone', data);
};

export const editPhoneNumber = (data = {}) => {
    return baseInstance.post('/Customer/ChangePhone', data);
};

export const changePassword = (data = {}) => {
    return baseInstance.post('/Customer/CustomChangePassword', data);
};

export const editEmailAddress = (data = {}) => {
    return baseInstance.post('/Customer/ChangeEmail', data);
};

export const getCustomerAddress = (pageNumber = 0, pageSize = 20) => {
    return baseInstance.post(`/Customer/GetCustomerAddress`, {
        PageNumber: pageNumber,
        PageSize: pageSize,
    });
};

export const getCustomerAddressById = (addressId = '') => {
    return baseInstance.get(`/Customer/AddressEdit/${addressId}`);
};

export const addCustomerAddress = (data = {}) => {
    return baseInstance.post('/Customer/AddAddress', data);
};

export const updateCustomerAddress = (data = {}) => {
    return baseInstance.put('/Customer/UpdateAddress', data);
};

export const deleteCustomerAddress = (addressId = '') => {
    return baseInstance.delete(`/Customer/DeleteAddress?AddressId=${addressId}`);
};

export const getAddressDetails = (addressId = '') => {
    return baseInstance.post(`/Customer/AddressDetails?Id=${addressId}`);
};

export const customerContactUs = (data) => {
    return baseInstance.post('/Customer/ContactUs', data);
};
