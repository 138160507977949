import React, { useContext, useState, useEffect } from 'react';
import { Steps } from 'primereact/steps';
import { Controller, set, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputTextarea } from "primereact/inputtextarea";
import { FormStepContext } from '../../utils/contexts/FormStepContext';
import { STEPS } from '../../utils/constants';
import { getCategoriesAPI } from '../../api/categoriseAPIs';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import buisness from '../../assets/images/vendor/wizard/business.svg';
import document from '../../assets/images/vendor/wizard/documents.svg';
import info from '../../assets/images/vendor/wizard/broduct-info.svg';
import arrow from '../../assets/images/btn-arrow.svg';

const StepThree = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeIndex] = useState(2);
    const [categoriesList, setCategoriesList] = useState([]);

    const items = [
        {
            label: (
                <div className='step-title'><img src={buisness} alt='pic' />{t("business_details")}</div>
            )
        },
        {
            label: (
                <div className='step-title'><img src={document} alt='pic' />{t("documents")}</div>
            )
        },
        {
            label: (
                <div className='step-title'><img src={info} alt='pic' /> {t("products_info")}</div>
            )
        }
    ];

    const { setStep, updateFormState, formState, submitForm } = useContext(FormStepContext)

    const onSubmit = (data) => {
        let item = { categoryId: data?.productCategory?.id, descriptions: data.description }

        updateFormState({
            ProductCategories: [item],
            IsAcceptTerms: data.IsAcceptTerms || false,
        }, 3);
    };

    const goBack = () => {
        updateFormState({
            ProductCategories: [{
                categoryId: formState?.ProductCategories?.[0]?.categoryId || "",
                descriptions: formState?.ProductCategories?.[0]?.descriptions || "",
            }],
            IsAcceptTerms: formState?.IsAcceptTerms || false,
        });

        setStep(STEPS.DOCUMENT);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }

    const {
        control,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({ mode: 'onChange' });

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error"></small>;
    };

    useEffect(() => {
        getCategoriesAPI({
            paging: {
                PageNumber: 0,
                PageSize: 100
            },
            data: {
                isHome: false,
                subCategorySize: 1
            }
        })
            .then(res => {
                setCategoriesList(res.data.data.list);
            })
    }, []);

    useEffect(() => {
        reset({
            productCategory: formState?.ProductCategories?.[0]?.categoryId || "",
            description: formState?.ProductCategories?.[0]?.descriptions || "",
            IsAcceptTerms: formState?.IsAcceptTerms || false,
        });
    }, [activeIndex]);

    useEffect(() => {
        if (formState?.ProductCategories?.[0]?.categoryId && categoriesList?.length) {
            const category = categoriesList.find(cat => cat?.id === formState?.ProductCategories?.[0]?.categoryId);
            reset({ productCategory: category || "", description: formState?.ProductCategories?.[0]?.descriptions || "", IsAcceptTerms: formState?.IsAcceptTerms || false, });
        }
    }, [formState?.ProductCategories?.[0]?.categoryId, categoriesList]);

    return (
        <>
            <div className='steps-items'>
                <Steps model={items} activeIndex={activeIndex} readOnly={false} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='card-bx mt_40'>

                    <div className='field'>
                        <label htmlFor='productCategory' className={classNames({ 'p-error': errors.productCategory })}>
                            {t("product_category")}
                        </label>
                        <span className="">
                            <Controller
                                name="productCategory"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Dropdown
                                            {...field}
                                            options={categoriesList?.map(cat => ({ ...cat }))}
                                            optionLabel="name"
                                            value={field.value || ""}
                                            placeholder={t("select_product_category")}
                                            className="w-full"
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                updateFormState({
                                                    ProductCategories: [{
                                                        categoryId: e.value.id,
                                                        descriptions: formState?.ProductCategories?.[0]?.descriptions || "",
                                                    }],
                                                    IsAcceptTerms: formState?.IsAcceptTerms || false,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            />

                        </span>
                        {getFormErrorMessage('productCategory')}
                    </div>

                    <div className='field'>
                        <label htmlFor='description' className={classNames({ 'p-error': errors.description })}>
                            {t("product_description")}
                        </label>
                        <span className="">
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: t("this_field_is_required") }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputTextarea
                                            {...field}
                                            id={field.name}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                                updateFormState({
                                                    ProductCategories: [{
                                                        categoryId: formState?.ProductCategories?.[0]?.categoryId || "",
                                                        descriptions: e.target.value,
                                                    }],
                                                    IsAcceptTerms: formState?.IsAcceptTerms || false,
                                                });
                                            }}
                                            value={field.value || ''}
                                            rows={4}
                                            cols={30}
                                            maxLength='500'
                                            className={classNames({ 'p-invalid': fieldState.error })}
                                        />
                                    </>
                                )}
                            />

                        </span>
                        {getFormErrorMessage('description')}
                    </div>

                    <div className='field flex gap-2 align-items-center'>
                        <Controller
                            name="IsAcceptTerms"
                            control={control}
                            rules={{ required: t("this_field_is_required") }}
                            render={({ field, fieldState }) => (
                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {
                                    field.onChange(e.checked);
                                    updateFormState({
                                        ProductCategories: [{
                                            categoryId: formState?.ProductCategories?.[0]?.categoryId || "",
                                            descriptions: formState?.ProductCategories?.[0]?.descriptions || "",
                                        }],
                                        IsAcceptTerms: e.checked,
                                    })
                                }}
                                />
                            )}
                        />
                        <label htmlFor='IsAcceptTerms' className={classNames({ 'p-error': errors.IsAcceptTerms || false, 'mb-0': true })}>
                            {t("accept_terms")} <a href='#'>{t("terms_and_conditions")}</a>
                        </label>
                        {getFormErrorMessage('IsAcceptTerms')}
                    </div>
                </div>

                <div className='btns-content'>
                    <button className='back' onClick={goBack}><img src={arrow} alt='pic' />
                        {t("previous")}
                    </button>
                    <button className='next'>
                        {t("submit")}
                        <img src={arrow} alt='pic' /> </button>
                </div>
            </form>
        </>
    );
}

export default StepThree;
