import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getWishList } from '../../api/wishListAPI';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import NoData from '../../components/NoData/NoData';
import Pagination from '../../components/Pagination/Pagination';
import CartItem from '../../components/CartItem/CartItem';
import PuffLoader from 'react-spinners/PuffLoader';
import useForceRerender from '../../hooks/useForceRerender';

const Wishlist = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [rerender, forceRerender] = useForceRerender();
    const { myWishList, loading } = useSelector((state) => state.wishListReducer);
    const { isLogged } = useSelector((state) => state.authReducer);
    const [currentPage, setCurrentPage] = useState(0);
    const local = localStorage.getItem('language');
    const itemsPerPage = 20;

    const onPageChange = (newPage) => {
        dispatch(showLoader());
        setCurrentPage(newPage);

        if (isLogged) {
            dispatch(
                getWishList({
                    paging: { PageNumber: newPage, PageSize: itemsPerPage },
                    ids: [],
                    isGuest: false,
                }),
            );
        } else {
            let idArray = [];
            let wishListArr = localStorage.getItem('wishlist');
            if (wishListArr) idArray = wishListArr.split(',').map((item) => item.trim());
            dispatch(
                getWishList({
                    paging: { PageNumber: newPage, PageSize: itemsPerPage },
                    ids: idArray,
                    isGuest: true,
                }),
            );
        }

        dispatch(hideLoader());
    };

    const getWishListData = async () => {
        dispatch(showLoader());

        try {
            const { data } = await getWishList({
                paging: { PageNumber: 0, PageSize: itemsPerPage },
                ids: [],
                isGuest: false,
            });
        } catch (error) {
            console.error('Error fetching wishlist data', error);
        }

        dispatch(hideLoader());
    };

    const getMyWishList = () => {
        dispatch(showLoader());

        let wishlistObj = {};

        if (isLogged) {
            wishlistObj = {
                paging: { PageNumber: 0, PageSize: itemsPerPage },
                ids: [],
                isGuest: false,
            };
        } else {
            let idArray = [];
            let wishListArr = localStorage.getItem('wishlist');

            if (wishListArr) idArray = wishListArr.split(',').map((item) => item.trim());

            wishlistObj = {
                paging: { PageNumber: 0, PageSize: itemsPerPage },
                ids: idArray,
                isGuest: true,
            };
        }

        dispatch(getWishList(wishlistObj));
        dispatch(hideLoader());
    };

    useEffect(() => {
        getWishListData();
    }, []);

    useEffect(() => {
        getMyWishList();
    }, [local, rerender]);

    return (
        <section className="my-account-section container_bx">
            {loading && (
                <div className="myWishlist loader-spinner-bx">
                    <PuffLoader
                        color="#DB5F19"
                        loading={loading}
                        size={70}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}
            <div className="tab-title whishlist">
                <h3>{t('my_wishlist')}</h3>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="basic-info-bx mb_1">
                        {myWishList?.data?.list.length > 0 ? (
                            <div className="wishlist-bx">
                                <div className="grid pt_30 w_100">
                                    {myWishList?.data?.list.map((card) => {
                                        return (
                                            <div
                                                key={card?.id}
                                                className="col-12 md:col-6 lg:col-3">
                                                <div className="new-cart-item">
                                                    <CartItem
                                                        key={card?.id}
                                                        id={card?.id}
                                                        type={card?.type}
                                                        label={card?.label}
                                                        manufacturer={card?.manufacturer}
                                                        cart={card?.cart}
                                                        saleText={card?.saleText}
                                                        title={card?.name}
                                                        price={card?.prices?.price}
                                                        img={card?.imageURL}
                                                        rateText={card?.rating?.avgRate}
                                                        priceOld={card?.prices?.oldPrice}
                                                        desc={card?.shortDescription}
                                                        shipping={card?.shipping}
                                                        stockStatus={card?.stockStatus}
                                                        displayStockQuantity={
                                                            card.displayStockQuantity
                                                        }
                                                        stockQuantity={card.stockQuantity}
                                                        isWishlist={card?.isWishlist}
                                                        associatedId={card?.associatedId}
                                                        forceRerender={forceRerender}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <NoData productShape={true} />
                        )}
                        {(myWishList?.data?.paging?.TotalItems > itemsPerPage && (
                            <Pagination
                                totalItems={myWishList?.data?.paging.TotalItems}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onPageChange={onPageChange}
                            />
                        )) ||
                            ''}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Wishlist;
