import React, { useEffect, useState } from 'react';
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import Collapse from './Collapse';
import DropDownList from './DropDownList';
import CheckInputs from './CheckInputs';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import {
    getCategoriesAPI,
    getCategoriesAttributes,
    getDiscountTags,
    getSubCategoriesAPI,
} from '../../api/categoriseAPIs';
import RadioInputs from './RadioInputs';
import { useTranslation } from 'react-i18next';
import { getBrandsAPI } from '../../api/brandsAPIs';
import PuffLoader from 'react-spinners/PuffLoader';
import { Rating } from 'react-simple-star-rating';

const Filter = ({
    filterResult = {},
    setFilterResult = () => {},
    forceRerender = () => {},
    brandId = '',
    categoryId = '',
    subCategoryId = '',
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [valueLeft, setValueLeft] = useState('');
    const [valueRight, setValueRight] = useState('');
    const [range, setRange] = useState([1, 99]);

    const [keyWordSearch, setKeyWordSearch] = useState('');

    const [categoriesList, setCategoriesList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [toggleCategoryList, setToggleCategoryList] = useState(false);

    const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCategorySelected, setSubCategorySelected] = useState(subCategoryId ?? '');

    const [brandsList, setBrandsList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');

    const [isSubCategoryLoading, setIsSubCategoryLoading] = useState(false);

    const [rating, setRating] = useState('');
    const [includeOutOfStock, setIncludeOutOfStock] = useState(false);

    // const [discountsList, setDiscountsList] = useState([]);
    const [includeDiscount, setIncludeDiscount] = useState('');

    const [attributesList, setAttributesList] = useState([]);
    const [attributesLoading, setAttributesLoading] = useState(false);
    const [attributesListValues, setAttributesListValues] = useState([]);

    const handelRangeChange = (values) => {
        setRange(values);
        setValueLeft(values[0]);
        setValueRight(values[1]);
    };

    function categoryOnChangeHandler(e) {
        setSelectedCategory(e.value);
        setSubCategorySelected('');
        getSubCategoriesHandler(e.value);
    }

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate);
    };

    const handleReset = () => {
        // Set the initial value
        setRating('');
    };

    async function getAllCategoriesHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getCategoriesAPI({
                paging: { TotalItems: 0, PageNumber: 0, PageSize: 20 },
                data: { isHome: false, withAll: false, subCategorySize: 10 },
            });
            // data?.data?.list?.length > 0 && setCategoriesList(data?.data?.list);

            if (data?.data?.list?.length > 0 && categoryId) {
                setCategoriesList([data?.data?.list.find((category) => category.id == categoryId)]);
                setSelectedCategory(+categoryId);
                getSubCategoriesHandler(categoryId);
            } else {
                setCategoriesList(data?.data?.list);
                setSelectedCategory('');
                setSubCategorySelected('');
                setSubCategoryList([]);
            }
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getSubCategoriesHandler(categoryId) {
        // dispatch(showLoader());
        setIsSubCategoryLoading(true);
        try {
            const { data } = await getSubCategoriesAPI({
                paging: {
                    PageNumber: 0,
                    PageSize: 20,
                },
                data: {
                    categoryId,
                },
            });
            if (subCategoryId) {
                setSubCategoryList([
                    data?.data?.list.find((subCategory) => subCategory.id == subCategoryId),
                ]);
                setSubCategorySelected(+subCategoryId);
            } else {
                data?.data?.list?.length > 0
                    ? setSubCategoryList(data?.data?.list)
                    : setSubCategoryList([]);
            }
        } catch (error) {
            console.log(error);
        }
        // dispatch(hideLoader());
        setIsSubCategoryLoading(false);
    }

    async function getBrandsHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getBrandsAPI({
                paging: { PageNumber: 0, PageSize: 20 },
                data: { isHome: false },
            });

            if (data?.data?.list?.length > 0 && brandId) {
                setBrandsList([data?.data?.list.find((brand) => brand.id == brandId)]);
                setSelectedBrand(+brandId);
            } else {
                setBrandsList(data?.data?.list);
                setSelectedBrand('');
            }
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getAllDiscountTagsHandler() {
        dispatch(showLoader());
        try {
            const { data } = await getDiscountTags();
            data?.data?.length > 0 && setDiscountsList(data?.data);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getFilterResultHandler() {
        dispatch(showLoader());

        await getAllCategoriesHandler();

        await getBrandsHandler();

        // await getAllDiscountTagsHandler();

        dispatch(hideLoader());
    }

    async function getAllCategoriesAttributesHandler(subCategorySelectedValue) {
        if (!subCategorySelectedValue) return;
        setAttributesLoading(true);

        try {
            const { data } = await getCategoriesAttributes(subCategorySelectedValue);
            data?.data?.length > 0 && setAttributesList(data?.data);
        } catch (error) {
            console.log(error);
        }

        setAttributesLoading(false);
    }

    useEffect(() => {
        getFilterResultHandler();
    }, [categoryId, brandId, subCategoryId]);

    return (
        <>
            <div className="card-bx filter-bx">
                <div
                    className="flex justify-content-between align-items-center"
                    style={{ margin: '0 15px 10px' }}>
                    <h3>{t(`filter`)}</h3>
                    <div className="flex align-items-center gap-2">
                        <button
                            className="clear-bx__btn"
                            onClick={(e) => {
                                e.preventDefault();
                                handleReset();
                                setFilterResult({});
                                setSelectedCategory('');
                                setSubCategorySelected('');
                                setSelectedBrand('');
                                setValueLeft('');
                                setValueRight('');
                                setIncludeOutOfStock(false);
                                setIncludeDiscount(false);
                                setAttributesListValues([]);
                                setSubCategoryList([]);
                                setAttributesList([]);
                                forceRerender();
                            }}>
                            {t(`clear`)}
                        </button>
                        <button
                            className="filter-bx__btn"
                            onClick={(e) => {
                                e.preventDefault();
                                setFilterResult({
                                    catalogID: subCategorySelected
                                        ? subCategorySelected
                                        : selectedCategory,
                                    brandId: selectedBrand,
                                    priceFrom: valueLeft,
                                    priceTo: valueRight,
                                    rating: rating,
                                    includingOutOfStock: includeOutOfStock,
                                    attributesId:
                                        attributesListValues?.length > 0
                                            ? attributesListValues
                                            : '',
                                });
                                forceRerender();
                            }}>
                            {t(`apply`)}
                        </button>
                    </div>
                </div>

                <ul className="list-items">
                    <li>
                        <div className="item hidden">
                            <DropDownList
                                value={keyWordSearch}
                                setValue={setKeyWordSearch}
                                PlaceholderText={t(`search_here`)}
                            />
                        </div>
                    </li>
                    {/* Categories */}
                    <li>
                        <div className="item">
                            <h4 className="title">{t(`categories`)}</h4>

                            <div
                                className={`${
                                    categoriesList?.length > 1 ? 'checks-box' : 'check-one-box'
                                }`}
                                style={{
                                    height: `${toggleCategoryList ? 'auto' : '75px'}`,
                                    overflow: 'hidden',
                                }}>
                                {categoriesList?.length > 0 ? (
                                    categoriesList?.map((category, index) => (
                                        <div key={index}>
                                            <RadioInputs
                                                InputId={category?.id}
                                                Name={category?.name}
                                                LabelTitle={category?.name}
                                                value={selectedCategory}
                                                setValue={setSelectedCategory}
                                                categoryOnChangeHandler={categoryOnChangeHandler}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div>no data</div>
                                )}
                            </div>
                            {categoriesList?.length > 4 && (
                                <div className="more-bx">
                                    <div
                                        className="see-more"
                                        onClick={() => {
                                            setToggleCategoryList(!toggleCategoryList);
                                        }}>
                                        {!toggleCategoryList ? t(`see_more`) : t(`see_less`)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                    {/* Sub Categories */}
                    <li>
                        <div className="item">
                            <h4 className="title">{t(`sub_categories`)}</h4>

                            <div
                                className={`${
                                    subCategoryList?.length > 0 && !isSubCategoryLoading
                                        ? 'checks-box'
                                        : ''
                                }`}>
                                {isSubCategoryLoading && (
                                    <div className="w-full h-full flex justify-content-center align-items-center">
                                        <PuffLoader
                                            color="#DB5F19"
                                            loading={isSubCategoryLoading}
                                            size={70}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                )}
                                {subCategoryList?.length > 0 && !isSubCategoryLoading ? (
                                    subCategoryList?.map((subCategory, index) => (
                                        <div key={index}>
                                            <RadioInputs
                                                InputId={subCategory?.id}
                                                Name={subCategory?.name}
                                                LabelTitle={subCategory?.name}
                                                value={subCategorySelected}
                                                setValue={setSubCategorySelected}
                                                categoryOnChangeHandler={(e) => {
                                                    setSubCategorySelected(e.value);
                                                    getAllCategoriesAttributesHandler(e.value);
                                                }}
                                            />
                                        </div>
                                    ))
                                ) : subCategoryList?.length === 0 && !isSubCategoryLoading ? (
                                    <div>{t(`selectCategory`)}</div>
                                ) : null}
                            </div>

                            {/* <Collapse buttonTitle="see more" content={<div>text 1</div>} /> */}
                        </div>
                    </li>
                    {/* Brands */}
                    <li>
                        <div className="item">
                            <h4 className="title">{t(`brand`)}</h4>

                            <div className="checks-box">
                                {brandsList?.length > 0 ? (
                                    brandsList?.map((brand, index) => (
                                        <div key={index}>
                                            <RadioInputs
                                                InputId={brand?.id}
                                                Name={brand?.name}
                                                LabelTitle={brand?.name}
                                                value={selectedBrand}
                                                setValue={setSelectedBrand}
                                                categoryOnChangeHandler={(e) => {
                                                    setSelectedBrand(e.value);
                                                }}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div>no data</div>
                                )}
                            </div>

                            {/* <Collapse buttonTitle="see more" content={<div>text 1</div>} /> */}
                        </div>
                    </li>
                    {/* Price Range */}
                    <li>
                        <div className="item">
                            <h4 className="title">
                                {t(`price`)} <span className="currency">{t(`sar`)}</span>
                            </h4>
                            <div className="range-line-bx hidden">
                                <Slider
                                    value={range}
                                    onChange={(e) => handelRangeChange(e.value)}
                                    range
                                />
                            </div>
                            <div className="range-slider-bx">
                                <InputText
                                    value={valueLeft}
                                    onChange={(e) => setValueLeft(e.target.value)}
                                    placeholder={t(`from`)}
                                />
                                <InputText
                                    value={valueRight}
                                    onChange={(e) => setValueRight(e.target.value)}
                                    placeholder={t(`to`)}
                                />
                            </div>
                        </div>
                    </li>

                    {/* Attributes */}
                    {attributesLoading ? (
                        <li>
                            <div className="w-full h-full flex justify-content-center align-items-center">
                                <PuffLoader
                                    color="#DB5F19"
                                    loading={attributesLoading}
                                    size={70}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        </li>
                    ) : (
                        attributesList?.length > 0 &&
                        attributesList?.map((attribute, index) => (
                            <li key={index}>
                                <div className="item">
                                    <h4 className="title">{attribute?.groupName}</h4>

                                    <div className="checks-box">
                                        {attribute?.values?.length > 0 &&
                                            attribute?.values?.map((value, index) => (
                                                <div
                                                    key={index}
                                                    className="flex justify-content-start align-items-center gap-2">
                                                    <Checkbox
                                                        inputId={value?.id}
                                                        name={value?.id}
                                                        checked={attributesListValues.includes(
                                                            value?.id,
                                                        )}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            // setIncludeDiscount(e.target.checked);
                                                            if (
                                                                !attributesListValues.includes(
                                                                    value?.id,
                                                                )
                                                            ) {
                                                                setAttributesListValues([
                                                                    ...attributesListValues,
                                                                    value?.id,
                                                                ]);
                                                            } else {
                                                                setAttributesListValues(
                                                                    attributesListValues.filter(
                                                                        (item) =>
                                                                            item !== value?.id,
                                                                    ),
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={value?.id}>{value?.name}</label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </li>
                        ))
                    )}
                    {/* Rate */}
                    <li>
                        <div className="item">
                            <h4 className="title">{t(`rate`)}</h4>

                            <div>
                                <Rating
                                    onClick={handleRating}
                                    initialValue={rating}
                                    rtl={i18n?.language === 'ar' ? true : false}
                                    // allowFraction={true}
                                />
                            </div>
                        </div>
                    </li>
                    {/* Stock Availability */}
                    <li>
                        <div className="item">
                            <h4 className="title">{t(`stock`)}</h4>

                            <div className="flex align-items-center gap-2">
                                <Checkbox
                                    inputId="outOfStock"
                                    name="outOfStock"
                                    checked={includeOutOfStock}
                                    onChange={(e) => {
                                        setIncludeOutOfStock(e.target.checked);
                                    }}
                                />
                                <label htmlFor="outOfStock">{t(`includeOutOfStock`)}</label>
                            </div>
                        </div>
                    </li>

                    {/* <li>
                        <div className="item">
                            <h4 className="title">{t(`discount`)}</h4>

                            <div className="flex align-items-center gap-2">
                                <Checkbox
                                    inputId="includeDiscount"
                                    name="includeDiscount"
                                    checked={includeDiscount}
                                    onChange={(e) => {
                                        setIncludeDiscount(e.target.checked);
                                    }}
                                />
                                <label htmlFor="includeDiscount">{t(`includeDiscount`)}</label>
                            </div>
                        </div>
                    </li> */}
                </ul>
            </div>
        </>
    );
};

export default Filter;
