import React from 'react';
import NoData from '../../components/NoData/NoData';
import { useTranslation } from 'react-i18next';
import ContactUsForm from './ContactUsForm';

export default function ContactUs() {
    const { t } = useTranslation();

    return (
        <section className="contact-us basic-info-bx mb_20">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="basic-info-bx-inner">
                            <h2 className="main-heading">{t('contactUs')}</h2>
                            <div className="grid my-5">
                                <div className="col-12 md:col-6">
                                    <ContactUsForm />
                                </div>
                                <div className="hidden md:flex justify-content-center col-1 relative">
                                    <hr className="contact-us-line-break" />
                                </div>
                                <div className="col-12 md:col-5">
                                    <div>
                                        <h3 className="sub-heading">{t('adresses')}</h3>
                                        <p
                                            className="bullet-before orange-hover cursor-pointer my-2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                    'https://www.google.com/maps/place/Watan+First+Digital/@24.7879717,46.646802,17z/data=!3m1!4b1!4m6!3m5!1s0x3e2ee39e8e50d381:0x788f1beb59752735!8m2!3d24.7879717!4d46.646802!16s%2Fg%2F11pynk4byh?entry=ttu',
                                                    '_blank',
                                                );
                                            }}>
                                            {t('address-saudi-1')}
                                        </p>
                                        <p
                                            className="bullet-before orange-hover cursor-pointer"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                    'https://www.google.com/maps/place/Watan+First+Digital+-+Egypt,+Al+Lasilki,+12G+Etisalat+Club+Rd,+Maadi,+Cairo+Governorate/data=!4m2!3m1!1s0x6573653939c767a7:0x59d6e5463a0d604a?utm_source=mstt_1&entry=gps&g_ep=CAESCjExLjExNy4xMDEYACDXggMqGyw0NzA3NTkxNSw0NzA4NzExOCw0NzA4NDM4N0ICRUc%3D',
                                                    '_blank',
                                                );
                                            }}>
                                            {t('address-egypt-1')}
                                        </p>
                                    </div>
                                    <div className="my-6">
                                        <h3 className="sub-heading">{t('phone_number')}</h3>
                                        <p className="bullet-before my-2">{t('contact-phone-1')}</p>
                                        <p className="bullet-before">{t('contact-phone-2')}</p>
                                    </div>
                                    <div className="my-6">
                                        <h3 className="sub-heading">{t('email')}</h3>
                                        <p className="bullet-before my-2">{t('contact-email-1')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
