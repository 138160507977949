import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination/Pagination';

export default function PaymentCardsChange({
    isVisible,
    setIsVisible = () => {},
    cardsList = [],
    cardsPaging = {},
    pageSize = 10,
    page = 0,
    setPage = () => {},
    visaCardId,
    setVisaCardId = () => {},
    setSelectedCard = () => {},
}) {
    const { t } = useTranslation();

    const [card, setCard] = useState({});

    function handleReset() {
        setIsVisible(false);
    }

    return (
        <Dialog
            header={null}
            visible={isVisible}
            draggable={false}
            resizable={false}
            className="models-without-header"
            style={{ width: '50%', maxWidth: '500px' }}
            onHide={handleReset}>
            <h2>{t('payFrom')}</h2>
            <ul className="p-0">
                {(cardsList?.length > 0 &&
                    cardsList?.map((card, index) => (
                        <li key={index} className="my-5">
                            <div className="flex align-items-center justify-content-between gap-2">
                                <label
                                    htmlFor={card?.id}
                                    className="inline-flex align-items-start gap-3">
                                    <span
                                        className="mt-1 inline-block"
                                        style={{ width: '26px', height: '26px' }}>
                                        <CardSVG />
                                    </span>
                                    <span style={{ color: '#83807F' }}>
                                        <p
                                            className="one-line-text"
                                            style={{
                                                color: '#312B29',
                                                fontSize: '16px',
                                                maxWidth: '250px',
                                            }}>
                                            {card?.holderName}
                                        </p>
                                        <p>
                                            <span>{t('cardNumber')} ****</span>
                                            <span>{card?.cardNumber?.slice(-4)}</span>
                                            {card?.default ? (
                                                <span className="mx-2 default-card">{`${t(
                                                    'default',
                                                )}`}</span>
                                            ) : null}
                                        </p>
                                        <p>
                                            {t('EXP')} {card?.expiryDate}
                                        </p>
                                        {card?.expired ? (
                                            <p className="text-red-500">{t('expired')}</p>
                                        ) : null}
                                    </span>
                                </label>
                                <RadioButton
                                    name="card"
                                    className="mx-2"
                                    id={card?.id}
                                    value={card?.id}
                                    checked={visaCardId == card?.id}
                                    onChange={(e) => {
                                        setVisaCardId(e.value);
                                        setCard(card);
                                        //   setCvv('');
                                    }}
                                />
                            </div>
                        </li>
                    ))) ||
                    ''}
            </ul>
            {(cardsPaging?.TotalItems > pageSize && (
                <div className="w-full flex justify-content-center mt-4">
                    <Pagination
                        totalItems={cardsPaging?.TotalItems}
                        itemsPerPage={pageSize}
                        currentPage={page}
                        onPageChange={setPage}
                    />
                </div>
            )) ||
                ''}
            <div className="flex justify-content-end align-items-center gap-4">
                <button
                    className="main-btn"
                    onClick={() => {
                        handleReset();
                        setSelectedCard(card);
                    }}>
                    {t('save')}
                </button>
            </div>
        </Dialog>
    );
}

const CardSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 31" fill="none">
        <g>
            <path
                d="M26.8337 12.9999C26.8337 8.91467 26.8337 6.8715 25.564 5.60292C24.2954 4.33325 22.2522 4.33325 18.167 4.33325H13.8337C9.74841 4.33325 7.70524 4.33325 6.43666 5.60292C5.16699 6.8715 5.16699 8.91467 5.16699 12.9999C5.16699 17.0852 5.16699 19.1283 6.43666 20.3969C7.70524 21.6666 9.74841 21.6666 13.8337 21.6666H18.167C22.2522 21.6666 24.2954 21.6666 25.564 20.3969C26.2725 19.6895 26.5856 18.7416 26.7232 17.3333M13.8337 17.3333H9.50033M18.167 17.3333H16.542M5.16699 10.8333H10.5837M26.8337 10.8333H14.917"
                stroke="#83807F"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_1054_68244"
                x="-1"
                y="0"
                width="34"
                height="34"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1054_68244"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1054_68244"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
