import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderPriceDetails({ priceDetails = {}, orderDetails = {} }) {
    const { t } = useTranslation();

    return (
        <>
            <h2>{t('orderSummary')}</h2>
            <div className="basic-info-bx my-2 flex flex-column gap-3">
                <div className="flex justify-content-between">
                    <p>{t('subtotal')}</p>
                    <div className="flex align-items-center justify-content-center gap-1">
                        <p>{priceDetails?.subTotal}</p>
                        <span>
                            {orderDetails?.currency?.name
                                ? t(`${orderDetails?.currency?.code}`)
                                : t(`SAR`)}
                        </span>
                    </div>
                </div>
                <div className="flex justify-content-between">
                    <p>{t('shipping')}</p>
                    <div className="flex align-items-center justify-content-center gap-1">
                        <p>{priceDetails?.shipping ?? 0}</p>
                        <span>
                            {orderDetails?.currency?.name
                                ? t(`${orderDetails?.currency?.code}`)
                                : t(`SAR`)}
                        </span>
                    </div>
                </div>
                <div className="flex justify-content-between">
                    <p>{t('tax')}</p>
                    <div className="flex align-items-center justify-content-center gap-1">
                        <p>{priceDetails?.tax ?? 0}</p>
                        <span>
                            {orderDetails?.currency?.name
                                ? t(`${orderDetails?.currency?.code}`)
                                : t(`SAR`)}
                        </span>
                    </div>
                </div>
                {priceDetails?.codeFee ? (
                    <div className="flex justify-content-between">
                        <p>{t('Promocode')}</p>
                        <div className="flex align-items-center justify-content-center gap-1">
                            <p style={{ color: '#07C564' }}>- {priceDetails?.codeFee}</p>
                            <span style={{ color: '#07C564' }}>
                                {orderDetails?.currency?.name
                                    ? t(`${orderDetails?.currency?.code}`)
                                    : t(`SAR`)}
                            </span>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <hr style={{ border: '1px solid #D9D9D9' }} />
                <div className="flex justify-content-between">
                    <p>{t('total')}</p>
                    <div className="flex align-items-center justify-content-center gap-1">
                        <p>{priceDetails?.total}</p>
                        <span>
                            {orderDetails?.currency?.name
                                ? t(`${orderDetails?.currency?.code}`)
                                : t(`SAR`)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
