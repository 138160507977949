import React, { useEffect, useState } from 'react';
import { getMyOrders } from '../../api/ordersAPIs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { Link, useParams } from 'react-router-dom';
import NoData from '../../components/NoData/NoData';
import './Orders.css';
import NoOrder from './NoOrder/NoOrder';
import OrdersTable from './OrdersTable/OrdersTable';
import Pagination from '../../components/Pagination/Pagination';

export default function Orders() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ordersData, setOrdersData] = useState([]);
    const [orderPaging, setOrderPaging] = useState({});
    const [orderPage, setOrderPage] = useState(0);
    const [orderPageSize] = useState(20);

    async function getMyOrdersData() {
        const formData = {
            paging: { PageNumber: orderPage, PageSize: orderPageSize },
            data: { sort: 0 },
        };
        dispatch(showLoader());
        try {
            const { data } = await getMyOrders(formData);
            setOrdersData(data?.data?.orders);
            setOrderPaging(data?.data?.paging);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    useEffect(() => {
        getMyOrdersData();

        return () => {
            setOrdersData([]);
        };
    }, [orderPage]);

    if (!ordersData || !ordersData?.length)
        return <NoData productShape={true} text={t('no_items')} />;

    return (
        <section className="basic-info-bx mb_20">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="basic-info-bx-inner">
                            <h2 className="main-heading">{t('my_orders')}</h2>
                            <div className="order-list">
                                {ordersData?.length > 0 ? (
                                    <>
                                        <OrdersTable orders={ordersData} />
                                        {(orderPaging?.TotalItems > orderPageSize && (
                                            <Pagination
                                                totalItems={orderPaging?.TotalItems}
                                                itemsPerPage={orderPageSize}
                                                currentPage={orderPage}
                                                onPageChange={(page) => setOrderPage(page)}
                                                handelScroll={() => {
                                                    const items =
                                                        document.querySelector(
                                                            '.orders-items-list',
                                                        );
                                                    items &&
                                                        items.scrollIntoView({
                                                            behavior: 'smooth',
                                                        });
                                                }}
                                            />
                                        )) ||
                                            ''}
                                    </>
                                ) : (
                                    <NoOrder />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
