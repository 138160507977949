import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import NoData from '../../components/NoData/NoData';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';

const SubCutgSlider = ({ subCategoryList, loaded, setCategoryId }) => {
    const { i18n } = useTranslation();
    if (loaded & (subCategoryList?.length === 0)) return;

    return (
        <section className="sub-section container_bx">
            {subCategoryList?.length > 0 ? (
                <div>
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024,
                                },
                                items: 7,
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0,
                                },
                                items: 4,
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464,
                                },
                                items: 2,
                            },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={i18n.language === 'ar' ? true : false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable>
                        {subCategoryList.map((subCategory) => (
                            <div key={subCategory?.id} className="sub-item">
                                <Link to="#" onClick={() => setCategoryId(subCategory?.id)}>
                                    <div className="img-holder">
                                        <img src={subCategory.image} alt="pic" />
                                    </div>
                                    <h3>{subCategory.name}</h3>
                                </Link>
                            </div>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <NoData />
            )}
        </section>
    );
};

export default SubCutgSlider;
