import React from 'react';
import { Link } from 'react-router-dom';
import { TfiWorld } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import call from '../../assets/images/call.svg';
import LanguageSelect from './LanguageSelect';
import '../../assets/css/shared/topbar.css';
import 'flag-icon-css/css/flag-icons.min.css';

const TopBar = () => {
    const { isLogged, userType } = useSelector((state) => state.authReducer);
    const { t, i18n } = useTranslation();
    document.documentElement.lang = i18n.language;

    return (
        <div className="top-bar container_bx">
            <div className="select-container">
                <div className="lang-dropdown">
                    {i18n.language === 'en' && (
                        <span
                            className="lang-btn"
                            onClick={() => {
                                i18n.changeLanguage('ar');
                                window.location.reload();
                            }}>
                            <div className="text"> عربي </div> <TfiWorld />
                        </span>
                    )}
                    {i18n.language === 'ar' && (
                        <span
                            className="lang-btn"
                            onClick={() => {
                                i18n.changeLanguage('en');
                                window.location.reload();
                            }}>
                            <div className="text"> english </div> <TfiWorld />
                        </span>
                    )}
                </div>

                <div className="delivery-select">
                    <span className="text">{t('delivery_in')}</span>
                    <LanguageSelect />
                </div>
            </div>

            <div className="content-bx">
                {isLogged && userType !== 1 && (
                    <Link to="/vendor-wizard" className="sell">
                        {t('Sell_on_shaari')}
                    </Link>
                )}
                <Link className="call hidden">
                    <img src={call} alt="pic" />
                    {t('call')}
                </Link>
            </div>
        </div>
    );
};

export default TopBar;
