import React from 'react';

import Services from '../../components/serviceSection/Services';
import GallerySliderOne from './GallerySliderOne';
import CategoryItemSection from './CategoryItemSection';
import GridSection from './GridSection';
import GallerySliderTwo from './GallerySliderTwo';
import RecommendedSection from './RecommendedSection';
import OfferBannerSection from './OfferBannerSection';
import BrandSection from './BrandSection';
import { ToastContainer } from 'react-toastify';
import '../../assets/css/pages/home.css';

const Home = () => {
    return (
        <>
            <ToastContainer />

            <GallerySliderOne />

            <Services />

            <CategoryItemSection />

            <RecommendedSection />

            {/* <OfferBannerSection /> */}

            <GallerySliderTwo />

            <BrandSection />
        </>
    );
};

export default Home;
