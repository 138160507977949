import React from 'react'

export default function HyperPayCard({children}) {
  function closeHyperPay() {
    const hyperPayWrapper = document.getElementById('paymentWidgetsHyper');
    const hyperPayBody = document.getElementById('paymentWidgetsHyperBody');
    if (hyperPayWrapper) {
        hyperPayWrapper.classList.add('hidden');
        hyperPayBody.innerHTML = '';
    }
    const hyperPayScript = document.getElementById('paymentWidgetsHyperScriptTag');
    if (hyperPayScript) {
        hyperPayScript.remove();
    }
  }
  return (
    <div id='paymentWidgetsHyper' className='hidden'>
      <div className='close-btn' onClick={closeHyperPay}>
        <span className='inline-block'>X</span>
      </div>
        <div id='paymentWidgetsHyperBody'></div>
    </div>
  )
}