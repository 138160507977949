import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateApiKey } from '../helpers/loadApiKey';
import { setApiKeyAction } from '../redux/authSlice';

export const useInitApiKey = () => {
    const dispatch = useDispatch();

    const [isLoadingKey, setIsLodingKey] = useState(true);

    const { apiKey } = useSelector((state) => state.authReducer);

    useEffect(() => {
        validateApiKey(apiKey).then((res) => {
            dispatch(setApiKeyAction(res));
        }).catch((err) => {
            console.log('err >> ', err);
        }).finally(() => {
            setIsLodingKey(false);
        });
    }, []);

    return { isLoadingKey };
};
