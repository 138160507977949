import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clearMiniCartModal } from '../../redux/addToCart';
import { logoutAction } from '../../redux/authSlice';

const LogOut = ({ logoutContent }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <span
            className="logout-link"
            onClick={() => {
                dispatch(logoutAction());
                dispatch(clearMiniCartModal());
                navigate('/');
                window.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            }}>
            <span>
                {logoutContent.photo}
                {logoutContent.text}
            </span>
        </span>
    );
};

export default LogOut;
