import { createSlice } from '@reduxjs/toolkit';
import {
    getAddToCompareList,
    getRemoveFromCompareList,
    getCompareList,
} from '../api/compareListAPIs';

export const compareListSlice = createSlice({
    name: 'compareList',
    initialState: {
        compareList: [],
        loading: false,
    },

    extraReducers: {
        [getCompareList.pending]: (state) => {
            state.loading = true;
        },
        [getCompareList.fulfilled]: (state, action) => {
            state.compareList = action.payload;
            state.loading = false;
        },
        [getCompareList.rejected]: (state) => {
            state.loading = false;
        },
        [getAddToCompareList.pending]: (state) => {
            state.loading = true;
        },
        [getAddToCompareList.fulfilled]: (state, action) => {
            state.compareList = action.payload;
            state.loading = false;
        },
        [getAddToCompareList.rejected]: (state) => {
            state.loading = false;
        },
        [getRemoveFromCompareList.pending]: (state) => {
            state.loading = true;
        },
        [getRemoveFromCompareList.fulfilled]: (state, action) => {
    //  const newArray=Object.values(state.compareList).filter((item)=>item.id !==action.payload)
    state.loading = false;
        state.compareList=action.payload
        },
        [getRemoveFromCompareList.rejected]: (state) => {
            state.loading = false;
        },
    },
});

export default compareListSlice.reducer;
